
// https://github.com/mrdoob/three.js/blob/356ef226e96053ed93d715e2222308ee881458cd/src/renderers/webgl/WebGLProgram.js#L216
const includePattern = /^[ \t]*#include +[<"]([\w\d./]+)[>"]/gm;
const uniformPattern = /^[ \t]*uniform[ \t]+([\w\d]+)[ \t]+([\w\d_]+)(\[[\w\d]+\])?[ \t]*;/gm;

export function extractIncludes(shader: string){
    const matches = shader.matchAll(includePattern)
    const includes: [string, string][] = [] // filename, full-line
    for (const match of matches) {
        let name = match[1]
        includes.push([name, match[0]])
    }
    return includes
}

export function extractUniforms(shader: string){
    const matches = shader.matchAll(uniformPattern)
    const uniforms: {
        type: string,
        name: string,
        array?: number
    }[] = []
    for (const match of matches) {
        uniforms.push({
            type: match[1],
            name: match[2],
            array: match[3] ? parseInt(match[3].slice(1, -1)) : undefined
        })
    }
    return uniforms
}

export function getFileSync(url: string) {
    let httpRequest = new XMLHttpRequest();
    httpRequest.open("GET", url, false);
    httpRequest.send();
    if (httpRequest.status === 200)
        return httpRequest.responseText;
    else
        return "";
}
