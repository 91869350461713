import {isProjectEmpty, useDBContext} from '../contexts/DBContext'
import React, {useMemo} from 'react'
import {Button, ButtonGroup, Card, Classes, Elevation, H4, HTMLTable, Overlay2, Tag} from '@blueprintjs/core'
import {useLoadingState} from '../utils/loadingState'
import {getProjectVersions, useProjectActions} from '../utils/idb'
import {WelcomeDialogEmptyProjectState} from './WelcomeDialogEmptyProjectState'
import {useNavBarContext} from '../contexts/NavbarContext'

export function ProjectVersionsDialog() {
    const {currentProject} = useDBContext()
    const {showVersions, setShowVersions} = useNavBarContext()

    return !currentProject || !showVersions ? <></> : <Overlay2
        isOpen={showVersions}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        shouldReturnFocusOnClose={true}
        className={Classes.OVERLAY_SCROLL_CONTAINER} onClose={()=>setShowVersions(false)}>
        <Card id="project-versions-dialog" className={"popup-dialog"} elevation={Elevation.ZERO}>
            <ProjectVersionsData/>
        </Card>
    </Overlay2>
}


function ProjectVersionsData() {
    const {currentProject} = useDBContext()
    const [projects, setProjects] = React.useState<any[]>([])
    React.useEffect(() => {
        if (!currentProject) return
        getProjectVersions(currentProject.id).then((projects)=>{
            console.log(projects)
            setProjects(projects)
        })
    }, [currentProject])

    const projectsSorted = useMemo(() =>
            projects.sort((b, a) =>
                Number(a.localUpdatedAt || a.updated_at || '0') - (Number(b.localUpdatedAt || b.updated_at || '0'))),
        [projects])
    const {loadingState, updateLoading} = useLoadingState()
    const actions = useProjectActions()
    return currentProject && projects.length > 0 ? (
        <div className="popup-main-container">
            <H4>
                Project Versions: {currentProject.name}
            </H4>
            <HTMLTable interactive={true}
                       className="project-list">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Modified</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {projectsSorted.map((project) => (
                    <tr key={project.id}
                        className="project-list-item">
                        <td
                            // onClick={() => !project.localDeletedAt && updateLoading('open-project-' + project.id, actions.loadProject(project))}
                            className="project-list-item-title">
                            {project.name} &nbsp;
                            {isProjectEmpty(project) ? (<Tag minimal round intent={'warning'}>Empty</Tag>) : null}
                            {project.localDeletedAt ? (<Tag minimal round intent={'danger'}>Deleted</Tag>) : null}
                        </td>
                        <td className="project-list-item-modified">
                            {project.localUpdatedAt || project.updated_at ? new Date(project.localUpdatedAt || project.updated_at!).toLocaleString() : 'Never'}
                        </td>
                        <td className="project-list-item-actions">
                            <ButtonGroup minimal>
                                {/*Revert to version*/}
                                {/*todo*/}
                                {/*Edit Project*/}
                                {/*{!project.localDeletedAt && <Button*/}
                                {/*    icon={'edit'} loading={loadingState['open-project-' + project.id]}*/}
                                {/*    onClick={() => !project.localDeletedAt && updateLoading('open-project-' + project.id, actions.loadProject(project))}/>}*/}
                                {/*Open in new Tab*/}
                                {/*{project.slug && }*/}
                                {/*<Button small minimal*/}
                                {/*        icon={'share'}*/}
                                {/*        onClick={() => updateLoading('open-project-' + project.id, actions.loadProject(project))} />*/}
                                {/*Download JSON*/}
                                {!isProjectEmpty(project) && <Button
                                    icon={'download'} loading={loadingState['download-project-' + project.id]}
                                    onClick={(e) => updateLoading('download-project-' + project.id, actions.downloadProjectJSON(project))}/>}
                                {/*Duplicate Project*/}
                                {/*{<Button*/}
                                {/*    icon={'duplicate'} loading={loadingState['duplicate-project-' + project.id]}*/}
                                {/*    onClick={() => updateLoading('duplicate-project-' + project.id, actions.duplicateProject(project))}/>}*/}
                                {/*todo Delete Project*/}
                                {/*{!project.localDeletedAt && <Button*/}
                                {/*    icon={'trash'} loading={loadingState['delete-project-' + project.id]}*/}
                                {/*    onClick={(e) => updateLoading('delete-project-' + project.id,*/}
                                {/*        e.shiftKey ? (actions.deleteProjectVersionForce(project, true)) : actions.deleteProjectVersion(project))}/>}*/}
                                {/*More actions*/}
                            </ButtonGroup>
                        </td>
                    </tr>
                ))}
                </tbody>
            </HTMLTable>
        </div>
    ) : (<WelcomeDialogEmptyProjectState/>)
}
