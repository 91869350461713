import {generateUUID, Vector2,} from 'threepipe'
import {ConnectionValueType} from './data/NodeData'
import {CameraNodeData} from './data/CameraNodeData'
import {FlowNodeType} from '../utils/rendering'

export interface CameraNode extends FlowNodeType<CameraNodeData> {
    position: Vector2
}

export function createCameraNode(camera: ConnectionValueType['camera'], name?: string) {
    const node: CameraNode = {
        id: generateUUID(),
        data: new CameraNodeData(camera),
        position: new Vector2(0, 0),
        type: 'camera'
    }
    if (name) node.data.name = name
    return node
}
