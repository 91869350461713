import {
    blobToDataURL,
    IObject3D,
    ITexture,
    PerspectiveCamera,
    RenderPass,
    Scene,
    serializable,
    SerializationMetaType,
    serialize,
    ThreeSerialization,
    ThreeViewer
} from 'threepipe'
import {PreviewNodeData} from './PreviewNodeData'
import {createNodeConnectionSlot, NodeData, TCamera} from './NodeData'
import {ALLOWED_3D_MODEL_EXTENSIONS} from '../../utils/flow'
import {RenderTargetType} from '../../utils/rendering'

@serializable('Scene3dNodeData')
export class Scene3dNodeData extends PreviewNodeData<'valueChanged'> implements NodeData {
    @serialize()
    value: string/* | File*/ // url or base64 url

    object: Scene

    @serialize()
    get name() {
        return this.object.name
    }

    set name(name: string) {
        this.object.name = name
    }

    fromJSON(data: any, meta: SerializationMetaType) {
        const value = data.value
        const data2 = {...data}
        delete data2.value
        ThreeSerialization.Deserialize(data2, this, meta, true)
        if (value && value !== this.value) {
            const name = data.name
            this.loadData(value, meta._context.assetManager!.viewer).then(d => {
                if (name) this.name = name
                // this.setDirty() // todo for updating the card in UI
            })
        }
        return this
    }

    async loadData(value: string | File, viewer: ThreeViewer) {
        if (this.value === value || !value) return;
        const object3d = await viewer.load<IObject3D>(
            typeof value === 'string' ? value :
                {path: '/' + value.name, file: value}, {
                autoScale: true,
                autoScaleRadius: 1,
                autoCenter: true,
                autoImportZipContents: true,
            })
        if (!object3d) {
            console.warn('loadData: Failed to load object3d', this.value)
            return false
        }

        const name = (typeof value === 'string' ?
            (value.startsWith('data:') ?
                ('file.' + value.split(';')[0].split('/').pop())
                : value.split('/').pop()) : value.name)

        const extension = name?.split('.').pop()?.toLowerCase()
        if (!extension || !ALLOWED_3D_MODEL_EXTENSIONS.includes(extension)) {
            console.warn('loadData: Invalid file extension', extension)
            return false
        }

        this.value = typeof value === 'string' ? value : await blobToDataURL(value)
        if (this.value.startsWith('data:application/octet-stream;base64,')) {
            const mime = 'model/' + extension
            this.value = this.value.replace('application/octet-stream', mime)
        }
        this.dispatchEvent({type: 'valueChanged', value: this.value})
        if (!this.value) return
        const scene = this.object
        scene.clear()
        scene.add(this.camera)
        // scene.add(new DirectionalLight2(0xffffff, 10))
        // scene.add(new HemisphereLight(0xffffff, 0x444444, 10))
        // scene.environment = await viewer.load<ITexture>('https://threejs.org/examples/textures/equirectangular/venice_sunset_1k.hdr') || null
        scene.add(object3d)
        // scene.backgroundColor = new Color(0,0,0)
        // console.log(scene)
        scene.name = name || 'Scene'
        this.object = scene
        return true
    }

    // todo serialize?
    // preview camera. make readonly by public?
    camera: TCamera

    constructor(
        value?: string,
        object?: Scene,
        preview?: boolean,
    ) {
        super(preview)
        this.value = value || ''
        this.object = object || new Scene()
        // this._defaultCamera.position.set(0,0,3)
        // this._defaultCamera.lookAt(0,0,0)
        // this.camera = this._defaultCamera
        this.slots.push(createNodeConnectionSlot('texture', {
            name: 'envMap',
            label: 'Environment',
            getValue: () => this.object.environment,
            setValue: (value) => this.object.environment = value,
        }))
        if ((this.object.background as any)?.isColor) {
            console.error('Scene Background cannot be set to color instance here, clearing.')
            this.object.background = null
        }
        this.slots.push(createNodeConnectionSlot('texture', {
            name: 'bgMap',
            label: 'Background',
            getValue: () => this.object.background as ITexture | null,
            setValue: (value) => this.object.background = value,
        }))
        this.slots.push(createNodeConnectionSlot('object3d', {
            name: 'scene',
            label: 'Scene',
            getValue: () => this.object,
            // setValue: (value) => this.object = value,
        }))

        this.camera = new PerspectiveCamera(45, 1, 0.1, 20)
        this.camera.position.set(0, 0, 3)
        this.camera.lookAt(0, 0, 0)

        this.slots.push(createNodeConnectionSlot('camera', {
            name: 'previewCam',
            label: 'Preview Camera',
            getValue: () => this.camera,
            setValue: (value) => this.camera = value,
        }))

    }

    tempTarget?: RenderTargetType

    renderPass = new RenderPass()

    dispose() {
        this.object.environment && this.object.environment.dispose();
        this.object.background && (this.object.background as any).dispose && (this.object.background as any).dispose();

        (this.object as any).dispose && (this.object as any).dispose()
    }
}
