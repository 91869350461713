import {serializable, serialize} from 'threepipe'
import {MinimizeNodeData} from './MinimizeNodeData'
import {createNodeConnectionSlot, NodeData} from './NodeData'

export interface RichTextEditorSettings{
    fontSize: number
}

@serializable('RichTextNodeData')
export class RichTextNodeData extends MinimizeNodeData implements NodeData {
    @serialize() value: string

    @serialize()
    name!: string

    @serialize()
    editorSettings: RichTextEditorSettings = {
        fontSize: 1.25,
    }

    constructor(
        value?: string,
    ) {
        super()
        this.value = value || 'Text'
        this.slots.push(createNodeConnectionSlot('string', {
            name: 'string',
            getValue: () => this.value,
        }))
    }

    dispose() {
        // this.value = ''
    }

    get ext(){
        return 'md'
    }

}
