import {ConnectionDataType, NodeConnectionSlot} from '../nodes/data/NodeData'
import {Handle, Position} from 'reactflow'
import React from 'react'

export function HandleInSlot<T extends ConnectionDataType>(props: {
    type: T,
    style?: React.CSSProperties,
    slot?: NodeConnectionSlot<T>,
    connectable: boolean
    main: boolean
}) {
    return <Handle
        type="target"
        position={Position.Left}
        style={{...props.style, opacity: props.style?.opacity ?? (props.main?'1':'0.75')}} // todo instead of getValue, make and use hasSetValue, which is undefined or defaultValue
        id={`${props.type}In` + (props.slot ? `_${props.slot.name}` : '')}
        className={`${props.type}-handle` + (props.main?' main-in-handle':'') + (props.slot ? ` handle-${props.slot.name}` : '')}
        isConnectable={props.connectable}
    />
}
