import {ConnectionDataType, NodeConnectionSlot} from '../nodes/data/NodeData'
import {ControlGroup, Label} from '@blueprintjs/core'
import {Handle, Position} from 'reactflow'
import {toTitleCase} from 'ts-browser-helpers'
import React from 'react'

export function NodeInOutHandleSlot<T extends ConnectionDataType>(props: {
    type: T,
    slot: NodeConnectionSlot<T>,
    connectable: boolean,
    children?: React.ReactNode
}) {
    return <ControlGroup className={'bp5-small'}>
        {props.slot.setValue && <Handle // todo use the handle in and handle out components
            type="target"
            className={`${props.type}-handle handle-${props.slot.name}`}
            position={Position.Left}
            id={`${props.type}In_` + props.slot.name}
            // style={{position: 'relative', transform: 'unset', marginRight: '10px'}}
            isConnectable={props.connectable}
        />}
        {props.children ?? (
        <Label className={props.slot.setValue ? "slot-in-control-label" : ""} style={{marginRight: "10px"}}>{props.slot.label || toTitleCase(props.slot.name)}</Label>
        )}
        {props.slot.getValue && <Handle
            type="source"
            className={`${props.type}-handle handle-${props.slot.name}`}
            position={Position.Right}
            id={`${props.type}Out_` + props.slot.name}
            // style={{display: props.slot.getValue() ? 'block' : 'none'}}
            style={{opacity: props.slot.getValue() ? '1' : '0.2'}}
            isConnectable={props.connectable}
        />}
    </ControlGroup>
}

