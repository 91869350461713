import {Button, Colors, InputGroup, Label} from '@blueprintjs/core'
import {ALLOWED_IMAGE_EXTENSIONS} from '../flow'
import {ITexture, SRGBColorSpace} from 'threepipe'
import {createTextureNode} from '../../nodes/TextureNode'
import {autoPlaceNodes} from '../addNodes'
import React from 'react'
import {TFlowContext} from '../../contexts/FlowContext'

export const remoteTextureAdd = (context: TFlowContext, allowMultiple = false, error?: string) => {
    const {viewer, dialog, setDialog} = context
    if (!viewer || !dialog || !setDialog) return
    const state = {value: 'https://threejs.org/examples/textures/equirectangular/venice_sunset_1k.hdr', loading: false}
    setDialog({
        ...dialog,
        canClose: true,
        isOpen: true,
        state,
        title: 'Load Image',
        content: (
            <Label>
                Enter URL:
                <InputGroup
                    placeholder="https://example.com/image.jpg"
                    defaultValue={state.value}
                    onChange={(e: any) => state.value = e.target.value}
                />
                {error && <div style={{color: Colors.RED3, margin: '5px'}}>{error}</div>}
                <div style={{marginTop: '10px'}}>
                Supported Extensions: {ALLOWED_IMAGE_EXTENSIONS.join(', ')}
                </div>
            </Label>
        ),
        actions: (
            <>
                <Button text={'Close'} onClick={() => setDialog({...dialog, isOpen: false})}/>
                <Button text={'Load'} intent="primary" onClick={async () => {
                    if(state.loading) return
                    state.loading = true
                    const url1 = state.value
                    const urls = url1.split(';').filter((u: string) => u.trim())
                    const nodes = []
                    if(!allowMultiple && urls.length > 1) {
                        state.loading = false
                        remoteTextureAdd(context, allowMultiple, 'Multiple URLs not allowed (;)')
                        return
                    }
                    for (let file of urls) {
                        if (!file) continue
                        let url
                        try {
                            url = new URL(file)
                        } catch (e) {
                            console.warn('remoteTextureAdd: Invalid URL', file)
                            continue
                        }
                        const extension = url.pathname.includes('.') ? url.pathname.split('.').pop()!.toLowerCase() :
                            url.hash.split('.').pop()?.toLowerCase()
                        // || url.searchParams.get('ext')?.toLowerCase()
                        const filename = url.pathname.split('/').pop()
                            || url.hash.split('/').pop()
                        // || url.searchParams.get('filename')
                        // || url.searchParams.get('name')
                        if (!extension || !ALLOWED_IMAGE_EXTENSIONS.includes(extension)) {
                            console.warn('remoteTextureAdd: Invalid extension', extension)
                            continue
                        }
                        // replace github links with jsdelivr
                        if(file.startsWith('https://github.com/')){
                            // https://github.com/patriciogonzalezvivo/lygia_examples/blob/main/assets/sprite_megaman.png
                            // https://cdn.jsdelivr.net/gh/patriciogonzalezvivo/lygia_examples@main/assets/sprite_megaman.png
                            file = file
                                .replace(/^https:\/\/github.com\//, 'https://cdn.jsdelivr.net/gh/')
                                .replace(/\/blob\//, '@')
                        }
                        const texture = await viewer.load<ITexture>(file)
                        if (!texture) {
                            console.warn('remoteTextureAdd: Invalid texture', texture)
                            continue
                        }
                        if(!texture.isDataTexture)
                            texture.colorSpace = SRGBColorSpace
                        const node = createTextureNode(texture, filename)
                        nodes.push(node)
                    }
                    if(nodes.length > 0) {
                        if(nodes.length !== urls.length) {
                            alert('Some URLs failed to load, check console for details') // todo change to proper dialog
                        }
                        setDialog({...dialog, isOpen: false})
                        autoPlaceNodes(viewer, nodes)
                    }else {
                        state.loading = false
                        error = 'Failed to load texture(s), enter a valid URL.'
                        remoteTextureAdd(context, allowMultiple, error)
                    }
                }}/>
            </>
        ),
    })
    // autoPlaceNodes([node])
}

