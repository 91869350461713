import React from 'react';
import Flow from './Flow'
import './App.scss';
import {ReactFlowProvider} from 'reactflow'
import {DBProvider} from './contexts/DBContext'
import {FlowProvider} from './contexts/FlowContext'
import {ShaderCodeEditor} from './components/ShaderCodeEditor'
import {BlueprintProvider} from "@blueprintjs/core";
import {NavbarContextProvider} from './contexts/NavbarContext'
import {GlobalSettingsProvider} from './contexts/GlobalSettings'
import {GlobalSettingsDrawer} from './components/GlobalSettingsDrawer'

function App() {
    return (
        <div className="App">
            <BlueprintProvider>
            <ReactFlowProvider>
            <DBProvider>
            <FlowProvider>
            <GlobalSettingsProvider>
            <NavbarContextProvider>
            <ShaderCodeEditor>
                <Flow/>
                <GlobalSettingsDrawer/>
            </ShaderCodeEditor>
            </NavbarContextProvider>
            </GlobalSettingsProvider>
            </FlowProvider>
            </DBProvider>
            </ReactFlowProvider>
            </BlueprintProvider>
        </div>
    );
}

export default App;
