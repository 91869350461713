import {
    Color,
    Material,
    Matrix3,
    Matrix4,
    Object3D,
    OrthographicCamera,
    PerspectiveCamera,
    Texture,
    Vector2,
    Vector3,
    Vector4,
    WebGLRenderTarget
} from 'threepipe'

export type TCamera = PerspectiveCamera | OrthographicCamera

export type ConnectionValueType = {
    object3d: Object3D,
    camera: TCamera,
    texture: Texture | null,
    buffer: WebGLRenderTarget|null,
    shader: string, //glsl code basically
    vector: number|Vector2|Vector3|Vector4, // number is vec1
    material: Material|null,
    string: string, // for labels, text etc. Right now only used in rich text node(markdown) and not connected anywhere...

    //todo not used
    number: number, // both int and float
    color: Color
    boolean: boolean,
    matrix: Matrix3 | Matrix4,
}
export type ConnectionDataType = keyof ConnectionValueType
export type ConnectionNameType = {
    object3d: string,
    camera: string,
    texture: string,
    buffer: 'readBuffer'|'writeBuffer'|string,
    shader: string,
    vector: string,
    color: string,
    material: string,
    number: string,
    boolean: string,
    string: string,
    matrix: string,
}

// types which allow multiple input connections
export const allowedMultipleEdgeTypes: ConnectionDataType[] = ['shader']
export const allowedDirectConnectionTypes: ConnectionDataType[] = ['texture', 'object3d', 'camera', 'material', 'shader', 'string', 'vector', 'color', 'number', 'boolean', 'matrix']

export interface NodeConnectionSlot<T extends ConnectionDataType = ConnectionDataType>{
    name: ConnectionNameType[T],
    label?: string,
    dataType: T,

    // value?: ConnectionValueType[T],
    // input?: boolean,
    // output?: boolean,
    getValue?: ()=>ConnectionValueType[T],
    setValue?: (value: ConnectionValueType[T])=>void,
    // setInternalValue?: (value: ConnectionValueType[T])=>void,

    // this is set when null is being set
    defaultValue: ConnectionValueType[T],

    // indicates that this has a value saved so should not be cleared if input is not resolved by edge
    // this is only required when there is a setter.
    // todo this needs to be refactored. rename to needsEdge maybe, or have another property for the stored value.
    //   idea - make a storedValue property, use that instead of defaultValue when an input edge is not connected.
    // hasValue?: boolean,

    needsClear?: boolean // only for writeBufferNode right now, others should clear when not used
}

export function setInternalVal<T extends ConnectionDataType = ConnectionDataType>(node: NodeConnectionSlot<T>, value: ConnectionValueType[T]){
    node.defaultValue = value
    return setSlotVal(node, value)
}

export interface NodeData<T=any>{
    name: string
    // value: T
    slots: NodeConnectionSlot<any>[]

    metadata: any // should be serializable, like userdata

    dispose(): void
}

// Create a helper function to infer the type
export function createNodeConnectionSlot<T extends ConnectionDataType>(
    type: T,
    slot: Omit<NodeConnectionSlot<T>, 'dataType'|'defaultValue'> & {defaultValue?: ConnectionValueType[T]}
): NodeConnectionSlot<T> {
    const s = slot as NodeConnectionSlot<T>
    s.dataType = type
    if(s.defaultValue === undefined){
        const v = getSlotVal(s)
        if(v !== undefined) s.defaultValue = v
    }
    return s
}

export function filterSlots<T extends ConnectionDataType>(slots: NodeConnectionSlot<any>[], type: T, predicate?: (slot: NodeConnectionSlot<T>)=>boolean){
    return slots.filter(s=>s.dataType === type && (!predicate || predicate(s))) as NodeConnectionSlot<T>[]
}

export function getSlot<T extends ConnectionDataType>(slots: NodeConnectionSlot<any>[], type: T, name: string){
    return slots.find(s=>s.dataType === type && s.name === name) as NodeConnectionSlot<T> | undefined
}

export function getSlotValue<T extends ConnectionDataType>(slots: NodeConnectionSlot<any>[], type: T, name: string){
    return getSlotVal(getSlot(slots, type, name))
}

export function setSlotValue<T extends ConnectionDataType>(slots: NodeConnectionSlot<any>[], type: T, name: string, value: ConnectionValueType[T]){
    const slot = getSlot(slots, type, name)
    return setSlotVal(slot, value)
}
export function setSlotVal<T extends ConnectionDataType>(slot: NodeConnectionSlot<T>|undefined, value: ConnectionValueType[T], force = false){
    if(!slot || !slot.setValue) return undefined
    // if(value === null && slot.hasValue) return slot
    // if(value === null && slot.defaultValue !== undefined) value = slot.defaultValue
    if(force || getSlotVal(slot) !== value) slot.setValue(value)
    return slot
}
export function getSlotVal<T extends ConnectionDataType>(slot: NodeConnectionSlot<T>|undefined){
    return slot?.getValue && slot.getValue()
}
