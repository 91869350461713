import {NodeProps} from 'reactflow'
import {ContextVariablesNodeData} from '../data/ContextVariablesNodeData'
import React from 'react'
import {InputGroup, Label} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {BaseNodeCard} from '../../components/BaseNodeCard'
import {useFlowContext} from '../../contexts/FlowContext'
import {Vec3InputGroup} from '../../components/Vec3InputGroup'
import {useThreeViewer} from '../../components/ThreeViewerComponent'
import {FlowMouseState, FlowTimeState} from '../../utils/state'
import {filterSlots, getSlotVal} from '../data/NodeData'
import {toTitleCase} from 'threepipe'

function ContextVariablesNodeFC({type, data, selected, isConnectable, dragging}: NodeProps<ContextVariablesNodeData>) {
    const viewer = useThreeViewer()
    const {plugin} = useFlowContext()
    const [minimize, setMinimize] = React.useState(data.minimize)
    React.useEffect(() => {
        data.minimize = minimize
    }, [minimize, data])

    const [contextState, setContextState] = React.useState<Partial<FlowTimeState&FlowMouseState>>(data.state)
    React.useEffect(() => {
        const listener = ()=>{
            data.state = {
                ...plugin?.timeState,
                // ...plugin?.mouseState, todo
            }
            setContextState(data.state)
        }
        viewer?.addEventListener('postRender', listener) // todo postFrame?
        return ()=>viewer?.removeEventListener('postRender', listener)
    }, [plugin, viewer])

    // const state =
    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent title={minimize ? data.name : 'Vector'} buttons={[
                // nodeHeaderButtons.download(() => { // todo:
                //     const blob = flow.viewer?.renderManager.exportRenderTarget(data.value)
                //     return blob ? new File([blob], `${data.value.texture.name}.${blob.ext}`) : undefined
                // })
                // viewer && nodeHeaderButtons.duplicate(type, data, viewer),
                nodeHeaderButtons.minimize(minimize, setMinimize),
            ]}/>
            {!minimize && (<>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    Name
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        defaultValue={data.name}
                        onChange={(evt) => data.name = evt.target.value}
                    />
                </Label>
                {filterSlots(data.slots, 'vector').map(s=>(
                    <Vec3InputGroup
                        key={s.name}
                        label={s.label || toTitleCase(s.name)}
                        name={s.name}
                        value={s.name.endsWith('Ms') ? parseFloat((getSlotVal(s) as number)?.toFixed?.(1)) : getSlotVal(s) || 0}
                        children2={<HandleOutSlot type={'vector'} main={false}
                                                  slot={s}
                                                  connectable={isConnectable}/>}
                    />
                ))}

            </>)}
        </BaseNodeCard>
    );
}

export default ContextVariablesNodeFC
