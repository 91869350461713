import {
    ClampToEdgeWrapping,
    ExtendedShaderPass,
    ITexture,
    LinearFilter,
    LinearMipmapLinearFilter,
    NearestFilter,
    RepeatWrapping,
    ShaderPass,
    Texture,
    ThreeViewer,
    WebGLRenderTarget
} from 'threepipe'
import {PassNode} from '../../nodes/data/PassNodeData'
import {FlowEdgeType, FlowNodeType} from '../rendering'
import {FlowRendererPlugin1} from '../plugins/flowRendererPlugin1'
import {
    allowedMultipleEdgeTypes,
    ConnectionDataType,
    getSlot,
    getSlotVal,
    getSlotValue,
    NodeConnectionSlot,
    setSlotVal,
    setSlotValue
} from '../../nodes/data/NodeData'
import {updateCameraAspect, updateNodeCamera} from '../updateNodeCamera'
import {NodeRenderPass} from '../../passes/NodeRenderPass'

// todo: log warning in console or disable preserve output if the output handle is not connected anywhere
function preservePassNodeOutput(v: ThreeViewer, node: PassNode, target: WebGLRenderTarget) {
    let temp: WebGLRenderTarget | undefined = node.data.tempTarget
    if(temp === target) return temp;
    if (temp && (
        !node.data.preserveOutput || temp.width !== target.width || temp.height !== target.height
    )) {
        v.renderManager.releaseTempTarget(temp)
        node.data.tempTarget = undefined
        temp = undefined
    }
    if (!node.data.preserveOutput) return undefined;
    const targetSize = {width: target.width * node.data.preserveOutputScale, height: target.height * node.data.preserveOutputScale}
    if (!temp || (
        Math.abs(temp.width - targetSize.width) + Math.abs(temp.height - targetSize.height) > 0.1
        || temp.texture.format !== target.texture.format
        || temp.texture.type !== target.texture.type
    )) {
        node.data.tempTarget = v.renderManager.getTempTarget<WebGLRenderTarget>({
            size: targetSize,
            colorSpace: target.texture.colorSpace,
            type: target.texture.type,
            format: target.texture.format,
            generateMipmaps: target.texture.generateMipmaps,
            // wrapS: target.texture.wrapS,
            // wrapT: target.texture.wrapT,
            minFilter: target.texture.minFilter,
            magFilter: target.texture.magFilter,
        })
        temp = node.data.tempTarget
    }
    const props = ['minFilter', 'magFilter', 'generateMipmaps', 'colorSpace', 'wrapS', 'wrapT'] as const
    let changed = false
    for (const prop of props) {
        if (temp.texture[prop] !== target.texture[prop]) {
            (temp.texture as any)[prop] = target.texture[prop]
            changed = true
        }
    }
    if (changed) temp.texture.needsUpdate = true

    v.renderManager.blit(temp, {
        source: target.texture,
        clear: true,
        respectColorSpace: false,
    })
    return temp
}

// sampler from shader toy
function updateOutputSampler(inputEdge: FlowEdgeType, output: Texture) {
    const sampler = (inputEdge as any).sampler
    // console.log('sampler', (inputEdge as any).sampler)
    if (sampler.filter === 'nearest') {
        output.magFilter = NearestFilter
        output.minFilter = NearestFilter
        output.needsUpdate = true
    } else if (sampler.filter === 'linear') {
        output.magFilter = LinearFilter
        output.minFilter = output.generateMipmaps ? LinearMipmapLinearFilter : LinearFilter
        output.needsUpdate = true
    } else {
        console.error('unknown filter', sampler)
    }
    if (sampler.wrap === 'repeat') {
        output.wrapS = RepeatWrapping
        output.wrapT = RepeatWrapping
        output.needsUpdate = true
    } else if (sampler.wrap === 'clamp') {
        output.wrapS = ClampToEdgeWrapping
        output.wrapT = ClampToEdgeWrapping
        output.needsUpdate = true
    } else {
        console.error('unknown wrap', sampler)
    }
    // todo flipy, srgb etc
}

export function resolveNodeInputs(inputEdges: FlowEdgeType[], vs: FlowRendererPlugin1<string>, nodeSlots: NodeConnectionSlot<any>[]) {
    // const inputsUsed: Record<keyof NodeData['inputs'], string[]> = {
    //     textures: [], shaders: [], buffers: [], object3ds: [], cameras: [],
    //     vectors: [], colors: []
    // }
    const slotsUsed: NodeConnectionSlot<any>[] = []

    const multiInputSlots = {} as Record<ConnectionDataType, Record<string, any[]>>

    for (const inputEdge of inputEdges) {
        // console.log(inputEdge)
        const sourceHandle = inputEdge.sourceHandle?.split('_') || []
        const targetHandle = inputEdge.targetHandle?.split('_') || []
        const sourceName = sourceHandle.slice(1).join('_')
        const sourceType = sourceHandle[0].replace(/Out$/, '') as ConnectionDataType
        const targetName = targetHandle.slice(1).join('_')
        const targetType = targetHandle[0].replace(/In$/, '') as ConnectionDataType

        const allowMultipleInputs = allowedMultipleEdgeTypes.includes(targetType)

        const sourceNode = vs.nodes.find(n => n.id === inputEdge.source)
        // const nodeOutputs = sourceNode?.data.outputs
        const sourceSlots = sourceNode?.data.slots
        if (!sourceNode || !sourceSlots) {
            console.warn('Ignoring edge, source node not found', inputEdge)
            continue
        }
        vs.renderNode(sourceNode)

        let output = undefined

        // textures and buffers
        if (sourceType === 'buffer' && targetType === 'texture') {
            // output = nodeOutputs.buffers![sourceName]?.value?.texture
            output = getSlotValue(sourceSlots, 'buffer', sourceName)?.texture || null
            // nodeInputs.textures![targetName].value = output || null
            // inputsUsed.textures.push(targetName)
            const slot = setSlotValue(nodeSlots, 'texture', targetName, output)
            slot && slotsUsed.push(slot)
        } else if (sourceType === 'buffer' && targetType === 'buffer') {
            // no need to render, since its done in bufferIn.
            // actually, will we ever come in this branch? yes, we need to set the output value properly, thats why this is before `else`
            // output = nodeOutputs.buffers![sourceName].value?.texture
            const outputBuffer = getSlotValue(sourceSlots, 'buffer', sourceName)
            output = outputBuffer?.texture || null // null for output check below
            // nodeInputs.buffers![targetName].value = nodeOutputs.buffers![sourceName].value
            // inputsUsed.buffers.push(targetName)
            const slot = setSlotValue(nodeSlots, 'buffer', targetName, outputBuffer || null)
            slot && slotsUsed.push(slot)
        } else {
            if (sourceType === targetType) {
                const source = getSlotValue(sourceSlots, sourceType, sourceName)
                if(source === undefined) throw new Error('Cannot get source value, invalid edge?')
                if(allowMultipleInputs){
                    if(!multiInputSlots[targetType]) multiInputSlots[targetType] = {}
                    if(!multiInputSlots[targetType][targetName]) multiInputSlots[targetType][targetName] = []
                    multiInputSlots[targetType][targetName].push(source)
                }else {
                    const target = setSlotValue(nodeSlots, targetType, targetName, source)
                    target && slotsUsed.push(target)
                }
                output = source
            }
        }

        if (output === undefined) {
            console.error('no output, probably invalid edge, format: <type>_<name>', inputEdge.sourceHandle, inputEdge.targetHandle, {sourceType, targetType, nodeSlots})
            continue
        }

        // todo add sampler(from shadertoy) to edge type and UI somehow?
        if ((inputEdge as any).sampler && output) {
            if(!(output as ITexture).isTexture){
                console.error('output is not a texture, cannot apply sampler.', output)
                continue
            }
            updateOutputSampler(inputEdge, output as ITexture)
        }
    }

    // set multi inputs
    for (const type of Object.keys(multiInputSlots) as ConnectionDataType[]) {
        for (const name of Object.keys(multiInputSlots[type])) {
            const val = multiInputSlots[type][name]
            let value;
            if(type === 'shader'){
                value = val.map(v=>v.toString()).join('\n')
            }else throw new Error('Invalid multi input type')
            // console.log('multi input', type, name, value)
            const target = setSlotValue(nodeSlots, type, name, value)
            target && slotsUsed.push(target)
        }
    }

    // clear the inputs that are not used, these may be set from previous render calls
    // const inputs = nodeInputs as any
    // for (const key of (Object.keys(inputsUsed) as any as (keyof typeof inputsUsed)[])) {
    //     inputs[key] && Object.keys(inputs[key]!).forEach((name) => {
    //         if (!inputsUsed[key].includes(name) && !inputs[key]![name].hasValue) inputs[key]![name].value = null
    //     })
    // }
    for (const slot of nodeSlots) {
        // direct check with null, as it should set when the getSlotVal function returns null.(output only node)
        if (!slotsUsed.includes(slot)/* && getSlotVal(slot) !== null*/) { // no need to check for setter as its done in setSlotVal
            setSlotVal(slot, slot.defaultValue)
        }
    }
}

function updatePassUniforms(vs: FlowRendererPlugin1, pass: ShaderPass|ExtendedShaderPass, writeBuffer: WebGLRenderTarget){
    if(!pass.uniforms) return
    if(vs.mouseState && pass.uniforms.iMouse){
        // https://www.shadertoy.com/view/Mss3zH
        pass.uniforms.iMouse.value.set( // acc to shadertor
            vs.mouseState.position.x * writeBuffer.width,
            vs.mouseState.position.y * writeBuffer.height,
            vs.mouseState.clickPosition.x * (vs.mouseState.isDown ? 1 : -1) * writeBuffer.width,
            vs.mouseState.clickPosition.y * (vs.mouseState.isClick ? 1 : -1) * writeBuffer.height,
        )
    }
    if(vs.timeState){
        if(pass.uniforms.iTime) pass.uniforms.iTime.value = vs.timeState.time/1000
        if(pass.uniforms.iFrame) pass.uniforms.iFrame.value = vs.timeState.frame
    }
}

export function renderPassNode(node: PassNode, vs: FlowRendererPlugin1, target?: WebGLRenderTarget, renderForward = false) {
    if(!vs.viewer) throw new Error('Viewer not initialized')

    const ret = (s = '')=>{
        vs.renderNodePreview(node)
        if (renderForward) {
            console.warn('Forward Pass Node: not implemented') // todo
        }
        return s
    }

    let inputEdges = vs.inputEdges(node)

    // get the edge that writes to the bufferIn handle
    // input buffer to render to.
    let writeBuffer = target
    let bufferInNode: FlowNodeType | undefined
    if(!writeBuffer){
        const bufferInEdge = inputEdges.find(e => e.targetHandle === 'bufferIn')
        bufferInNode = bufferInEdge ? vs.nodes.find(n => n.id === bufferInEdge.source) : undefined
        if (!bufferInNode) {
            // console.warn('Shader pass node: No write buffer to write to', node)
            // nothing connected in the in-node
            // if there is output writeBuffer set, unset it. (since its the reference to lastBuffer)
            const writeBufferSlot = getSlot(node.data.slots, 'buffer', 'writeBuffer')
            if(getSlotVal(writeBufferSlot)){
                setSlotVal(writeBufferSlot, null)
            }
            return ret()
        }
        vs.renderNode(bufferInNode)
        writeBuffer = getSlotValue(bufferInNode.data.slots, 'buffer', bufferInEdge!.sourceHandle!.split('_')[1]||'writeBuffer') || undefined
    }
    if (!writeBuffer) {
        console.error('Shader pass node: No write buffer to write to', node)
        return ret()
    }
    inputEdges = inputEdges.filter(e => e.targetHandle !== 'bufferIn')

    // fills the node inputs with the resolved values from the input edges.
    // maps correctly between buffers and textures.
    resolveNodeInputs(inputEdges, vs, node.data.slots)

    const pass = node.data.value
    const read = undefined;

    // hacks to sync time and mouse states in uniforms
    // todo: move to somewhere else maybe
    // @ts-ignore
    updatePassUniforms(vs, pass, writeBuffer)

    // console.log('rendering pass', pass.passId, 'to target', target.texture.name)

    if((pass as NodeRenderPass).camera?.isCamera){
        const minDist = 2;
        const maxDist = 20;
        const camera = (pass as NodeRenderPass).camera!

        const anyNodeMouse = false
        const mouseState = anyNodeMouse || vs.mouseState.nodeId === node.id ? vs.mouseState : undefined

        updateNodeCamera(mouseState, camera, minDist, maxDist)
        if(writeBuffer) {
            const aspect = writeBuffer ? writeBuffer.width / writeBuffer.height : 1;
            updateCameraAspect(aspect, camera)
        }
    }

    pass.render(vs.viewer.renderManager.renderer, writeBuffer, read, (vs.timeState?.delta||0)/1000)

    const writeBufferSlot = getSlot(node.data.slots, 'buffer', 'writeBuffer')
    if(writeBufferSlot) {
        // node.data.outputs.buffers.writeBuffer.value = writeBuffer
        setSlotVal(writeBufferSlot, writeBuffer)

        // this is required since we are now setting writeBuffer to current node for output.
        // check the lastBuffer logic in NodeShaderPass and NodeRenderPass, this basically maps to that.
        const bufferInWriteBufferSlot = bufferInNode && getSlot(bufferInNode.data.slots, 'buffer', 'writeBuffer')
        if(bufferInWriteBufferSlot?.needsClear && getSlotVal(bufferInWriteBufferSlot) === writeBuffer){
            setSlotVal(bufferInWriteBufferSlot, null)
        }

    }
    preservePassNodeOutput(vs.viewer, node, writeBuffer)

    return ret('bufferOut')
}
