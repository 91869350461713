import {ThreeViewer} from 'threepipe'
import {useFlowContext} from '../contexts/FlowContext'
import {useLoadingState} from '../utils/loadingState'
import React, {useCallback} from 'react'
import {Button} from '@blueprintjs/core'

// todo use ImportPopupFileButton in remoteTextureAdd and remoteSceneAdd etc.
export function ImportPopupFileButton(props: {
    state: { value: string },
    importer: (s: string, v: ThreeViewer) => Promise<string>
}) {
    const {viewer, dialog, setDialog, project, setProject} = useFlowContext()
    const {loadingState, updateLoading} = useLoadingState()
    const t = useCallback(async () => {
        let id = props.state.value
        if (!id) return
        const name = await props.importer(id, viewer!).catch((e) => {
            alert('Unable to import: ' + (e.message || e))
            console.error(e)
        })
        if (!name) return
        if (project.name === 'Untitled') setProject({...project, name: name || 'Untitled'})
        setDialog({...dialog, isOpen: false})
    }, [props.importer, props.state.value, viewer, dialog, setDialog, project, setProject])
    if (!viewer || !dialog || !setDialog) return <></>
    return <Button text={'Import'} intent="primary"
                   loading={loadingState['import-file']}
                   disabled={loadingState['import-file']}
                   onClick={async (e) => updateLoading('import-file', t())}/>
}
