import {Alignment, NonIdealState} from '@blueprintjs/core'
import React from 'react'
import {WelcomeDialogCreateProjectActions} from './WelcomeDialogCreateProjectActions'

export function WelcomeDialogEmptyProjectState() {
    return <NonIdealState
        icon={'projects'}
        title={(<h2>Welcome to Shader Editor</h2>)}
        description={(
            <p style={{textAlign: 'center'}}>Create, explore and experiment with shader programs and render-pipelines in
                the next generation IDE for shaders using both nodes and code. <br/>
                Note: shaders are written as code, in GLSL.
                <span style={{display: 'none'}}>
                    The nodes represent individual components in the scene and the render pipeline like 3d objects and
                    shader passes. <br/>
                    This is not a graph editor for visual shader programming. Shaders have to be written in GLSL</span>
            </p>)}
        // action={<Button icon={"add"} text={"New Project"} outlined />}
        children={(
            <div className="welcome-dialog-empty-actions">
                <WelcomeDialogCreateProjectActions alignText={Alignment.LEFT} minimal={true} outlined={false} />
            </div>
        )}
    />
}
