import {
    AdditiveBlending,
    AnyMapping,
    Blending,
    ByteType,
    ClampToEdgeWrapping,
    ColorSpace,
    CubeReflectionMapping,
    CubeUVReflectionMapping,
    CustomBlending,
    DisplayP3ColorSpace,
    EquirectangularReflectionMapping,
    FloatType,
    HalfFloatType,
    IntType,
    LinearFilter,
    LinearMipmapLinearFilter,
    LinearMipmapNearestFilter,
    LinearSRGBColorSpace,
    MagnificationTextureFilter,
    MinificationTextureFilter,
    MirroredRepeatWrapping,
    MultiplyBlending,
    NearestFilter,
    NearestMipmapLinearFilter,
    NearestMipmapNearestFilter,
    NoBlending,
    NormalBlending,
    RepeatWrapping,
    RGBM16ColorSpace,
    ShortType,
    SRGBColorSpace,
    SubtractiveBlending,
    TextureDataType,
    UnsignedByteType,
    UnsignedInt248Type,
    UnsignedIntType,
    UnsignedShort4444Type,
    UnsignedShort5551Type,
    UnsignedShortType,
    UVMapping,
    Wrapping
} from 'threepipe'

export const blending: Record<string, Blending> = {
    None: NoBlending,
    Normal: NormalBlending,
    Additive: AdditiveBlending,
    Subtractive: SubtractiveBlending,
    Multiply: MultiplyBlending,
    Custom: CustomBlending,
}
export const blendingToName = Object.fromEntries(Object.entries(blending).map(([k, v]) => [v, k])) as Record<Blending, string>

export const mapping: Record<string, AnyMapping> = {
    UV: UVMapping,
    Cube: CubeReflectionMapping,
    // CubeRefraction: CubeRefractionMapping,
    CubeUV: CubeUVReflectionMapping,
    Equirectangular: EquirectangularReflectionMapping,
    // EquirectangularRefraction: EquirectangularRefractionMapping,
}

export const mappingToName = Object.fromEntries(Object.entries(mapping).map(([k, v]) => [v, k])) as Record<AnyMapping, string>

export const colorSpace: Record<string, ColorSpace> = {
    None: '',
    SRGB: SRGBColorSpace,
    LinearSRGB: LinearSRGBColorSpace,
    RGBM16: RGBM16ColorSpace,
    DisplayP3: DisplayP3ColorSpace,
}

export const colorSpaceToName = Object.fromEntries(Object.entries(colorSpace).map(([k, v]) => [v, k])) as Record<ColorSpace, string>

export const textureDataType: Record<string, TextureDataType> = {
    UnsignedByte: UnsignedByteType,
    Byte: ByteType,
    Short: ShortType,
    UnsignedShort: UnsignedShortType,
    Int: IntType,
    UnsignedInt: UnsignedIntType,
    Float: FloatType,
    HalfFloat: HalfFloatType,
    UnsignedShort4444: UnsignedShort4444Type,
    UnsignedShort5551: UnsignedShort5551Type,
    UnsignedInt248: UnsignedInt248Type,
}

export const textureDataTypeToName = Object.fromEntries(Object.entries(textureDataType).map(([k, v]) => [v, k])) as Record<TextureDataType, string>

export const minFilter: Record<string, MinificationTextureFilter> = {
    Nearest: NearestFilter,
    Linear: LinearFilter,
    NearestMipmapNearest: NearestMipmapNearestFilter,
    LinearMipmapNearest: LinearMipmapNearestFilter,
    NearestMipmapLinear: NearestMipmapLinearFilter,
    LinearMipmapLinear: LinearMipmapLinearFilter,
}

export const minFilterToName = Object.fromEntries(Object.entries(minFilter).map(([k, v]) => [v, k])) as Record<MinificationTextureFilter, string>

export const magFilter: Record<string, MagnificationTextureFilter> = {
    Nearest: NearestFilter,
    Linear: LinearFilter,
}

export const magFilterToName = Object.fromEntries(Object.entries(magFilter).map(([k, v]) => [v, k])) as Record<MagnificationTextureFilter, string>

export const wrapMode: Record<string, Wrapping> = {
    Repeat: RepeatWrapping,
    Clamp: ClampToEdgeWrapping,
    MirroredRepeat: MirroredRepeatWrapping,
}

export const wrapModeToName = Object.fromEntries(Object.entries(wrapMode).map(([k, v]) => [v, k])) as Record<Wrapping, string>

export const vectorTypes = {
    'Number': 1,
    'Vector2': 2,
    'Vector3': 3,
    'Vector4': 4,
}

export const vectorTypesToName = Object.fromEntries(Object.entries(vectorTypes).map(([k, v]) => [v, k])) as Record<1|2|3|4, string>
