import {generateUUID, Vector2,} from 'threepipe'
import {ShaderMathFunctionNodeData} from './data/ShaderMathFunctionNodeData'
import {FlowNodeType} from '../utils/rendering'

export interface ShaderMathFunctionNode extends FlowNodeType<ShaderMathFunctionNodeData> {
    position: Vector2,
    type: 'shaderMathFunction'
}

export function createShaderMathFunctionNode(value: string, defValue: string, name?: string) {
    const node: ShaderMathFunctionNode = {
        id: generateUUID(),
        data: new ShaderMathFunctionNodeData(value, defValue, true),
        position: new Vector2(0, 0),
        type: 'shaderMathFunction'
    }
    if (name) node.data.name = name
    return node
}

// {\displaylines \operatorname{sdSphere}:=(p\in\mathrm{R}^3,s\in\mathrm{R})\to\mathbb{R} \mapsto\lbrace\\ \quad \operatorname{length}\left(p\right)-s+0.1\\\rbrace}

// \operatorname{sdSphere}:=(p\in\mathrm{R}^3,s\in\mathrm{R})\to\mathbb{R}\mapsto
// {{{\displaylines\operatorname{length}\left(p\right)-s+0.1}}}
