import {generateUUID, Vector2} from 'threepipe'
import {NodeRenderPass} from '../passes/NodeRenderPass'
import {RenderPassNodeData} from './data/RenderPassNodeData'
import {PassNode} from './data/PassNodeData'

export interface RenderPassNode extends PassNode<RenderPassNodeData>{type: 'renderPass'}

export function createRenderPassNode(pass: NodeRenderPass, name?: string): RenderPassNode {
    const node: RenderPassNode = {
        id: generateUUID(),
        data: new RenderPassNodeData(pass, true, false),
        position: new Vector2(),
        type: 'renderPass'
    }
    if(name) node.data.name = name
    return node;
}
