import {serializable, serialize} from 'threepipe'
import {createNodeConnectionSlot, NodeData} from './NodeData'
import {MinimizeNodeData} from './MinimizeNodeData'
import {FlowMouseState, FlowTimeState} from '../../utils/state'

@serializable('ContextVariablesNodeData')
export class ContextVariablesNodeData extends MinimizeNodeData implements NodeData {
    // @serialize() value: IVector

    // no need to serialize since that is done in value
    @serialize()
    name: string = 'Context'

    state: Partial<FlowTimeState&FlowMouseState> = {}

    constructor(
        // value?: IVector,
    ) {
        super()
        // this.value = value || 0
        this.slots.push(createNodeConnectionSlot('vector', {
            name: 'timeMs',
            label: 'Time (ms)',
            getValue: ()=> this.state.time||0
        }))
        this.slots.push(createNodeConnectionSlot('vector', {
            name: 'timeDeltaMs',
            label: "Time Delta (ms)",
            getValue: ()=> this.state.delta||0
        }))
        this.slots.push(createNodeConnectionSlot('vector', {
            name: 'frameCount',
            label: "Frame Count",
            getValue: ()=> this.state.frame||0
        }))
        this.slots.push(createNodeConnectionSlot('vector', {
            name: 'isRunning',
            label: "Is Running",
            getValue: ()=> this.state.running?1:0
        }))
    }


    dispose() {
    }

}
