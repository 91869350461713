import {
    Camera,
    Color,
    IWebGLRenderer,
    Material,
    MeshDepthMaterial,
    MeshNormalMaterial,
    PerspectiveCamera,
    RenderPass,
    Scene,
    serializable,
    serialize,
    WebGLMultipleRenderTargets,
    WebGLRenderTarget
} from 'threepipe'
import {createNodeConnectionSlot, NodeConnectionSlot, TCamera} from '../nodes/data/NodeData'

export abstract class NodeRenderPassBase extends RenderPass { // todo try ExtendedRenderPass
    @serialize() passId: string = 'render-pass'
    // declare ['constructor']: typeof NodeRenderPass & { PassTypeName: string }

    // inputs: NodeData['inputs'] = {
    //     object3ds: {} as Record<string, { value: Object3D | null }>,
    //     cameras: {} as Record<string, { value: Camera | null }>,
    //     // buffers: {} as Record<string, { value: WebGLRenderTarget | null }>,
    //     // shaders: {} as Record<string, { value: string, default: string }>
    // }

    // todo do the same as _slots in NodeShaderPassBase
    slots: NodeConnectionSlot<any>[] = []
    camera!: TCamera
    scene!: Scene

    dirty = false

    setDirty() {
        this.dirty = true
        // super.setDirty();
    }

    render(renderer: IWebGLRenderer, writeBuffer: WebGLRenderTarget | null, _readBuffer?: WebGLMultipleRenderTargets | WebGLRenderTarget, deltaTime?: number, maskActive?: boolean) {
        // read buffer is ignored and write buffer is passed as the 3d parameter to remain consistent with shader pass.
        super.render(renderer, null, writeBuffer || undefined, deltaTime, maskActive);
    }

    constructor(scene?: Scene, camera?: Camera, overrideMaterial?: Material, clearColor?: Color, clearAlpha?: number) {
        super(scene ?? new Scene(), camera, overrideMaterial, clearColor, clearAlpha)
        // this.clear = false
        this.clearDepth = true

        if(!this.camera) {
            this.camera = new PerspectiveCamera(45, 1, 0.1, 20)
            this.camera.position.set(0,0,3)
            this.camera.lookAt(0,0,0)
        }
        this.slots.push(createNodeConnectionSlot('object3d', {
            name: 'scene',
            getValue: () => this.scene,
            setValue: (value) => this.scene = value as any,
        }))
        this.slots.push(createNodeConnectionSlot('camera', {
            name: 'camera',
            getValue: () => this.camera,
            setValue: (value) => this.camera = value,
        }))
    }
}

@serializable('NodeRenderPass')
export class NodeRenderPass extends NodeRenderPassBase{
    static PassTypeName = 'Simple' // for ui

}

@serializable('NodeDepthRenderPass')
export class NodeDepthRenderPass extends NodeRenderPassBase{
    static PassTypeName = 'Depth' // for ui
    constructor(scene?: Scene, camera?: Camera, overrideMaterial?: Material, clearColor?: Color, clearAlpha?: number) {
        super(scene, camera, overrideMaterial ?? new MeshDepthMaterial(), clearColor, clearAlpha)
    }
}
@serializable('NodeNormalRenderPass')
export class NodeNormalRenderPass extends NodeRenderPassBase{
    static PassTypeName = 'Normal' // for ui
    constructor(scene?: Scene, camera?: Camera, overrideMaterial?: Material, clearColor?: Color, clearAlpha?: number) {
        super(scene, camera, overrideMaterial ?? new MeshNormalMaterial(), clearColor, clearAlpha)
    }
}
