// MathfieldElement.computeEngine!.declare("length", { // glsl length
//     signature: { domain: "Functions" },
//     // evaluate: (ce, args) => ce.number(args[0].value * 2),
// });
// MathfieldElement.computeEngine!.assign("length(x,y)",["Length", "x", "y"]);
// console.log(Object.values(MathfieldElement.computeEngine!.latexDictionary).filter(v=>(v as any).kind === 'function'))
// MathfieldElement.computeEngine!.assign("length(x)",["Length", "x"]);
import {Mathfield, MathfieldElement} from 'mathlive'

export function setupMathfield(){
    MathfieldElement.computeEngine!.declare("length", "Functions"); // this should be lowercase length
    MathfieldElement.computeEngine!.declare("vec2", "Functions"); // this should be lowercase length
    MathfieldElement.computeEngine!.declare("vec3", "Functions"); // this should be lowercase length
    MathfieldElement.computeEngine!.declare("vec4", "Functions"); // this should be lowercase length
    // { identifierTrigger: 'length', kind: 'function', parse: 'Length' },
    MathfieldElement.computeEngine!.latexDictionary = [
        // Include all the entries from the default dictionary...
        // @ts-ignore
        ...MathfieldElement.computeEngine!.latexDictionary,
        { identifierTrigger: 'length', kind: 'function', parse: 'Length' },
        { identifierTrigger: 'vec2', kind: 'function', parse: 'Vec2' },
        { identifierTrigger: 'vec3', kind: 'function', parse: 'Vec3' },
        { identifierTrigger: 'vec4', kind: 'function', parse: 'Vec4' },
        // { latexTrigger: '\\displaylines', kind: 'environment', parse: (parser, until) => {
        //     return "Block"
        // } } as LatexDictionaryEntry,
        // {
        //     kind: "matchfix" as any,
        //     // latexTrigger: '\\displaylines',
        //     openTrigger: '{\\displaylines',
        //     closeTrigger: '}',
        //     parse: (parser: any, body: any) => {
        //         return ["Abs", body];
        //     },
        // } as any,
        // {
        //     // The parse handler below will be invoked when this LaTeX command is encountered
        //     kind: "function",
        //     // identifierTrigger: 'length',
        //     latexTrigger: '\\length',
        //     name: 'Length',
        //     // parse: "Length",
        //     // parse: (parser, a) => {
        //     //     debugger;
        //     //     return ["Length", ...parser.parseArguments("enclosure",a)??[]];
        //     // },
        // } as LatexDictionaryEntry,
    ];
}

// mf.current.menuItems = [];
// mf.current.macros = { ...mf.current.macros,
//     length: {
//         args: 1,
//         def:'\\operatorname{length}'
//     },
// };
// mf.current.inlineShortcuts = {
//     ...mf.current.inlineShortcuts,
//     length: "\\length",
// };

// console.log(mf.current.macros);
// mf.current.addEventListener("focusin", (evt) =>
//     window.mathVirtualKeyboard.show()
// );
// mf.current.addEventListener("focusout", (evt) =>
//     window.mathVirtualKeyboard.hide()
// );
export function onInlineShortcut(mathfield: Mathfield, s: string) {
    // if (/^[A-Z][a-z]+$/.test(s)) return `\\operatorname{${s}}`;
    if (s === 'length') return '\\operatorname{length}';
    if (s === 'vec2') return '\\operatorname{vec2}';
    if (s === 'vec3') return '\\operatorname{vec3}';
    if (s === 'vec4') return '\\operatorname{vec4}';
    if (s === 'R') return '\\mathbb{R}';
    // if(s === 'x') return '\\operatorname{x}';
    return '';
}
