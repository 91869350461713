import {generateUUID, Scene, Vector2} from 'threepipe'
import {Scene3dNodeData} from './data/Scene3dNodeData'
import {FlowNodeType} from '../utils/rendering'

export interface Scene3dNode extends FlowNodeType<Scene3dNodeData> {
    position: Vector2
    type: 'scene3d'
}

export function createScene3dNode(file?: Scene3dNodeData['value'], scene?: Scene) {
    const node: Scene3dNode = {
        id: generateUUID(),
        data: new Scene3dNodeData(file, scene, true),
        position: new Vector2(0, 0),
        type: 'scene3d'
    }
    return node
}
