import {Checkbox, ControlGroup, NumericInput} from '@blueprintjs/core'
import {Handle, Position} from 'reactflow'
import React from 'react'

export function PreserveOutputBufferControl(props: {
    preserveOutput: boolean,
    onPreserveOutputChange: () => void,
    preserveOutputScale: number,
    onPreserveOutputScaleChange: (value: number) => void,
    connectable: boolean
}) {
    return <ControlGroup className={'bp5-small'} style={{gap: 'var(--pt-grid-size)'}}>
        <Checkbox defaultChecked={props.preserveOutput} label="Preserve Buffer" onChange={props.onPreserveOutputChange}
                  className={'bp5-small'}/>
        {props.preserveOutput && <NumericInput placeholder="Scale Buffer"
                                               small={true}
                                               className="numberInput"
                                               leftIcon={'asterisk'}
                                               stepSize={1. / 4}
                                               minorStepSize={1. / 16}
                                               majorStepSize={1. / 2}
                                               defaultValue={props.preserveOutputScale}
                                               onValueChange={props.onPreserveOutputScaleChange}/>}
        <Handle
            type="source"
            position={Position.Right}
            id="textureOut_writeBuffer"
            className="texture-handle pass-preserve-buffer-handle"
            style={{opacity: props.preserveOutput ? '1' : '0', top: '10px', right: '-20px'}} // todo set display?
            isConnectable={props.connectable}
        />
    </ControlGroup>
}
