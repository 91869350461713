import {serializable, serialize, Vector2, Vector3, Vector4} from 'threepipe'
import {createNodeConnectionSlot, NodeData} from './NodeData'
import {IVector, vectorDimension} from '../../components/Vec3InputGroup'
import {MinimizeNodeData} from './MinimizeNodeData'

@serializable('VectorNodeData')
export class VectorNodeData extends MinimizeNodeData implements NodeData {
    @serialize() value: IVector

    // no need to serialize since that is done in value
    @serialize()
    name: string = 'Vector'

    constructor(
        value?: IVector,
    ) {
        super()
        this.value = value || 0
        this.slots.push(createNodeConnectionSlot('vector', {
            name: 'vector',
            getValue: () => this.value,
        }))
    }

    get dimension(): 1 | 2 | 3 | 4{
        return vectorDimension(this.value)
    }

    set dimension(d: 1 | 2 | 3 | 4) {
        const cd = this.dimension
        if(cd === d) return
        let nv = d === 1 ? 0 : d === 2 ? new Vector2() : d === 3 ? new Vector3() : new Vector4()
        if(d >= 1 && cd >= 1){
            let x = cd === 1 ? (this.value as number) : (this.value as Vector2).x
            if(d===1) nv = x
            else (nv as Vector2).x = x
        }
        if(d >= 2 && cd >= 2) (nv as Vector2).y = (this.value as Vector2).y
        if(d >= 3 && cd >= 3) (nv as Vector3).z = (this.value as Vector3).z
        if(d >= 4 && cd >= 4) (nv as Vector4).w = (this.value as Vector4).w
        this.value = nv
    }

    dispose() {
    }

}
