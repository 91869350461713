import {generateUUID, Vector2,} from 'threepipe'
import {VectorNodeData} from './data/VectorNodeData'
import {FlowNodeType} from '../utils/rendering'
import {IVector} from '../components/Vec3InputGroup'

export interface VectorNode extends FlowNodeType<VectorNodeData> {
    position: Vector2
    type: 'vector'
}

// todo merge all the node types and functions with react flow 12 upgrade
export function createVectorNode(vector: IVector, name?: string) {
    const node: VectorNode = {
        id: generateUUID(),
        data: new VectorNodeData(vector),
        position: new Vector2(0, 0),
        type: 'vector'
    }
    if (name) node.data.name = name
    return node
}
