import {Button, ButtonGroup, Classes, Tooltip} from '@blueprintjs/core'
import React from 'react'
import {useProjectActions} from '../utils/idb'
import {useLoadingState} from '../utils/loadingState'
import {useFlowContext} from '../contexts/FlowContext'
import {useNavBarContext} from '../contexts/NavbarContext'
import {useGlobalSettings} from '../contexts/GlobalSettings'

export function FlowNavBarUtilActions(props: {}) {
    const {colorPicker, setColorPicker} = useNavBarContext()
    const {plugin} = useFlowContext()
    const {loadingState, updateLoading} = useLoadingState()
    const actions = useProjectActions()
    const {setUi} = useGlobalSettings()

    return <ButtonGroup style={{gap: '0rem'}}>
        <Tooltip intent="success" content="Color Picker" placement="bottom">
            <Button minimal={true} intent={'success'} active={colorPicker} icon={'style'}
                    onClick={() => setColorPicker(!colorPicker)}/>
        </Tooltip>
        <Tooltip content="Fit View" placement="bottom">
            <Button minimal={true} icon={'zoom-to-fit'}
                    onClick={() => plugin?.flowInstance.fitView({padding: 0.2, duration: 600})}/>
        </Tooltip>
        <Tooltip content="Light/Dark Mode" placement="bottom">
            <Button minimal={true} icon={"flash"}
                    onClick={() => {
                        // todo use VisualStyleContext from uiconfig-blueprint
                        const darkMode = !document.body.classList.contains(Classes.DARK)
                        localStorage.setItem('bpUiDarkMode', darkMode ? 'true' : 'false')
                        document.body.classList.toggle(Classes.DARK, darkMode)
                        setUi({}) // hack
                    }}/>
        </Tooltip>
        <Tooltip content="Save Project" placement="bottom">
            <Button minimal={true} icon={'floppy-disk'} loading={loadingState['save-project']}
                    onClick={async ()=>updateLoading('save-project', actions.saveProject())}
            />
        </Tooltip>

    </ButtonGroup>
}
