import {isProjectDataChanged, useProjectActions} from './idb'
import {useCallback, useEffect, useState} from 'react'
import {useDBContext} from '../contexts/DBContext'
import {useFlowContext} from '../contexts/FlowContext'
import {OverlayToaster, Position} from '@blueprintjs/core'
import {createRoot} from 'react-dom/client'


/** Singleton toaster instance. Create separate instances for different options. */
export const AutoSaveToaster = OverlayToaster.createAsync({
    className: "autosave-toaster",
    position: Position.TOP,
}, {
    // Use createRoot() instead of ReactDOM.render(). This can be deleted after
    // a future Blueprint version uses createRoot() for Toasters by default.
    domRenderer: (toaster, containerElement) => createRoot(containerElement).render(toaster),
});
// react 17
// export const AutoSaveToaster = OverlayToaster.create({
//     className: "autosave-toaster",
//     position: Position.TOP,
// });

export const useAutosave = (interval = 60 * 1000) => {
    const {saveProject} = useProjectActions()
    const [isSaving, setIsSaving] = useState(false);
    const {currentProject} = useDBContext()
    const {plugin} = useFlowContext()
    const saveContent = useCallback(() => {
        if (isSaving) return;
        setIsSaving(true);
        AutoSaveToaster.then(t=>
            t.show({ message: "Auto Saving", intent: "primary", isCloseButtonShown: true, timeout: 1000, icon: 'saved' })
        );
        // react 17
        // AutoSaveToaster.show({ message: "Auto Saving", intent: "primary", isCloseButtonShown: true, timeout: 1000, icon: 'saved' })
        saveProject(true).catch(()=>{}).then(() => {
            setIsSaving(false)
        });
    }, [saveProject, isSaving, setIsSaving]);
    useEffect(() => {
        const autosaveInterval = setInterval(saveContent, interval);
        const handleVisibilityChange = () => {
            if (document.hidden) saveContent();
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            clearInterval(autosaveInterval);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [saveContent, interval]);
    const beforeUnload = useCallback((e: BeforeUnloadEvent) => {
        console.log('before unload fired', )
        const project = plugin?.saveProject()
        console.log(project, currentProject)
        if (!isProjectDataChanged(project, currentProject)) return
        e.preventDefault();
        e.returnValue = '';
        saveContent();
    }, [currentProject, saveContent, plugin])

    // TODO - Malwarebytes plugin in chrome breaks this. Check if the plugin is in use and show a warning to be careful.
    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnload);
        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [beforeUnload]);
};
