import React, {useEffect, useRef} from 'react'
import {MathfieldElement} from 'mathlive'
import {onInlineShortcut} from '../utils/cortex/setup'

export function MathFieldComponent(props: {
    onEnter: (evt: React.KeyboardEvent) => void,
    setValue: (value: string) => void,
    value: string,
}) {
    const mf = useRef<MathfieldElement>(null);
    // Customize the mathfield when it is created
    useEffect(() => {
        if (mf.current) {
            mf.current.mathVirtualKeyboardPolicy = "manual";
            mf.current.onInlineShortcut = onInlineShortcut;
        }
    }, []);
    return (<math-field
        ref={mf}
        onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
                //     data.value = evt.currentTarget.value
                props.onEnter(evt)
            }
        }}
        onInput={evt => {
            // console.log(evt.currentTarget.getValue('plain-text'))
            // console.log(evt.currentTarget.getValue('ascii-math'))
            // console.log(evt.currentTarget.getValue('math-ml'))
            // console.log(evt.currentTarget.getValue('math-json'))
            props.setValue(evt.currentTarget.value)
            console.log(evt.currentTarget.value)
        }}>
        {props.value}
    </math-field>)

}
