// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {useEffect} from 'react'

export function EditableAutoFocusPlugin(props: { editable: boolean }) {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        editor.setEditable(props.editable)
    }, [editor, props.editable]);

    useEffect(() =>
        editor.registerEditableListener((editable: boolean) => {
            if (editable) editor.focus();
            else editor.blur()
        }), [editor]);

    return null;
}
