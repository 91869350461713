import {NodeProps, useReactFlow} from 'reactflow'
import {ShaderMathFunctionNodeData} from '../data/ShaderMathFunctionNodeData'
import {useThreeViewer} from '../../components/ThreeViewerComponent'
import {useFlowContext} from '../../contexts/FlowContext'
import React from 'react'
import {Button, InputGroup, Label} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {NodeHorizResizeControl} from '../../components/NodeHorizResizeControl'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {getSlot} from '../data/NodeData'
import {BaseNodeCard} from '../../components/BaseNodeCard'
import {MathFieldComponent} from '../../components/MathFieldComponent'
import {useShaderCodeEditor} from '../../components/ShaderCodeEditor'

function ShaderMathFunctionNodeFC({data, selected, isConnectable, dragging}: NodeProps<ShaderMathFunctionNodeData>) {
    const viewer = useThreeViewer()
    const flow = useFlowContext()
    const flowInstance = useReactFlow()
    const [preview, setPreview] = React.useState(data.preview)
    const [minimize, setMinimize] = React.useState(data.minimize)
    React.useEffect(() => {
        data.preview = preview
        data.minimize = minimize
    }, [preview, minimize, data])

    const [showLatex, setShowLatex] = React.useState(false)
    const [latexValue, setLatexValue] = React.useState(data.value)
    const [defLatexValue, setDefLatexValue] = React.useState(data.defValue)
    React.useEffect(() => {
        data.defValue = defLatexValue
    }, [defLatexValue, data])
    React.useEffect(() => {
        data.value = latexValue
    }, [latexValue, data])

    const {setShaderError} = useShaderCodeEditor()

    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent title={minimize ? data.name : `Math Function`} buttons={[
                !preview ? null : nodeHeaderButtons.focusPreview(preview, setPreview, data, flow, flowInstance),
                nodeHeaderButtons.preview(preview, setPreview),
                // nodeHeaderButtons.download(() => { // todo:
                //     const blob = flow.viewer?.renderManager.exportRenderTarget(data.value)
                //     return blob ? new File([blob], `${data.value.texture.name}.${blob.ext}`) : undefined
                // })
                nodeHeaderButtons.minimize(minimize, setMinimize),
            ]}/>
            {selected && <NodeHorizResizeControl/>}
            {!minimize && <>
                <Label
                    className="bp5-inline bp5-flex-label bp5-small"
                >
                    Name
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        style={{flex: '1'}}
                        defaultValue={data.name}
                        onChange={(evt) => data.name = evt.target.value}
                    />
                </Label>
                <Label>
                    <MathFieldComponent
                        onEnter={() => {
                            setShaderError('')
                            viewer?.setDirty()
                        }}
                        setValue={setDefLatexValue}
                        value={defLatexValue}
                    />
                </Label>
                <Label>
                    <MathFieldComponent
                        onEnter={() => {
                            setShaderError('')
                            viewer?.setDirty()
                        }}
                        setValue={setLatexValue}
                        value={latexValue}
                    />
                </Label>
                <Label>
                    <Button small={true} minimal={true}
                            text={"Advanced"}
                            rightIcon={showLatex ? 'chevron-up' : 'chevron-down'}
                            onClick={() => setShowLatex(!showLatex)}/>
                </Label>
                {showLatex && <>
                    <Label>
                        <InputGroup
                            small={true}
                            placeholder="Definition"
                            value={defLatexValue}
                            onChange={(evt) => setDefLatexValue(evt.target.value)}
                        />
                    </Label>
                    <Label>
                        <InputGroup
                            small={true}
                            placeholder="Value"
                            value={latexValue}
                            onChange={(evt) => setLatexValue(evt.target.value)}
                        />
                    </Label>
                    <Label>
                        <InputGroup
                            small={true}
                            placeholder="Compiled Code"
                            value={data.compiled}
                            disabled={true}
                        />
                    </Label>
                </>}
            </>}
            <HandleOutSlot type={'shader'} main={true}
                           slot={getSlot(data.slots, 'shader', 'shader')}
                           connectable={isConnectable}/>
        </BaseNodeCard>
    );
}

export default ShaderMathFunctionNodeFC
