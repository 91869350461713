import {NodeProps} from 'reactflow'
import {VectorNodeData} from '../data/VectorNodeData'
import {useThreeViewer} from '../../components/ThreeViewerComponent'
import React from 'react'
import {InputGroup, Label} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {getSlot} from '../data/NodeData'
import {Vec3InputGroup} from '../../components/Vec3InputGroup'
import {BaseNodeCard} from '../../components/BaseNodeCard'
import {vectorTypes, vectorTypesToName} from '../../utils/three'
import {LabelledDropdown} from '../../components/LabelledDropdown'

function VectorNodeFC({type, data, selected, isConnectable, dragging}: NodeProps<VectorNodeData>) {
    const viewer = useThreeViewer()
    const [minimize, setMinimize] = React.useState(data.minimize)
    const [dimension, setDimension] = React.useReducer((s: 1|2|3|4, a:1|2|3|4)=>{
        data.dimension = a
        viewer?.setDirty()
        return a
    }, data.dimension)
    React.useEffect(() => {
        data.minimize = minimize
        data.dimension = dimension
    }, [dimension, minimize, data])
    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent title={minimize ? data.name : 'Vector'} buttons={[
                // nodeHeaderButtons.download(() => { // todo:
                //     const blob = flow.viewer?.renderManager.exportRenderTarget(data.value)
                //     return blob ? new File([blob], `${data.value.texture.name}.${blob.ext}`) : undefined
                // })
                viewer && nodeHeaderButtons.duplicate(type, data, viewer),
                nodeHeaderButtons.minimize(minimize, setMinimize),
            ]}/>
            {!minimize && (<>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    Name
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        defaultValue={data.name}
                        onChange={(evt) => data.name = evt.target.value}
                    />
                </Label>
                <LabelledDropdown
                    small={true}
                    label={"Type"}
                    options={Object.keys(vectorTypes)}
                    defaultValue={vectorTypesToName[dimension]}
                    onChange={(evt) => {
                        if (data.value === undefined) return
                        setDimension(Math.min(4, Math.max(1, parseInt((vectorTypes as any)[evt.target.value]))) as 1|2|3|4)
                        viewer?.setDirty()
                    }}/>
                <Vec3InputGroup
                    key={`dimension-${dimension}`} // Add this line
                    label='Value'
                    name={'value'}
                    value={data.value} onChange={(value) => {
                    data.value = value
                    viewer?.setDirty()
                }}/>

            </>)}
            <HandleOutSlot type={'vector'} main={true}
                           slot={getSlot(data.slots, 'vector', 'vector')}
                           connectable={isConnectable}/>
        </BaseNodeCard>
    );
}

export default VectorNodeFC
