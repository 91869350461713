import {useProjectActions} from '../utils/idb'
import React from 'react'
import {Alignment, Button, Colors, Icon} from '@blueprintjs/core'
import {importShaderToy} from '../utils/importers/shadertoy'
import {useLoadingState} from '../utils/loadingState'
import {useFlowContext} from '../contexts/FlowContext'

export function WelcomeDialogCreateProjectActions(props: {alignText?: Alignment, minimal?: boolean, outlined?: boolean}) {
    const flow = useFlowContext()
    const actions = useProjectActions()
    const {loadingState, updateLoading} = useLoadingState()

    return <>
        <Button icon={<Icon color={Colors.GREEN4} icon={'help'}/>}
                text={'Introduction & Tutorial'}
                minimal={props.minimal} outlined={props.outlined} alignText={props.alignText}
                loading={loadingState['intro-tutorial']}
                onClick={() => updateLoading('intro-tutorial', actions.loadTemplate('samples/Introduction Tutorial.json'))}
        />
        <Button icon={<Icon color={Colors.BLUE4} icon={'add'}/>}
                text={'Create New Project'}
                minimal={props.minimal} outlined={props.outlined} alignText={props.alignText}
                loading={loadingState['create-new']}
                onClick={() => updateLoading('create-new', actions.createProject())}
        />
        {/*<Button icon={<Icon color={Colors.GOLD3} icon={'folder-open'}/>}*/}
        {/*        text={'Open Project'}*/}
        {/*        minimal={props.minimal} outlined={props.outlined} alignText={props.alignText}*/}
        {/*        loading={loadingState['open-file']}*/}
        {/*        onClick={() => updateLoading('open-file', actions.createProject().then(() => flow.plugin?.promptProjectJSON()))}*/}
        {/*/>*/}
        <Button icon={'page-layout'} text={'Shader Toy Import'}
                minimal={props.minimal} outlined={props.outlined} alignText={props.alignText}
                loading={loadingState['shader-toy']}
                onClick={() => updateLoading('shader-toy', actions.createProject().then(() => importShaderToy(flow)))}
        />
        <Button icon={<Icon color={Colors.RED4} icon={'rocket-slant'}/>}
                text={'Browse Community Projects'}
                minimal={props.minimal} outlined={props.outlined} alignText={props.alignText}
                rightIcon={<Icon icon={'share'} size={14}/>}
                disabled
        />
    </>
}
