import {onChangeDispatchEvent, serialize} from 'threepipe'
import {BaseNodeData} from './BaseNodeData'

export abstract class MinimizeNodeData<T extends string = ''> extends BaseNodeData<T | 'minimizeChanged'> {
    @onChangeDispatchEvent('minimizeChanged')
    @serialize() minimize: boolean

    constructor() {
        super()
        this.minimize = false
    }

}
