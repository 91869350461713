import {TFlowContext} from '../../contexts/FlowContext'
import {Button, Colors, InputGroup, Label} from '@blueprintjs/core'
import {ALLOWED_3D_MODEL_EXTENSIONS} from '../flow'
import {createScene3dNode} from '../../nodes/Scene3dNode'
import {autoPlaceNodes} from '../addNodes'
import React from 'react'

// todo merge with remoteTextureAdd
export const remoteSceneAdd = (context: TFlowContext, allowMultiple = false, error?: string) => {
    const {viewer, dialog, setDialog} = context
    if (!viewer || !dialog || !setDialog) return
    const state = {value: 'https://threejs.org/examples/models/gltf/DamagedHelmet/glTF/DamagedHelmet.gltf'}
    setDialog({
        ...dialog,
        canClose: true,
        isOpen: true,
        state,
        title: 'Load 3D Model/Scene',
        content: (
            <Label>
                Enter URL:
                <InputGroup
                    placeholder="https://threejs.org/examples/models/gltf/DamagedHelmet/glTF/DamagedHelmet.gltf"
                    defaultValue={state.value}
                    onChange={(e: any) => state.value = e.target.value}
                />
                {error && <div style={{color: Colors.RED3, margin: '5px'}}>{error}</div>}
                <div style={{marginTop: '10px'}}>
                    Supported Extensions: {ALLOWED_3D_MODEL_EXTENSIONS.join(', ')}
                </div>
            </Label>
        ),
        actions: (
            <>
                <Button text={'Close'} onClick={() => setDialog({...dialog, isOpen: false})}/>
                <Button text={'Load'} intent="primary" onClick={async () => {
                    const url1 = state.value
                    const urls = url1.split(';').filter((u: string) => u.trim())
                    const nodes = []
                    if (!allowMultiple && urls.length > 1) {
                        remoteSceneAdd(context, allowMultiple, 'Multiple URLs not allowed (;)')
                        return
                    }
                    for (const file of urls) {
                        if (!file) continue
                        // todo move all this to loadData, same in localSceneAdd
                        let url
                        try {
                            url = new URL(file)
                        } catch (e) {
                            console.warn('remoteSceneAdd: Invalid URL', file)
                            continue
                        }
                        const extension = url.pathname.includes('.') ? url.pathname.split('.').pop()!.toLowerCase() :
                            url.hash.split('.').pop()?.toLowerCase()
                        // || url.searchParams.get('ext')?.toLowerCase()
                        if (!extension || !ALLOWED_3D_MODEL_EXTENSIONS.includes(extension)) {
                            console.warn('remoteSceneAdd: Invalid extension', extension)
                            continue
                        }
                        const node = createScene3dNode()
                        const loaded = await node.data.loadData(file, viewer)
                        if (loaded)
                            nodes.push(node)
                        else console.warn('remoteSceneAdd: Failed to load object3d', file)
                    }
                    if (nodes.length > 0) {
                        if (nodes.length !== urls.length) {
                            alert('Some URLs failed to load, check console for details') // todo change to proper dialog
                        }
                        setDialog({...dialog, isOpen: false})
                        autoPlaceNodes(viewer, nodes)
                    } else {
                        error = 'Failed to load scene(s), enter a valid URL.'
                        remoteSceneAdd(context, allowMultiple, error)
                    }
                }}/>
            </>
        ),
    })
    // autoPlaceNodes([node])
}
