import {bindToValue, ITexture, serializable, serialize, Texture} from 'threepipe'
import {PreviewNodeData} from './PreviewNodeData'
import {createNodeConnectionSlot, NodeData} from './NodeData'

@serializable('TextureNodeData')
export class TextureNodeData extends PreviewNodeData implements NodeData {
    @serialize() value: ITexture

    // no need to serialize since that is done in value
    @bindToValue({obj: 'value', key: 'name'})
    name!: string

    constructor(
        value?: ITexture,
        preview?: boolean,
    ) {
        super(preview)
        this.value = value || new Texture()
        this.slots.push(createNodeConnectionSlot('texture', {
            name: 'texture',
            getValue: () => this.value,
        }))
    }

    dispose() {
        this.value.dispose()
    }

}
