import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary'

export function RichTextBoxPlugin(props: { fontSize?: number, editable: boolean }) {
    const [editor] = useLexicalComposerContext();
    return <div style={{
        // @ts-ignore
        "--base-bp-font-size": "var(--pt-font-size)",
        "--base-bp-font-size-large": "var(--pt-font-size-large)",
        "--base-bp-font-size-small": "var(--pt-font-size-small)",
    }}><RichTextPlugin
        contentEditable={<ContentEditable
            contentEditable={props.editable}
            data-gramm="false"
            data-gramm_editor="false"
            data-enable_grammarly="false"
            // className={"bp5-running-text"}
            className={"bp5-running-text bp5-text-muted"}
            style={{
                // @ts-ignore
                "--ce-font-scale": `${props.fontSize || 1.}`,
                "--pt-font-size": `calc(var(--base-bp-font-size) * ${props.fontSize || 1.}`,
                "--pt-font-size-large": `calc(var(--base-bp-font-size-large) * ${props.fontSize || 1.}`,
                "--pt-font-size-small": `calc(var(--base-bp-font-size-small) * ${props.fontSize || 1.}`
                // for h1, h2... its done in bp-fixes.scss inside running text.
            }}
        />}
        placeholder={<div>Add some text above. (Markdown Compatible)</div>}
        ErrorBoundary={LexicalErrorBoundary}
    /></div>;
}
