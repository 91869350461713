import {NodeConnectionSlot} from '../nodes/data/NodeData'
import {useShaderCodeEditor} from './ShaderCodeEditor'
import {Button} from '@blueprintjs/core'
import {toTitleCase} from 'ts-browser-helpers'
import React from 'react'

export function ShaderSlotEditButton(props: {
    onClick?: () => void,
    slot: NodeConnectionSlot<'shader'>,
    // showOutHandle?: boolean, // default = false
    connectable: boolean,
    className?: string
}) {
    const {setCodeItem} = useShaderCodeEditor()
    return <Button small={true} large={false} icon="code"
                   className={props.className}
                   style={{marginRight: "10px"}}
                   onClick={() => {
                       setCodeItem(props.slot)
                       props.onClick && props.onClick()
                   }}>{props.slot.label || (toTitleCase(props.slot.name) + ' shader')}</Button>
    // {/*{props.showOutHandle && <HandleOutSlot type={'shader'} main={false}*/}
    // {/*                                       style={{opacity: getSlotVal(props.slot) ? '0.75' : '0.2'}}*/}
    // {/*                                       slot={props.slot}*/}
    // {/*                                       connectable={props.connectable}/>}*/}
}
