import {serializable} from 'threepipe'
import {PassNodeData} from './PassNodeData'
import {NodeRenderPass} from '../../passes/NodeRenderPass'
import {createNodeConnectionSlot} from './NodeData'

@serializable('RenderPassNodeData')
export class RenderPassNodeData extends PassNodeData<NodeRenderPass> {
    constructor(value?: NodeRenderPass, preview?: boolean, preserveOutput?: boolean) {
        super(value || new NodeRenderPass(), preview, preserveOutput)
        this._slots.push(createNodeConnectionSlot('material', {
            name: 'overrideMaterial',
            label: 'Override Material',
            // getValue: () => this.value.overrideMaterial || null, // only input node
            setValue: (value) => this.value.overrideMaterial = value || undefined
        }))
        this.refreshSlots()
    }
}
