import React from 'react'
import {
    Alignment,
    Button,
    Classes,
    Menu,
    MenuDivider,
    MenuItem,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
    Popover,
    Position,
    Tooltip
} from '@blueprintjs/core'
import {FlowNavBarProjectName} from './FlowNavBarProjectName'
import {FlowNavBarUtilActions} from './FlowNavBarUtilActions'
import {FlowNavBarPlaybackControls} from './FlowNavBarPlaybackControls'
import {importShaderToy} from '../utils/importers/shadertoy'
import {useFlowContext} from '../contexts/FlowContext'
import {remoteGLSLAdd} from '../utils/importers/remoteGLSLAdd'
import {importLygiaShader, LygiaLogo} from '../utils/importers/lygia'
import {useGlobalSettings} from '../contexts/GlobalSettings'
import {AddNodeMenu} from './AddNodeMenu'

export function ProjectNavbar(props: {
    currentTimeLabelRef: React.MutableRefObject<HTMLSpanElement | null>
}) {
    const flowContext = useFlowContext()
    const {setDrawerOpen} = useGlobalSettings()
    return <Navbar>
        <NavbarGroup align={Alignment.LEFT}>
            <img src="logo.svg" width={30} height={30} alt="ShaderFlow"/>
            <NavbarHeading>Editor</NavbarHeading>
            <NavbarDivider/>
            <FlowNavBarProjectName/>
            <NavbarDivider/>
            <Popover
                content={
                    <Menu>
                        <MenuItem text="Project JSON" icon="code"
                                  onClick={flowContext.plugin?.promptProjectJSON}/>
                        <MenuDivider/>
                        <MenuItem text="ShaderToy Link" icon="page-layout"
                                  onClick={() => importShaderToy(flowContext)}>
                        </MenuItem>
                        <MenuItem text="Lygia Link" icon={<LygiaLogo/>}
                                  onClick={() => importLygiaShader(flowContext)}>
                        </MenuItem>
                        <MenuItem text="GLSL File URL" icon="link"
                                  onClick={() => remoteGLSLAdd(flowContext)}>
                        </MenuItem>
                    </Menu>
                }
                minimal={false}
                position={Position.BOTTOM_LEFT}
                renderTarget={({isOpen, ref, ...p}) => (
                    <Button
                        {...p} active={isOpen} ref={ref}
                        className={Classes.MINIMAL} text="Import" icon="upload"/>
                )}
            />
            <Popover
                content={
                    <AddNodeMenu/>
                }
                minimal={false}
                position={Position.BOTTOM_LEFT}
                renderTarget={({isOpen, ref, ...p}) => (
                    <Button
                        {...p} active={isOpen} ref={ref}
                        className={Classes.MINIMAL} text="Add" icon="add"/>
                )}
            />
            {/*<NavbarDivider/>*/}
        </NavbarGroup>
        <NavbarGroup align={Alignment.RIGHT}>
            <FlowNavBarUtilActions/>
            <NavbarDivider/>
            <FlowNavBarPlaybackControls currentTimeLabelRef={props.currentTimeLabelRef}/>
            {/*<NavbarDivider/>*/}
            <Tooltip intent="primary" content={'Settings'} placement="bottom">
                <Button minimal={true} intent={'none'} icon={'cog'}
                        onClick={() => setDrawerOpen(true)}/>
            </Tooltip>
        </NavbarGroup>
    </Navbar>
}
