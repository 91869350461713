import {AnyOptions, uploadFile} from 'ts-browser-helpers'
import {
    HalfFloatType,
    ImportResult,
    ITexture,
    LinearSRGBColorSpace,
    OrthographicCamera,
    PerspectiveCamera,
    RGBAFormat,
    SRGBColorSpace,
    TextureDataType,
    ThreeViewer,
    timeout,
    WebGLRenderTarget
} from 'threepipe'
import {createTextureNode} from '../nodes/TextureNode'
import {createRenderTargetNode} from '../nodes/RenderTargetNode'
import {FlowProjectPlugin1} from './plugins/flowProjectPlugin1'
import {ALLOWED_3D_MODEL_EXTENSIONS, ALLOWED_IMAGE_EXTENSIONS} from './flow'
import {createScene3dNode} from '../nodes/Scene3dNode'
import {createCameraNode} from '../nodes/CameraNode'
import {createShaderMathFunctionNode} from '../nodes/ShaderMathFunctionNode'
import {createShaderSnippetNode} from '../nodes/ShaderSnippetNode'
import {FlowEdgeType, FlowNodeType} from './rendering'

export function autoPlaceNodes(viewer: ThreeViewer, nodes: FlowNodeType[], edges?: FlowEdgeType[]) {
    const plugin = viewer.getPlugin(FlowProjectPlugin1)
    if (!plugin?.flowInstance || !nodes.length) return
    const vp = plugin.flowInstance.getViewport()
    const rect = viewer.canvas.getBoundingClientRect()
    // this x, y is from the left center of the viewport.
    // todo change to the first/last node(use this when no node). first find based on the last node, if no space on screen then find based on first node. Try importing some complex shadertoy on empty canvas also to test
    const yPos = (-vp.y + rect.height / 2) / vp.zoom
    let xPos = -vp.x / vp.zoom + 20
    // console.log('autoPlaceNodes', xPos, yPos, vp, rect)
    for (const node of nodes) {
        let i = 0;
        while (plugin.flowInstance.getIntersectingNodes({
            x: xPos, y: yPos+node.position.y,
            width: node.width || 300, height: node.height || 300, // todo
        }, true).length) {
            xPos += (node.width || 300) + 100
            // console.log('intersecting', xPos, yPos)
            if (i++ > 10) break;
        }
        node.position.y += yPos
        node.position.x = xPos
        xPos += 400
    }
    plugin.addElements({nodes, edges})

    const node = nodes[0]
    if(node){
        // hack for now
        timeout(500).then(()=>{
            // check if node is on screen
            const rect1 = {
                x: vp.x,
                y: vp.y,
                width: vp.zoom * rect.width,
                height: vp.zoom * rect.height
            }
            const onScreen = plugin.flowInstance.isNodeIntersecting(node, rect1, false)
            if(!onScreen){
                plugin.flowInstance.fitBounds({
                    x: node.position.x - 100,
                    y: node.position.y - 100,
                    width: node.width || 300,
                    height: node.height || 300
                }, {padding: 0, duration: 600})
            }
        })

    }
}
export const localTextureAdd = async (viewer?: ThreeViewer) => {
    if (!viewer) return
    const files = await uploadFile(true, false, 'image/*')
    const nodes = []
    for (const file of files) {
        const extension = file.name.split('.').pop()?.toLowerCase()
        if (!extension || !ALLOWED_IMAGE_EXTENSIONS.includes(extension)) {
            console.warn('localTextureAdd: Invalid file extension', extension)
            continue
        }
        const texture = await viewer.load<ITexture>({path: '/'+file.name, file})
        if (!texture) {
            console.warn('localTextureAdd: Failed to load texture', file.name)
            continue
        }
        texture.colorSpace = SRGBColorSpace
        const node = createTextureNode(texture, file.name)
        nodes.push(node)
    }
    autoPlaceNodes(viewer, nodes)
}

export const localSceneAdd = async (viewer?: ThreeViewer) => {
    if (!viewer) return
    const files = await uploadFile(true, false, '*/*')
    const nodes = []
    for (const file of files) {
        // todo move this extension thing to loadData, same in remoteSceneAdd
        const extension = file.name.split('.').pop()?.toLowerCase()
        if (!extension || !ALLOWED_3D_MODEL_EXTENSIONS.includes(extension)) {
            console.warn('localSceneAdd: Invalid file extension', extension)
            continue
        }
        const node = createScene3dNode()
        const loaded = await node.data.loadData(file, viewer)
        if(loaded)
            nodes.push(node)
        else console.warn('localSceneAdd: Failed to load object3d', file.name)

    }
    autoPlaceNodes(viewer, nodes)
}

export const newNodeAdd = (viewer: ThreeViewer|undefined, creator: ()=>FlowNodeType|undefined) => {
    if (!viewer) return
    const node = creator()
    node && autoPlaceNodes(viewer, [node])
    return node
}

export const newRenderTargetAdd = (viewer?: ThreeViewer, type?: TextureDataType) => {
    return newNodeAdd(viewer, ()=> {
        const renderTarget = viewer!.renderManager.createTarget<WebGLRenderTarget>({
            size: {width: 512, height: 512},
            type: type || HalfFloatType,
            colorSpace: LinearSRGBColorSpace,
            format: RGBAFormat,
            depthBuffer: false
        })
        return createRenderTargetNode(renderTarget, 'Render Target')
    })
}

export const newCameraAdd = (viewer?: ThreeViewer, perspective = true) => {
    return newNodeAdd(viewer, ()=> createCameraNode(
        perspective ? new PerspectiveCamera(45, 1, 0.1, 20) : new OrthographicCamera(-1, 1, 1, -1, 0.1, 20),
        perspective? 'Perspective Camera': 'Orthographic Camera'))
}

export const newFunctionMathAdd = (viewer?: ThreeViewer) => {
    return newNodeAdd(viewer, ()=> createShaderMathFunctionNode('\\sin(x)', '\\operatorname{sinFunc}:=(x\\in\\mathbb{R})\\to\\mathbb{R}', 'Simple Sine Function'))
}

export const newShaderSnippetAdd = (viewer?: ThreeViewer) => {
    return newNodeAdd(viewer, ()=> createShaderSnippetNode('float add(float a, float b){ return a + b; }', 'Simple Addition'))
}

export function onDropAssets(plugin: FlowProjectPlugin1, e: { assets?: ImportResult[], nativeEvent?: DragEvent } & AnyOptions){
    for (const asset of e.assets || []) {
        if (!asset) continue
        if(asset.nodes){
            plugin.importState(asset)
            continue
        }
        if (asset.isTexture) {
            const node = createTextureNode(asset as ITexture)
            if (!node) continue
            if (e.nativeEvent?.clientX !== undefined) {
                node.position.copy(plugin.flowInstance.project({x: e.nativeEvent.clientX, y: e.nativeEvent.clientY}))
            }
            plugin.addElements({nodes: [node]})
            continue
        }
        console.warn('onDropAssets: Unknown asset type', asset)
    }
}
