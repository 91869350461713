import {serializable, serialize} from 'threepipe'
import {MinimizeNodeData} from './MinimizeNodeData'
import {createNodeConnectionSlot, NodeConnectionSlot, NodeData} from './NodeData'

export type ShaderSnippetLanguages = 'GLSL'
export const shaderLanguageExtensions = {
    GLSL: 'glsl',
}

@serializable('ShaderSnippetNodeData')
export class ShaderSnippetNodeData extends MinimizeNodeData implements NodeData {
    @serialize() name: string = 'Shader Snippet'

    @serialize()
    value: string

    @serialize()
    language: ShaderSnippetLanguages = 'GLSL'

    get ext() {
        return shaderLanguageExtensions[this.language] || 'txt'
    }

    @serialize()
    source: string = '' // like shadertoy id

    constructor(
        value?: string,
    ) {
        super()
        this.value = value || ''
        const slot: NodeConnectionSlot<"shader"> = createNodeConnectionSlot('shader', {
            name: 'shader',
            // hasValue: true, // required since there is setter
            label: 'Shader Snippet',
            getValue: () => this.value,
            setValue: (value) => this.value = value,
        })
        this.slots.push(slot)
    }

    dispose() {
        // not req
    }
}
