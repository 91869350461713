import {Classes, Drawer, DrawerSize, H4, Label, Slider, Switch} from "@blueprintjs/core"
import {useGlobalSettings} from '../contexts/GlobalSettings'
import React from 'react'

export function GlobalSettingsDrawer() {
    const {
        ui, setUi,
        interaction, setInteraction,
        rendering, setRendering,
        drawerOpen, setDrawerOpen
    } = useGlobalSettings()
    return (
        <Drawer
            isOpen={drawerOpen}
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={false}
            enforceFocus={false}
            lazy={true}
            onClose={() => setDrawerOpen(false)}
            hasBackdrop={false}
            shouldReturnFocusOnClose={false} // note dont set to true, messes up react flow (maybe, cannot test easily)
            className="global-settings-drawer"
            isCloseButtonShown={true}
            title="Global Settings"
            transitionName={'none'}
            transitionDuration={0}
            // onClosed={() => onCodeMirrorSubmit(codeString)}

            // position={Position.RIGHT}
            size={DrawerSize.SMALL}
            // portalClassName="code-drawer-portal"
            usePortal={false}
            // portalContainer={flow.flowWrapper}
        >
            <div className={Classes.DRAWER_BODY}>
                <H4>Rendering</H4>
                <Switch defaultChecked={rendering.gammaCorrection ?? true} label="Gamma Correction"
                        onChange={() => setRendering({gammaCorrection: !rendering.gammaCorrection})}
                        className={"bp5-small"}/>
                <br/>
                <H4>UI settings</H4>
                <Switch defaultChecked={ui.nodes_visible ?? true} label="Show Graph"
                        onChange={() => setUi({nodes_visible: !ui.nodes_visible})} className={"bp5-small"}/>
                <Switch disabled={!ui.nodes_visible} defaultChecked={ui.edges_visible ?? true} label="Show Edges"
                        onChange={() => setUi({edges_visible: !ui.edges_visible})} className={"bp5-small"}/>
                {/*<Switch defaultChecked={ui.handles_visible ?? true} label="Show Handles"*/}
                {/*        onChange={() => setUi({handles_visible: !ui.handles_visible})} className={"bp5-small"}/>*/}
                <br/>
                <Switch disabled={!ui.nodes_visible} defaultChecked={ui.node_outlines ?? true} label="Node Outlines"
                        onChange={() => setUi({node_outlines: !ui.node_outlines})} className={"bp5-small"}/>
                <Switch disabled={!ui.nodes_visible} defaultChecked={ui.node_colors ?? true} label="Node Colors"
                        onChange={() => setUi({node_colors: !ui.node_colors})} className={"bp5-small"}/>
                <Switch disabled={!ui.nodes_visible || !ui.edges_visible} defaultChecked={ui.edge_colors ?? true}
                        label="Edge Colors"
                        onChange={() => setUi({edge_colors: !ui.edge_colors})} className={"bp5-small"}/>
                <Switch disabled={!ui.nodes_visible} defaultChecked={ui.handle_colors ?? true} label="Handle Colors"
                        onChange={() => setUi({handle_colors: !ui.handle_colors})} className={"bp5-small"}/>
                <br/>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    <span style={{minWidth: "60px"}}>Shadows</span>
                    <Slider
                        value={ui.node_shadows ?? 3}
                        onChange={(evt) => setUi({node_shadows: evt as any})}
                        min={0}
                        max={4}
                        stepSize={1}
                    />
                </Label>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    <span style={{minWidth: "60px"}}>Corners</span>
                    <Slider
                        value={ui.node_corner_radius ?? 25}
                        onChange={(evt) => setUi({node_corner_radius: evt as any})}
                        min={0}
                        max={40}
                        stepSize={5}
                        labelStepSize={10}
                    />
                </Label>
                <br/>
                <H4>Interaction settings</H4>
                <Switch defaultChecked={interaction.panOnScroll ?? true} label="Pan on Scroll"
                        onChange={() => setInteraction({panOnScroll: !interaction.panOnScroll})}
                        className={"bp5-small"}/>
                <Switch disabled={interaction.panOnScroll} defaultChecked={interaction.zoomOnScroll ?? true}
                        label="Zoom on Scroll"
                        onChange={() => setInteraction({zoomOnScroll: !interaction.zoomOnScroll})}
                        className={"bp5-small"}/>
                <Switch defaultChecked={interaction.zoomOnPinch ?? true} label="Zoom on Pinch"
                        onChange={() => setInteraction({zoomOnPinch: !interaction.zoomOnPinch})}
                        className={"bp5-small"}/>
                <Switch defaultChecked={interaction.leftMouseDrag ?? true} label="Pan on Left Mouse Drag"
                        onChange={() => setInteraction({leftMouseDrag: !interaction.leftMouseDrag})}
                        className={"bp5-small"}/>
                <Switch disabled={interaction.leftMouseDrag} defaultChecked={interaction.selectOnDrag ?? true}
                        label="Selection on Left Mouse"
                        onChange={() => setInteraction({selectOnDrag: !interaction.selectOnDrag})}
                        className={"bp5-small"}/>
            </div>
            {/*<div className={Classes.DRAWER_FOOTER}>*/}
            {/*    <div className={Classes.DIALOG_FOOTER_ACTIONS}>*/}
            {/*        <Button intent='none' onClick={() => setShowCodeInput(false)}>Close</Button>*/}
            {/*        <Button intent='primary' onClick={() => onCodeMirrorSubmit(codeString)}>Run</Button>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </Drawer>
    )
}
