import {generateUUID, Vector2} from 'threepipe'
import {NodeShaderPassBase} from '../passes/NodeShaderPassBase'
import {ShaderPassNodeData} from './data/ShaderPassNodeData'
import {PassNode} from './data/PassNodeData'

export interface ShaderPassNode extends PassNode<ShaderPassNodeData>{type: 'shaderPass'}

export function createShaderPassNode(pass: NodeShaderPassBase, name?: string): ShaderPassNode {
    const node: ShaderPassNode = {
        id: generateUUID(),
        data: new ShaderPassNodeData(pass, true, false),
        position: new Vector2(),
        type: 'shaderPass'
    }
    if(name) node.data.name = name
    return node;
}
