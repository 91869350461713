import {onChange, serializable, serialize} from 'threepipe'
import {PreviewNodeData} from './PreviewNodeData'
import {createNodeConnectionSlot, NodeData} from './NodeData'
import {BoxedExpression} from '@cortex-js/compute-engine'
import {MathfieldElement} from 'mathlive'
import {compileGlsl, defaultCompileTarget} from '../../utils/cortex/compileGlsl'

@serializable('ShaderMathFunctionNodeData')
export class ShaderMathFunctionNodeData extends PreviewNodeData implements NodeData {
    @serialize() name: string = 'Shader Math Function'

    @serialize()
    @onChange('valueChanged')
    value: string

    @serialize()
    @onChange('valueChanged')
    defValue: string // definition value

    mathJson: BoxedExpression | undefined
    compiled: string = ''

    valueChanged() {
        console.log('value changed', this.value)
        try {
            // let fnDef = this.defValue
            // todo also check for \\rightarrow?
            // let fnType = fnDef.split('\\to ')[1] || ('\\' + fnDef.split('\\to\\')[1])
            // if(!fnType || fnType === '\\') fnType = '\\mathbb{R}'
            // else fnDef = fnDef.split('\\to ')[0]

            console.log('Fn Def', this.defValue)
            console.log('Display Lines', this.value)
            let s = displayLinesToBlock(this.value)
            console.log('To Block', s)
            // s = fnDef + '\\mapsto' + s
            // console.log('Fn', s)
            const expr = MathfieldElement.computeEngine!.parse(s)
            if (expr?.isValid) {
                this.mathJson = expr
                ;(this.mathJson as any)!._fnType = MathfieldElement.computeEngine!.parse(this.defValue)
            } else {
                console.warn('Invalid expression', expr?.json, expr)
            }
        } catch (e) {
            console.error(e)
        }
        // console.log(this.mathJson)
        if (!this.mathJson) {
            this.compiled = '1.0'
            return
        }
        try {
            console.log(this.mathJson.json, (this.mathJson as any)!._fnType?.json)
            this.compiled = compileGlsl(this.mathJson, defaultCompileTarget(this.mathJson), 0)?.toString() ?? '1.0'
            const fnType = (this.mathJson as any)!._fnType ? (compileGlsl((this.mathJson as any)!._fnType, defaultCompileTarget((this.mathJson as any)!._fnType), 0)?.toString() || 'float') : 'float'
            this.compiled = fnType + ' ' + this.compiled
            // this.compiled = glsl`(${this.mathJson.unknowns.join(', ')}) (${this.compiled})`
        } catch (e) {
            console.error(e)
            // this.compiled = '1.0'
        }
        // this.compiled = this.compiled.replace('Math.', '')
        // this.compiled = this.compiled.replaceAll('_.x', 'x')
        console.log(this.compiled)
    }

    constructor(
        value?: string,
        defValue?: string,
        preview?: boolean,
    ) {
        super(preview)
        this.value = value || ''
        this.defValue = defValue || ''
        this.valueChanged()
        this.slots.push(createNodeConnectionSlot('shader', {
            name: 'shader',
            // hasValue: true, // not required since there is no setter
            label: 'Math Shader Function',
            // getValue: () => glsl`#define ${this.name}(x) (${this.value})`, // todo make math macro node
            // getValue: () => glsl`#define ${this.name} (${this.compiled})`,
            getValue: () => `${this.compiled}\n`,
        }))
    }

    dispose() {
        this.mathJson = undefined
        this.compiled = ''
    }
}

// \mathrm{Block}\left(\left(a\coloneq1\right),a\right)
// \mathrm{Block}\left(\left(a\coloneq1\right),\left(b\coloneq1\right),a\right)
// \mathrm{Block}\left(\left(a\coloneq1\right),\left(b\coloneq1\right),\left(a\right)\right)


// todo use align or eqnarray https://texblog.net/latex-archive/maths/eqnarray-align-environment/
// {\displaylines a\coloneq1\\ a}
// {\displaylines a\coloneq1\\ a\\ b+a}
// {\displaylines q\coloneq\left|p\right|+b\\ w\coloneq\min\left(\max\left(q.x,0\right),0\right)}
// {\displaylines q\coloneq\left|p\right|+b\\ w\coloneq\min\left(\max\left(q.x,0\right),0\right)}
// {\displaylines q\coloneq\left|p\right|+b\\ w\coloneq\max\left(\min\left(q.x,0\right),0\right)}
// {\displaylines q\coloneq\left|p\right|+b\\ w\coloneq\max\left(\min\left(q[1],0\right),0\right)}
// {\displaylines q\inR\\ q\coloneq\left|p\right|+a_{x}\\ w\coloneq\frac{\min\left(\max\left(q_{x},\max\left(q_{y},q_{z}\right)\right),0\right)}{1}\\ \operatorname{\mathrm{length}}\left(\max\left(q,0\right)\right)+w}
// {\displaylines q\in R;w\in R_2^{}\\ q^{}\coloneq\left|p\right|+a_{x}\\ w\coloneq\min\left(\max\left(q_{x},\max\left(q_{y},q_{z}\right)\right),0\right)\\ \operatorname{\mathrm{length}}\left(\max\left(q,0\right)\right)+w}
// {\displaylines sdBox:\mathbb{R}\to\mathbb{R}\\ q\in\mathbb{R^3};w\in\reals^3\\ q^{}\coloneq\left|p\right|-b+0.1\\ w\coloneq\max\left(q_{x},\max\left(q_{y},q_{z}\right)\right)\\ \operatorname{\mathrm{length}}\left(\max\left(q,0\right)\right)+\min\left(w,0\right)}
// \begin{multline} f(x):\mathbb{R}\to\mathbb{R}\\ q\in\mathbb{R^3};w\in\reals^3\\ q^{}\coloneq\left|p\right|-b+0.1\\ w\coloneq\max\left(q_{x},\max\left(q_{y},q_{z}\right)\right)\\ \operatorname{\mathrm{length}}\left(\max\left(q,0\right)\right)+\min\left(w,0\right)\end{multline}
// \operatorname{argth}(x):\mathbb{R}\to\mathbb{R}\begin{align} q\in\mathbb{R^3};w\in\reals^3\\ q^{}\coloneq\left|p\right|-b+0.1\\ w\coloneq\max\left(q_{x},\max\left(q_{y},q_{z}\right)\right)\\ \operatorname{\mathrm{length}}\left(\max\left(q,0\right)\right)+\min\left(w,0\right)\end{align}
// \begin{multline} q\in\mathbb{R^3};w\in\reals^3\\ q^{}\coloneq\left|p\right|-b+0.1\\ w\coloneq\max\left(q_{x},\max\left(q_{y},q_{z}\right)\right)\\ \operatorname{\mathrm{length}}\left(\max\left(q,0\right)\right)+\min\left(w,0\right)\end{multline}
// \begin{multline} 3(a-x) = 3.5x + a - 1 \\ 3a - 3x = 3.5x + a - 1 \\ a = \frac{13}{4}x - \frac{1}{2}\end{multline}
// \begin{align} 3(a-x) = 3.5x + a - 1 \\ 3a - 3x = 3.5x + a - 1 \\ a = \frac{13}{4}x - \frac{1}{2}\end{align}
// \begin{align} f(x)  & = (a+b)^2 \\& = a^2+2ab+b^2 \\\end{align}
// f\colon\mathrm{R}\rightarrow\mathrm{R}=\mathrm{Block}\left(a\coloneq1,a\right)
// \operatorname{f}:\mathrm{R}\times\mathrm{R}\to\mathrm{R},\quad\operatorname{f}(a,b)=\mathrm{Block\left(c\coloneq1,a+b+c\right)}
// \operatorname{f}(a,b):\mathrm{R}\times\mathrm{R}\to\mathrm{R}\operatorname\mathrm{Block\left(c\coloneq1,a+b+c\right)}
// \operatorname{f}:\mathrm{R}\times\mathrm{R}\to\mathrm{R},\quad\operatorname{f}(a,b)=\begin{align}c&=1\\=a+b+c\end{align}
// \operatorname{f}:\mathrm{R}\times\mathrm{R}\to\mathrm{R},\quad\operatorname{f}(a,b)=\begin{multline}c\coloneq1\\a+b+c\end{multline}
// \operatorname{f}:\mathrm{R}\times\mathrm{R}\to\mathrm{R},\quad\operatorname{f}(a,b)=\begin{gather}c\coloneq1\\a+b+c\end{gather}
// f:=(x\in\mathrm{R},y\in\mathrm{R})\mapsto\mathrm{Block\left(c\coloneq1,a+b+c\right)}
// f:=(x\in\mathbb{R},y\in\mathbb{N})\to\mathbb{R}\mapsto\mathrm{Block}(x+y)
// f:=(x,y)\mapsto\mathrm{Block}(x+y)
// f:=(x,y)\mapsto\lbrace (x\coloneq x+y); x\rbrace
// {\displaylines f:=(x,y)\mapsto\lbrace\\ \quad x\coloneq x+y;\\ \quad x\\\rbrace}
// {\displaylines f:=(x,y)\mapsto\\ \quad x\coloneq x+y\\ \quad x}
// \begin{align} f:=(x,y)\mapsto\\\lbrace\\ \quad x\coloneq x+y;\\ \quad x\\\rbrace\end{align}
// \begin{align} f:=(x,y)\mapsto\lbrace\\ \quad x\coloneq x+y;\\ \quad x\\\rbrace\end{align}
// \begin{gather} f:=(x,y)\mapsto\lbrace\\ \quad x\coloneq x+y;\\ \quad x\\\rbrace\end{gather}
// \begin{gather} f:=(x,y)\mapsto\lbrace\\ x\coloneq x+y;\\ x\\\rbrace\end{gather}
// \begin{align} f:=(x,y)\mapsto\lbrace\\ x\coloneq x+y;\\ x\\\rbrace\end{align}
// \begin{multline} f:=(x,y)\mapsto\lbrace\\ x\coloneq x+y;\\ x\\\rbrace\end{multline}
// \begin{eqnarray} f:=(x,y)\mapsto\lbrace\\ x\coloneq x+y;\\ x\\\rbrace\end{eqnarray}
// \begin{align} f:=(x,y)\mapsto\\ x\coloneq x+y\\ x\end{align}
// \begin{multline} f:=(x,y)\mapsto\\ x\coloneq x+y\\ x\end{multline}
// \begin{eqnarray} f:=(x,y)\mapsto\\ x\coloneq x+y\\ x\end{eqnarray}

// {\displaylines f:=(x,y)\mapsto\\ \quad x\coloneq x+y\\ \quad x}
// \operatorname{f}:=(x,y)
// \operatorname{f}:=(x,y)\mapsto
// {\displaylines x\coloneq x+y\\ x}

// f:=(x\in\mathrm{R},y\in\mathrm{R})\mapsto\mathrm{Block\left(c\coloneq1,a+b+c\right)}
// {\displaylines f:=(x\in\mathrm{R},y\in\mathrm{R}^2)\to\mathrm{R}\mapsto\lbrace\\ \quad x\coloneq x+y\\ \quad x\\\rbrace}
// {\displaylines f:=(x\in\mathrm{R},y\in\mathrm{R}^2)\to\mathrm{R}\mapsto\\ \quad x\coloneq x+y\\ \quad x}
// \operatorname{f}:=(x\in\mathrm{R},y\in\mathrm{R}^2)\to\mathrm{R}
// \operatorname{f}:=(x\in\mathrm{R},y\in\mathrm{R}^2)\to\mathrm{R}\mapsto
// {{{\displaylines q\in\mathrm{R}^2\\ q:=\operatorname{length}(p_{xz})\\ \operatorname{\mathrm{length}}\left(\operatorname{\mathrm{vec2}}\left(\left(q-t_{x}\right),p_{y}\right)\right)-t_{y}}}}

// \operatorname{sdBox}:=(p\in\mathrm{R}^3,b\in\mathrm{R}^3)\to\mathbb{R}
// {{\displaylines q\in\mathrm{R}^3;w\in\mathrm{R}\\ q:=\left|p\right|-b\\ w:=\min\left(\max\left(q_{x},\max\left(q_{y},q_{z}\right)\right),0\right)\\ \operatorname{\mathrm{length}}\left(\max\left(q\cdot0.01,0\right)\right)+w}}

const displayLinesToBlock = (s: string)=>{
    s = s.trim()
    let b = 0
    while(s[b]=== '{'){
        b++
    }
    if(!s.endsWith(Array(b).fill('}').join(''))) {
        console.error('invalid', s)
        return s
    }
    s = s.slice(b, s.length - b)
    if(!s.startsWith('\\displaylines')) s = '\\displaylines '+s
    console.log(s)
    return s  // hacky
            .replace(/\\displaylines ?/g, '\\mathrm{Block}((')
            .replace(/\\\\ ?/g, '),(')
        // .replace(/$/g, '))')
        + '))'
}
