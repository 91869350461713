import {ITexture, serializable, serialize} from 'threepipe'
import {PreviewNodeData} from './PreviewNodeData'
import {createNodeConnectionSlot, NodeData} from './NodeData'

@serializable('RenderToScreenNodeData')
export class RenderToScreenNodeData extends PreviewNodeData implements NodeData {
    texture?: ITexture

    @serialize()
    name: string = 'Render to Screen'

    constructor(
        preview?: boolean,
    ) {
        super(preview)
        this.slots.push(createNodeConnectionSlot('texture', {
            name: 'texture',
            getValue: () => this.texture || null,
            setValue: (v) => this.texture = v || undefined,
        }))
    }

    dispose() {
        this.texture = undefined
    }

}
