import {ControlGroup, FormGroup, NumericInput} from '@blueprintjs/core'
import React from 'react'

export function NearFarInputGroup(props: {
    near: number,
    far: number,
    onChangeNear: (near: number) => void,
    onChangeFar: (far: number) => void,
}) {
    return <FormGroup inline={true}
                      label={"Near/Far Plane"}
                      labelFor={"near-far-input"} className="num-input-form-group bp5-small">
        <ControlGroup id={"near-far-input"}>
            <NumericInput placeholder="Near"
                          small={true}
                          className="numberInput"
                          leftIcon={"full-circle"}
                          stepSize={0.1}
                          minorStepSize={0.01}
                          majorStepSize={1}
                          defaultValue={props.near}
                          onValueChange={(v) => {
                              props.onChangeNear(v)
                          }}
            />
            <NumericInput placeholder="Far"
                          small={true}
                          className="numberInput"
                          leftIcon={"dot"}
                          stepSize={0.1}
                          minorStepSize={0.01}
                          majorStepSize={1}
                          defaultValue={props.far}
                          onValueChange={(v) => {
                              props.onChangeFar(v)
                          }}
            />
        </ControlGroup>
    </FormGroup>
}
