import React, {createContext, createElement} from 'react'

export type TNavBarFlowContext = {
    colorPicker: boolean, setColorPicker: (colorPicker: boolean) => void
    showVersions: boolean, setShowVersions: (showVersions: boolean) => void
}
const NavBarContext = createContext({
    colorPicker: false, setColorPicker: (_colorPicker: boolean) => {},
    showVersions: false, setShowVersions: (_showVersions: boolean) => {},
} as TNavBarFlowContext)

export const useNavBarContext = ()=>React.useContext(NavBarContext)


export function NavbarContextProvider({children}: {children: any}){
    const [colorPicker, setColorPicker] = React.useState<boolean>(false)
    const [showVersions, setShowVersions] = React.useState<boolean>(false)
    return createElement(NavBarContext.Provider, {value: {
            colorPicker, setColorPicker,
            showVersions, setShowVersions,
        }}, children)
}
