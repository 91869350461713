import {NodeProps, useReactFlow} from 'reactflow'
import {ShaderPassNodeData} from '../data/ShaderPassNodeData'
import {useThreeViewer} from '../../components/ThreeViewerComponent'
import React from 'react'
import {useFlowContext} from '../../contexts/FlowContext'
import {Checkbox, Divider, InputGroup, Label} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {HandleInSlot} from '../../utils/HandleInSlot'
import {LabelledDropdown} from '../../components/LabelledDropdown'
import {blending, blendingToName} from '../../utils/three'
import {PreserveOutputBufferControl} from '../../components/PreserveOutputBufferControl'
import {filterSlots, getSlot, getSlotVal, NodeConnectionSlot, setInternalVal} from '../data/NodeData'
import {NodeInOutHandleSlot} from '../../components/NodeInOutHandleSlot'
import {ShaderSlotEditButton} from '../../components/ShaderSlotEditButton'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {BaseNodeCard} from '../../components/BaseNodeCard'
import {Vec3InputGroup} from '../../components/Vec3InputGroup'
import {toTitleCase} from 'ts-browser-helpers'
import {NodeHorizResizeControl} from '../../components/NodeHorizResizeControl'

function ShaderPassNodeFC({type, data, selected, isConnectable, dragging, id}: NodeProps<ShaderPassNodeData>) {
    // const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((evt) => {
    //     console.log(evt.target.value, 'onChange');
    // }, []);
    const viewer = useThreeViewer()
    const [preserveOutput, setPreserveOutput] = React.useState(data.preserveOutput)

    const flow = useFlowContext()
    const flowInstance = useReactFlow()

    const [preview, setPreview] = React.useState(data.preview)
    // todo save minimize like preview in the data so it can also be serialized.
    const [minimize, setMinimize] = React.useState(data.minimize)
    React.useEffect(() => {
        data.preview = preview
        data.minimize = minimize
        data.preserveOutput = preserveOutput
        // console.log('data', data)
    }, [preview, minimize, preserveOutput, data])

    const [slots, setSlots] = React.useState(data.slots)
    React.useEffect(() => {
        const listener = () => setSlots(data.slots)
        data.addEventListener('slotsChanged', listener)
        return () => data.removeEventListener('slotsChanged', listener)
    }, [data, setSlots])

    // todo this only changes when we change something like selected or dragging, we need to do it without that also.
    const inEdgesForNode = flow.plugin?.edges.filter(e=>/*e.source === id || */e.target === id)
    const edgesForNodeKey = inEdgesForNode?.map(e=>e.id).join(' ') || ''
    return (
        <BaseNodeCard key={edgesForNodeKey} minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent
                title={minimize ? data.value.passId : `${(data.value.constructor as any).PassTypeName} Shader Pass`}
                buttons={[
                    !preview ? null : nodeHeaderButtons.focusPreview(preview, setPreview, data, flow, flowInstance),
                    nodeHeaderButtons.preview(preview, setPreview),
                    // minimize ? null : nodeHeaderButtons.download(() => { // todo: export when node is rendered
                    //     const blob = flow.viewer?.renderManager.exportRenderTarget(data.value)
                    //     return blob ? new File([blob], `${data.value.texture.name}.${blob.ext}`) : undefined
                    // })
                    viewer && nodeHeaderButtons.duplicate(type, data, viewer),
                    nodeHeaderButtons.minimize(minimize, setMinimize),
                ]}/>
            <HandleInSlot main={true} type={"buffer"} connectable={isConnectable}/>
            {selected && <NodeHorizResizeControl/>}
            {!minimize && (<>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    Name
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        defaultValue={data.value.passId}
                        onChange={(evt) => data.value.passId = evt.target.value}
                    />
                </Label>
                <LabelledDropdown
                    small={true}
                    label={"Blending"}
                    options={Object.keys(blending)}
                    defaultValue={blendingToName[data.value.blending]}
                    onChange={(evt) => {
                        if (!data.value.material) return
                        data.value.blending = blending[evt.target.value]
                        data.value.setDirty?.()
                    }}/>
                <Checkbox defaultChecked={data.value.clear} label="Clear WriteBuffer" onChange={() => {
                    data.value.clear = !data.value.clear
                    data.value.setDirty?.()
                }} className={"bp5-small"}/>
                <PreserveOutputBufferControl preserveOutput={preserveOutput}
                                             onPreserveOutputChange={() => setPreserveOutput(!preserveOutput)}
                                             preserveOutputScale={data.preserveOutputScale}
                                             onPreserveOutputScaleChange={(value) => {
                                                 data.preserveOutputScale = value
                                                 viewer?.setDirty()
                                             }} connectable={isConnectable}/>
                {filterSlots(slots, 'material').map((slot) =>
                    <NodeInOutHandleSlot key={slot.name} type={'material'} slot={slot} connectable={isConnectable}/>)
                }
                <Divider/>
                {/*Textures*/}
                {filterSlots(slots, 'texture').filter(s => s.name !== 'writeBuffer').map((slot) =>
                    <NodeInOutHandleSlot key={slot.name} type={'texture'} slot={slot} connectable={isConnectable}/>)
                }
                {/*Vectors*/}
                {/*// <NodeInOutHandleSlot key={slot.name} type={'vector'} slot={slot} connectable={isConnectable}/>)*/}
                {filterSlots(slots, 'vector')
                    .map(v=>[v, getSlotVal(v)])
                    // @ts-ignore
                    .filter(v=>v[1] !== undefined)
                    .map(([slot, value]) => (
                            <Vec3InputGroup
                                key={(slot as NodeConnectionSlot).name}
                                label={(slot as NodeConnectionSlot).label ?? toTitleCase((slot as NodeConnectionSlot).name)}
                                name={(slot as NodeConnectionSlot).name}
                                disabled={!!inEdgesForNode?.find(e=>e.targetHandle === 'vectorIn_'+(slot as NodeConnectionSlot).name)}
                                // disabled={value !== (slot as NodeConnectionSlot).defaultValue}
                                value={value as any} onChange={(value) => {
                                setInternalVal((slot as NodeConnectionSlot), value)
                                viewer?.setDirty()
                            }}
                                children1={<HandleInSlot type={'vector'} main={false}
                                                         // style={{position: 'relative', transform: 'unset', marginRight: '10px'}}
                                                          slot={(slot as NodeConnectionSlot)}
                                                          connectable={isConnectable}/>}
                                children2={<HandleOutSlot type={'vector'} main={false}
                                                          slot={(slot as NodeConnectionSlot)}
                                                          connectable={isConnectable}/>}
                            />
                ))}
                <Divider/>
                {/*Includes*/}
                {filterSlots(data.value.dynamicSlots, 'shader',
                    (s)=>s.name.startsWith('dynamic_'))
                    .map((slot) => (
                        <NodeInOutHandleSlot key={slot.name} type={'shader'} slot={slot} connectable={isConnectable}>
                            {!(inEdgesForNode?.find(e=>e.targetHandle===`shaderIn_${slot.name}`)) ?
                                <ShaderSlotEditButton
                                    key={slot.name} slot={slot}
                                    className={"slot-in-control-label"}
                                    connectable={isConnectable}/> : null}
                        </NodeInOutHandleSlot>
                    ))}
                {filterSlots(data.slots, 'shader',
                    (s)=>!s.name.startsWith('dynamic_'))
                    .map((slot) => (
                        <NodeInOutHandleSlot key={slot.name} type={'shader'} slot={slot} connectable={isConnectable}>
                            {!(inEdgesForNode?.find(e=>e.targetHandle===`shaderIn_${slot.name}`)) ?
                                <ShaderSlotEditButton
                                    key={slot.name} slot={slot}
                                    className={"slot-in-control-label"}
                                    connectable={isConnectable}/> : null}
                        </NodeInOutHandleSlot>
                ))}
            </>)}
            <HandleOutSlot type={'buffer'} main={true}
                           slot={getSlot(slots, 'buffer', 'writeBuffer')}
                           connectable={isConnectable}/>

        </BaseNodeCard>
    );
}

export default ShaderPassNodeFC
