import {NodeProps, useReactFlow} from 'reactflow'
import {RenderToScreenNodeData} from '../data/RenderToScreenNodeData'
import {useFlowContext} from '../../contexts/FlowContext'
import React from 'react'
import {InputGroup, Label} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {HandleInSlot} from '../../utils/HandleInSlot'
import {getSlot} from '../data/NodeData'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {BaseNodeCard} from '../../components/BaseNodeCard'
import {NodeHorizResizeControl} from '../../components/NodeHorizResizeControl'

function RenderToScreenNodeFC({data, selected, isConnectable, dragging}: NodeProps<RenderToScreenNodeData>) {
    const flow = useFlowContext()
    const flowInstance = useReactFlow()

    const [preview, setPreview] = React.useState(data.preview)
    const [minimize, setMinimize] = React.useState(data.minimize)
    React.useEffect(() => {
        data.preview = preview
        data.minimize = minimize
        // console.log('data', data)
    }, [preview, minimize, data])

    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent title={data.name || `Render to Screen`} buttons={[
                !preview ? null : nodeHeaderButtons.focusPreview(preview, setPreview, data, flow, flowInstance),
                nodeHeaderButtons.preview(preview, setPreview),
                nodeHeaderButtons.minimize(minimize, setMinimize),
            ]}/>
            <HandleInSlot main={true}
                          slot={getSlot(data.slots, "texture", "texture")!}
                          type={"texture"} connectable={isConnectable}/>
            {selected && <NodeHorizResizeControl/>}
            {!minimize && (<>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    Name
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        defaultValue={data.name}
                        onChange={(evt) => data.name = evt.target.value}
                    />
                </Label>
            </>)}
            <HandleOutSlot type={'texture'} main={true}
                           slot={getSlot(data.slots, 'texture', 'texture')}
                           connectable={isConnectable}/>
        </BaseNodeCard>
    );
}

export default RenderToScreenNodeFC
