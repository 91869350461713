import {
    bindToValue,
    Blending,
    ExtendedShaderPass,
    IWebGLRenderer,
    Material,
    MaterialExtension,
    Matrix3,
    Matrix4,
    NoBlending,
    serialize,
    Shader,
    ShaderChunk,
    ShaderMaterial2,
    Vector2,
    Vector3,
    Vector4,
    WebGLMultipleRenderTargets,
    WebGLRenderTarget
} from 'threepipe'
import {createNodeConnectionSlot, getSlotVal, NodeConnectionSlot, setInternalVal} from '../nodes/data/NodeData'
import {extractIncludes, extractUniforms} from '../utils/shaderBasicParse'
import {cacheLygiaInclude, lygiaMaterialExtension} from '../utils/importers/lygia'

// not serializable, copypass is
export class NodeShaderPassBase extends ExtendedShaderPass {
    // declare ['constructor']: typeof NodeShaderPass & { PassTypeName: string }
    static PassTypeName = 'Pass' // for ui

    @serialize() passId: string = 'shader-pass' // this is the name actually...

    readonly fragSnippetsExtension: MaterialExtension;

    slots: NodeConnectionSlot<any>[] = []

    onSlotsChanged?: () => void

    protected _slots: NodeConnectionSlot<any>[] = []
    dynamicSlots: NodeConnectionSlot<any>[] = []

    setNeedsUpdate(refreshDynamicSlots = true) {
        if(refreshDynamicSlots) this.refreshDynamicSlots()
        this.material.needsUpdate = true
        this.setDirty();
    }
    setDirty() {
        this.dirty = true
        this.material?.setDirty();
        super.setDirty();
    }

    dirty = false

    @serialize()
    @bindToValue({obj: 'material', onChange: 'setNeedsUpdate'})
    vertexShader!: string
    @serialize()
    @bindToValue({obj: 'material', onChange: 'setNeedsUpdate'})
    fragmentShader!: string

    @serialize()
    @bindToValue({obj: 'material', onChange: 'setDirty'})
    blending: Blending

    @serialize()
    get fragSnippetsShader() {
        return this.fragSnippetsExtension.parsFragmentSnippet as string || ''
    }

    set fragSnippetsShader(v) {
        if(this.fragSnippetsExtension.parsFragmentSnippet === v) return
        this.fragSnippetsExtension.parsFragmentSnippet = v
        this.fragSnippetsExtension.setDirty?.()
        this.fragSnippetsExtension.computeCacheKey = Math.random().toString()
        this.setNeedsUpdate()
    }

    // dont serialize. user defined strings mapped to slots.
    includes: Record<string, string> = {}

    ignoredIncludes: string[] = []
    ignoredUniforms: string[] = []

    protected initialized = false

    constructor(shader: Shader | ShaderMaterial2, editable = true, initialized = true, ...textureID: string[]) {
        super((shader as Material).isMaterial ? shader : {
            ...shader,
            uniforms: {
                iResolution: {value: new Vector3()},
                iTime: {value: 0},
                iFrame: {value: 0},
                iMouse: {value: new Vector4()},
                ...Object.fromEntries(textureID.map(t=>[t + 'Size', {value: new Vector2()}])),
                ...shader.uniforms,
            },
        }, ...textureID)

        this.ignoredUniforms.push("iTime", "iFrame", "iMouse", "iResolution", ...textureID.map(t=>t + 'Size'))

        this.material.registerMaterialExtensions([lygiaMaterialExtension])

        this.fragSnippetsExtension = {
            parsFragmentSnippet: '',
            shaderExtender: (shader) => {
                const includes = Object.keys(this.includes)
                for (const include of includes) {
                    // console.log(include, this.includes, this.fragmentShader)
                    let val = this.includes[include]
                    if(!val && include.startsWith('lygia')){
                        val = cacheLygiaInclude(include)
                        this.includes[include] = val
                        const slot = this.dynamicSlots.find(s=>s.name === 'dynamic_include_' + include)
                        if(slot) setInternalVal(slot, val)
                        else console.warn('Slot not found for include: ', include)
                    }
                    shader.vertexShader = shader.vertexShader.replace(new RegExp(`^[ \t]*#include +[<"]${include}[>"]`, 'gm'), val)
                    shader.fragmentShader = shader.fragmentShader.replace(new RegExp(`^[ \t]*#include +[<"]${include}[>"]`, 'gm'), val)
                    // console.log(this.fragmentShader)
                }
            },
            // todo compute cache key required on include change?
            isCompatible: () => true,
            priority: 100,
        }
        this.material.registerMaterialExtensions([this.fragSnippetsExtension])
        this.fragSnippetsExtension.computeCacheKey = Math.random().toString()
        this.fragSnippetsExtension.setDirty?.()
        this.material.needsUpdate = true

        this.clear = false
        this.blending = NoBlending
        for (const tex of textureID) {
            if(!this.material.uniforms[tex]) this.material.uniforms[tex] = {value: null}
            // this.inputs.textures![tex] = this.material.uniforms[tex]
            this._slots.push(createNodeConnectionSlot('texture', { // todo directly map uniform?
                name: tex,
                label: tex,
                getValue: () => this.material.uniforms[tex].value || null,
                setValue: (value) => this.material.uniforms[tex].value = value,
            }))
        }
        if(editable) this.setupSlots()

        if(initialized) {
            this.initialized = true
            this.refreshDynamicSlots()
        }
    }

    protected refreshDynamicSlots() {
        if(!this.initialized) return
        const shaders = this._slots
            .filter(s => s.dataType === 'shader')
            .map<string>(s => getSlotVal(s))
            .join('\n')
        // console.log(shaders)
        const incl = extractIncludes(shaders).map(s=>s[0]).filter(s=>!(ShaderChunk as any)[s] && !this.ignoredIncludes.includes(s))
        const unif = extractUniforms(shaders)
        const uniformTypeMap = {
            'vector': ['vec2', 'vec3', 'vec4', 'float', 'int'],
            'texture': ['sampler2D'],
            // todo
            // 'color': ['vec3', 'vec4'],
            // 'matrix': ['mat3', 'mat4'],
        }
        const uniformTypeDefaults = {
            vec2: ()=>new Vector2(),
            vec3: ()=>new Vector3(),
            vec4: ()=>new Vector4(),
            sampler2D: ()=>null,
            float: ()=>0,
            int: ()=>0,
            mat3: ()=>new Matrix3(),
            mat4: ()=>new Matrix4(),
        }
        // todo defines
        // console.log(incl, unif)
        this.dynamicSlots = []
        for (const inc of incl) {
            if(!this.includes[inc]) {
                let val = ''
                // done in shader extender, dont do here.
                // if(inc.startsWith('lygia/')){
                //     val = cacheLygiaInclude(inc)
                // }
                this.includes[inc] = val
            }
            const slot = createNodeConnectionSlot('shader', {
                name: 'dynamic_include_' + inc,
                label: 'i: ' + inc,
                // hasValue: true,
                getValue: () => this.includes[inc],
                setValue: (value) => {
                    this.includes[inc] = value
                    this.setNeedsUpdate(false)
                },
            })
            this.dynamicSlots.push(slot)
        }
        // console.log(this.includes)
        for (const un of unif) {
            if(!this.material.uniforms[un.name]) {
                this.material.uniforms[un.name] = {value: (uniformTypeDefaults as any)[un.type]?.()??null}
            }
            const type = Object.entries(uniformTypeMap).find(([k, v]) => v.includes(un.type))![0] as keyof typeof uniformTypeMap
            if(!type){
                console.error('Not supported uniform type', un)
                continue
            }
            if(!this.ignoredUniforms.includes(un.name)){
                const slot = createNodeConnectionSlot(type, {
                    name: 'dynamic_uniform_' + un.name,
                    label: 'u: ' + un.name,
                    getValue: () => {
                        let value = this.material.uniforms[un.name].value
                        if(value?.isNumber) value = value.x
                        return value
                    },
                    setValue: (value) => {
                        const last = this.material.uniforms[un.name].value
                        // hack because threejs throws error if we pass number instead of vector in uniforms
                        const val =
                            (typeof value === 'number' && typeof last !== 'number') ?
                            {x:value, isNumber: true} : value
                        if (val !== last && (!(val as any).isNumber || (val as any).x !== last.x)) {
                            this.material.uniforms[un.name].value = val
                            this.material.uniformsNeedUpdate = true
                        }
                    }
                })
                this.dynamicSlots.push(slot)
            }
        }
        // console.log(this.dynamicSlots)
        this.refreshSlots()
    }

    protected refreshSlots() {
        this.slots = [...this._slots, ...this.dynamicSlots]
        this.onSlotsChanged && this.onSlotsChanged()
    }

    protected setupSlots() {
        const vertexSlot = createNodeConnectionSlot('shader', {
            name: 'vertex',
            // hasValue: true,
            getValue: () => this.vertexShader,
            setValue: (value) => {
                // if(value === undefined || value === null) value = vertexSlot.defaultValue!
                this.vertexShader = value
            },
        })
        const fragmentSlot = createNodeConnectionSlot('shader', {
            name: 'fragment',
            // hasValue: true,
            getValue: () => this.fragmentShader,
            setValue: (value) => {
                // todo on empty string set to default value and use internalValue here.
                // if(value === undefined || value === null) value = fragmentSlot.defaultValue!
                this.fragmentShader = value
            },
        })
        this._slots.push(vertexSlot)
        this._slots.push(fragmentSlot)

        const fragSnippetsSlot = createNodeConnectionSlot('shader', {
            name: 'fragSnippets',
            label: 'Frag Snippets',
            getValue: () => this.fragSnippetsShader,
            setValue: (value) => {
                this.fragSnippetsShader = value || ''
            }
        })
        this._slots.push(fragSnippetsSlot)
        this.refreshDynamicSlots()
    }

    render(renderer: IWebGLRenderer, writeBuffer?: WebGLRenderTarget | null, readBuffer?: WebGLMultipleRenderTargets | WebGLRenderTarget, deltaTime?: number, maskActive?: boolean) {
        const renderManager = renderer.renderManager
        this.material.uniforms.iResolution.value.set((writeBuffer || renderManager.renderSize).width, (writeBuffer || renderManager.renderSize).height, 1)
        super.render(renderer, writeBuffer, readBuffer, deltaTime, maskActive);
        // this.lastBuffer = writeBuffer || null // done in renderShaderPassNode
    }

    // toJSON(meta?: SerializationMetaType): ISerializedConfig {
    //     const data: any = ThreeSerialization.Serialize(this, meta, true)
    //     // data.type = this.constructor.PluginType
    //     // data.assetType = 'config'
    //     // this.dispatchEvent({type: 'serialize', data})
    //
    //     // dynamic slot internal values
    //     if(!data.slots) data.slots = {}
    //     for (const slot of this.slots) {
    //         if(slot.name.startsWith('dynamic_')) {
    //             data.slots[slot.name] = slot.defaultValue
    //         }
    //     }
    //     return data
    // }
    //
    // fromJSON(data: ISerializedConfig, meta?: SerializationMetaType): this|null {
    //     // if (data.type !== this.constructor.PluginType && data.type !== this.constructor.OldPluginType)
    //     //     return null
    //
    //     // internal values
    //     if(data.slots) {
    //         for (const slot of this.slots) {
    //             if(data.slots[slot.name] !== undefined) {
    //                 setInternalVal(slot, data.slots[slot.name])
    //             }
    //         }
    //     }
    //
    //     ThreeSerialization.Deserialize(data, this, meta, true)
    //     this.slots.forEach(s=>{
    //         setInternalVal(s, getSlotVal(s))
    //     })
    //     // this.dispatchEvent({type: 'deserialize', data, meta})
    //     return this
    // }
}
