import {HTMLSelect, HTMLSelectProps, Label} from '@blueprintjs/core'
import React from 'react'

export function LabelledDropdown(props: { label: string, small?: boolean } & HTMLSelectProps) {
    const small = props.small ? ' bp5-small':''
    const prop2 = {...props, small: undefined, label: undefined}
    return <Label className={"bp5-inline bp5-flex-label" + small}>
        <span style={{flex: "1 1 40%"}} >{props.label}</span>
        <HTMLSelect style={{flex: "1 1 60%"}} className={"grow" + small} {...prop2}/>
    </Label>
}
