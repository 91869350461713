import {generateUUID, Vector2} from 'threepipe'
import {RenderToScreenNodeData} from './data/RenderToScreenNodeData'
import {FlowNodeType} from '../utils/rendering'

export interface RenderToScreenNode extends FlowNodeType<RenderToScreenNodeData>{position: Vector2, type: 'renderToScreen'}

export function createRenderToScreenNode(name?: string): RenderToScreenNode {
    const node: RenderToScreenNode = {
        id: generateUUID(),
        data: new RenderToScreenNodeData(),
        position: new Vector2(),
        type: 'renderToScreen'
    }
    if(name) node.data.name = name
    return node;
}
