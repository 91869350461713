import {AutoLinkPlugin, createLinkMatcherWithRegExp,} from '@lexical/react/LexicalAutoLinkPlugin';
import * as React from 'react';

// https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/AutoLinkPlugin/index.tsx
const URL_REGEX =
    /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)(?<![-.+():%])/;

const EMAIL_REGEX =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const MATCHERS = [
    createLinkMatcherWithRegExp(URL_REGEX, (text) => {
        return text.startsWith('http') ? text : `https://${text}`;
    }),
    createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
        return `mailto:${text}`;
    }),
];

export default function LexicalAutoLinkPlugin(): JSX.Element {
    return <AutoLinkPlugin matchers={MATCHERS} />;
}

// const URL_MATCHER =
//     /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
//
// const MATCHERS = [
//     (text: string) => {
//         const match = URL_MATCHER.exec(text);
//         if (match === null) {
//             return null;
//         }
//         const fullMatch = match[0];
//         return {
//             index: match.index,
//             length: fullMatch.length,
//             text: fullMatch,
//             url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
//             // attributes: { rel: 'noreferrer', target: '_blank' }, // Optional link attributes
//         };
//     },
// ];
