const errorLines = 12;
function handleSource( string: string, errorLine: number ) {

    console.error( 'Shader Error', errorLine)
    console.error( string)
    const lines = string.split( '\n' );
    const lines2 = [];

    const from = Math.max( errorLine - errorLines/2, 0 );
    const to = Math.min( errorLine + errorLines/2, lines.length );

    for ( let i = from; i < to; i ++ ) {

        const line = i + 1;
        lines2.push( `${line === errorLine ? '>' : ' '} ${line}: ${lines[ i ]}` );

    }

    return lines2.join( '\n' );

}

function getShaderErrors( gl: WebGLRenderingContext, shader: WebGLShader, type: string ) {

    const status = gl.getShaderParameter( shader, gl.COMPILE_STATUS );
    const errors = gl.getShaderInfoLog( shader )?.trim() || '';

    if ( status && errors === '' ) return '';

    const errorMatches = /ERROR: 0:(\d+)/.exec( errors );
    if ( errorMatches ) {

        // --enable-privileged-webgl-extension
        // console.log( '**' + type + '**', gl.getExtension( 'WEBGL_debug_shaders' ).getTranslatedShaderSource( shader ) );

        const errorLine = parseInt( errorMatches[ 1 ] );
        return type.toUpperCase() + '\n\n' + errors + '\n\n' + handleSource( gl.getShaderSource( shader )||'', errorLine );

    } else {

        return errors;

    }

}

export function getShaderErrorString(gl: WebGLRenderingContext, program: WebGLProgram, glVertexShader: WebGLShader, glFragmentShader: WebGLShader) {
    const vertexErrors = getShaderErrors( gl, glVertexShader, 'vertex' );
    const fragmentErrors = getShaderErrors( gl, glFragmentShader, 'fragment' );

    return 'Shader Error ' + gl.getError() + '\n' +
        'VALIDATE_STATUS ' + gl.getProgramParameter( program, gl.VALIDATE_STATUS ) + '\n\n' +
        vertexErrors + '\n' +
        fragmentErrors
}
