// subclasses are serializable, not this
import {PreviewNodeData} from './PreviewNodeData'
import {createNodeConnectionSlot, NodeConnectionSlot, NodeData} from './NodeData'
import {bindToValue, ExtendedShaderPass, Pass, serialize, Vector2} from 'threepipe'
import {FlowNodeType, RenderTargetType} from '../../utils/rendering'
import {NodeShaderPassBase} from '../../passes/NodeShaderPassBase'

export interface PassNode<T extends PassNodeData = PassNodeData> extends FlowNodeType<T>{position: Vector2}

type TPass = (Pass|ExtendedShaderPass|NodeShaderPassBase) & {slots: NodeData['slots']}

export class PassNodeData<T extends TPass = TPass> extends PreviewNodeData implements NodeData{
    private _value!: T
    @serialize() get value(): T{
        return this._value
    }
    set value(v: T){
        if(this._value === v) return
        if(this._value && (this._value as NodeShaderPassBase).onSlotsChanged === this.refreshSlots)
            (this._value as NodeShaderPassBase).onSlotsChanged = undefined
        this._value = v;
        (this._value as NodeShaderPassBase).onSlotsChanged = this.refreshSlots
        this.refreshSlots()
    }
    @serialize() preserveOutput: boolean
    @serialize() preserveOutputScale: number = 1

    @serialize()
    source: string = '' // like shadertoy id

    // no need to serialize since that is done in value
    @bindToValue({obj: 'value', key: 'passId'})
    name!: string

    tempTarget?: RenderTargetType // this is required for preserveOutput
    lastBuffer: RenderTargetType | null = null

    protected _slots: NodeConnectionSlot<any>[] = []
    protected refreshSlots(){
        this.slots = [...this._slots, ...this.value.slots]
    }

    constructor(value: T, preview?: boolean, preserveOutput?: boolean) {
        super(preview)
        this.refreshSlots = this.refreshSlots.bind(this)

        this.value = value
        this.preserveOutput = preserveOutput ?? false


        this._slots.push(createNodeConnectionSlot('buffer', {
            name: 'writeBuffer',
            needsClear: true,
            getValue: () => this.lastBuffer || null,
            setValue: (value) => this.lastBuffer = value || null,
        }))
        this._slots.push(createNodeConnectionSlot('texture', {
            name: 'writeBuffer',
            getValue: () => this.tempTarget?.texture || null,
        }))
        this.refreshSlots()
    }

    // get inputs(){
    //     return this.value?.inputs ?? {textures: {}, buffers: {}, shaders: {}}
    // }

    dispose() {
        this._value?.dispose()
        this.tempTarget?.dispose() // todo this must be released from the render target manager
        this.lastBuffer?.dispose()
        this.tempTarget = undefined
        this.lastBuffer = null
        // todo hack
        ;(this.value as any).dispose && (this.value as any).dispose()
    }
}
