import {Handle, NodeProps, Position, useReactFlow} from 'reactflow'
import {RenderPassNodeData} from '../data/RenderPassNodeData'
import {useThreeViewer} from '../../components/ThreeViewerComponent'
import React from 'react'
import {useFlowContext} from '../../contexts/FlowContext'
import {Checkbox, Divider, InputGroup, Label} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {HandleInSlot} from '../../utils/HandleInSlot'
import {PreserveOutputBufferControl} from '../../components/PreserveOutputBufferControl'
import {filterSlots, getSlot} from '../data/NodeData'
import {NodeInOutHandleSlot} from '../../components/NodeInOutHandleSlot'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {BaseNodeCard} from '../../components/BaseNodeCard'
import {NodeHorizResizeControl} from '../../components/NodeHorizResizeControl'

function RenderPassNodeFC({data, selected, isConnectable, dragging}: NodeProps<RenderPassNodeData>) {
    // const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((evt) => {
    //     console.log(evt.target.value, 'onChange');
    // }, []);
    const viewer = useThreeViewer()
    const [preserveOutput, setPreserveOutput] = React.useState(data.preserveOutput)

    const flow = useFlowContext()
    const flowInstance = useReactFlow()

    const [preview, setPreview] = React.useState(data.preview)
    const [minimize, setMinimize] = React.useState(data.minimize)
    React.useEffect(() => {
        data.preview = preview
        data.minimize = minimize
        data.preserveOutput = preserveOutput
        // console.log('data', data)
    }, [preview, minimize, preserveOutput, data])

    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent
                title={minimize ? data.value.passId : `${(data.value.constructor as any).PassTypeName} Render Pass`}
                buttons={[
                    !preview ? null : nodeHeaderButtons.focusPreview(preview, setPreview, data, flow, flowInstance),
                    nodeHeaderButtons.preview(preview, setPreview),
                    // minimize ? null : nodeHeaderButtons.download(() => { // todo: export when node is rendered
                    //     const blob = flow.viewer?.renderManager.exportRenderTarget(data.value)
                    //     return blob ? new File([blob], `${data.value.texture.name}.${blob.ext}`) : undefined
                    // })
                    nodeHeaderButtons.minimize(minimize, setMinimize),
                ]}/>
            <HandleInSlot main={true} type={"buffer"} connectable={isConnectable}/>
            {selected && <NodeHorizResizeControl/>}
            {!minimize && (<>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    Name
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        defaultValue={data.value.passId}
                        onChange={(evt) => data.value.passId = evt.target.value}
                    />
                </Label>
                <Checkbox defaultChecked={data.value.clear} label="Clear WriteBuffer" onChange={() => {
                    data.value.clear = !data.value.clear
                    data.value.setDirty?.()
                }} className={"bp5-small"}/>
                <PreserveOutputBufferControl preserveOutput={preserveOutput}
                                             onPreserveOutputChange={() => setPreserveOutput(!preserveOutput)}
                                             preserveOutputScale={data.preserveOutputScale}
                                             onPreserveOutputScaleChange={(value) => {
                                                 data.preserveOutputScale = value
                                                 viewer?.setDirty()
                                             }} connectable={isConnectable}/>
                <Divider/>
                {filterSlots(data.slots, 'material').map((slot) =>
                    <NodeInOutHandleSlot key={slot.name} type={'material'} slot={slot} connectable={isConnectable}/>)
                }
                <Divider/>
                {filterSlots(data.slots, 'object3d').map((slot) =>
                    <NodeInOutHandleSlot key={slot.name} type={'object3d'} slot={slot} connectable={isConnectable}/>)
                }
                {filterSlots(data.slots, 'camera').map((slot) =>
                    <NodeInOutHandleSlot key={slot.name} type={'camera'} slot={slot} connectable={isConnectable}/>)
                }
                <Handle
                    type="source"
                    position={Position.Right}
                    id="textureOut_writeBuffer"
                    className="texture-handle pass-preserve-buffer-handle"
                    style={{opacity: preserveOutput ? '1' : '0', top: '115px'}} // todo set display?
                    isConnectable={isConnectable}
                />
            </>)}
            <HandleOutSlot type={'buffer'} main={true}
                           slot={getSlot(data.slots, 'buffer', 'writeBuffer')}
                           connectable={isConnectable}/>

        </BaseNodeCard>
    );
}

export default RenderPassNodeFC
