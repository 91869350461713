import {generateUUID, Vector2,} from 'threepipe'
import {FlowNodeType} from '../utils/rendering'
import {ContextVariablesNodeData} from './data/ContextVariablesNodeData'

export interface ContextVariablesNode extends FlowNodeType<ContextVariablesNodeData> {
    position: Vector2
    type: 'contextVariables'
}

// todo merge all the node types and functions with react flow 12 upgrade
export function createContextVariablesNode(name?: string) {
    const node: ContextVariablesNode = {
        id: generateUUID(),
        data: new ContextVariablesNodeData(),
        position: new Vector2(0, 0),
        type: 'contextVariables'
    }
    if (name) node.data.name = name
    return node
}
