import {createContext, createElement, useContext, useState} from 'react'
import {ProjectRecordType} from '../utils/idb'

export type TDBContext = {
    currentProject?: ProjectRecordType|undefined, setCurrentProject?: (project: ProjectRecordType|undefined) => void,
    projects: ProjectRecordType[], setProjects: (project: ProjectRecordType[]) => void,
    // userAssets?: any[], setUserAssets?: (userAssets: any[]) => void,
    // profiles?: any[], setProfiles?: (profiles: any[]) => void,
}

const DBContext = createContext({
    currentProject: undefined,
    setCurrentProject: (project: ProjectRecordType|undefined) => {},
    projects: [],
    setProjects: (project: ProjectRecordType[]) => {},
} as TDBContext)

export function isProjectEmpty(project: ProjectRecordType){
    return Object.keys(project.project_data).length === 0 && project.name === 'Untitled'
}

export const useDBContext = () => useContext(DBContext)

function useSetupDB(){
    const [currentProject, setCurrentProject] = useState<ProjectRecordType|undefined>(undefined)
    const [projects, setProjects] = useState<ProjectRecordType[]>([])
    return {
        currentProject,
        setCurrentProject,
        projects,
        setProjects,
    }
}

export function DBProvider({children}: {children: any}){
    const value = useSetupDB()
    return createElement(DBContext.Provider, {value}, children)
}
