import React, {createContext, createElement, useCallback, useContext, useState} from 'react'
import {ThreeViewer} from 'threepipe'
import {FlowProjectPlugin1} from '../utils/plugins/flowProjectPlugin1'
import {FlowProjectType} from '../utils/state'
import {FlowEdgeType, FlowNodeType} from '../utils/rendering'
import {FitViewOptions, Viewport} from 'reactflow'

export type DialogStateType = {
    isOpen: boolean, title: string, content: React.ReactNode, actions: React.ReactNode, state: any,
    canClose: boolean // if true, dialog can be closed by clicking outside of it
}

export type TFlowContext = {
    viewer?: ThreeViewer, setViewer: (viewer: ThreeViewer) => void
    flowWrapper?: HTMLDivElement
    plugin?: FlowProjectPlugin1
    keepDirty: boolean, setKeepDirty: (keepDirty: boolean) => void
    dialog: DialogStateType, setDialog: (dialog: DialogStateType) => void
    project: FlowProjectType, setProject: (project: FlowProjectType) => void

    nodes: FlowNodeType[], setNodes: React.Dispatch<React.SetStateAction<FlowNodeType[]>>
    edges: FlowEdgeType[], setEdges: React.Dispatch<React.SetStateAction<FlowEdgeType[]>>
    viewport: Viewport, setViewport: (viewport: Viewport) => void
    rerenderNode: (nodeId: FlowNodeType['id']) => void
    updateNode: (nodeId: FlowNodeType['id'], data: Partial<FlowNodeType>) => void
    getNode: (nodeId: FlowNodeType['id']) => FlowNodeType | undefined

    // session: AuthSession|null, setSession: (session: AuthSession|null) => void
}

const FlowContext = createContext({
    viewer: undefined, setViewer: (_viewer: ThreeViewer) => {},
    flowWrapper: undefined,
    plugin: undefined,
    keepDirty: false,
    setKeepDirty: (_keepDirty: boolean) => {},
    dialog: {isOpen: false, title: '', content: '', actions: '', state: {}, canClose: false},
    setDialog: (_dialog: DialogStateType) => {},
    project: {uid: '', name: '', version: 0, description: ''},
    setProject: (_project: FlowProjectType) => {},

    nodes: [], setNodes: (_) => {},
    edges: [], setEdges: (_) => {},
    viewport: {x: 0, y: 0, zoom: 1},
    setViewport: (_viewport: Viewport) => {},
    rerenderNode: (_nodeId: FlowNodeType['id']) => {},
    updateNode: (_nodeId: FlowNodeType['id'], _data: Partial<FlowNodeType>) => {},
    getNode: (_nodeId: FlowNodeType['id']) => undefined,

    // session: null,
    // setSession: (session: AuthSession|null) => {},
} as TFlowContext)

export const useFlowContext = () => useContext(FlowContext)

const initialNodes: FlowNodeType[] = [];

const initialEdges: FlowEdgeType[] = [];

const fitViewOptions: FitViewOptions = {
    padding: 0.4,
};

function useSetupFlow(){
    const [viewer, setViewer] = React.useState<ThreeViewer | undefined>(undefined)
    const [keepDirty, setKeepDirty] = React.useState(false)
    const [project, setProject] = React.useState<FlowProjectType | undefined>(undefined)
    const plugin: FlowProjectPlugin1 | undefined = React.useMemo(() => viewer?.getPlugin(FlowProjectPlugin1), [viewer])
    const [dialog, setDialog] = React.useState<DialogStateType>({
        isOpen: false, title: '', content: null as React.ReactNode, actions: null as React.ReactNode, state: {} as any, canClose: true,
    })
    const [flowWrapper, setFlowWrapper] = React.useState<HTMLDivElement | undefined>(undefined)

    const [nodes, setNodes] = useState<FlowNodeType[]>(initialNodes);
    const [edges, setEdges] = useState<FlowEdgeType[]>(initialEdges);
    const [viewport, setViewport] = useState<Viewport>({x: 0, y: 0, zoom: 1});

    const rerenderNode = useCallback((nodeId: FlowNodeType['id']) => {
        setNodes((ns)=>ns.map((n) => n.id === nodeId ? {
            ...n, className: (n.className?.replace(/[\s^]rf-version-\d+[\s$]/, '')||'') + ' rf-version-' + (Date.now())
        } : n))
    }, [setNodes])

    const updateNode = useCallback((nodeId: FlowNodeType['id'], data: Partial<FlowNodeType>) => {
        setNodes((ns)=>ns.map((n) => n.id === nodeId ? {...n, ...data} : n))
    }, [setNodes])

    const getNode = useCallback((nodeId: FlowNodeType['id']) => {
        return nodes.find(n => n.id === nodeId)
    }, [nodes])

    return {
        viewer, setViewer,
        flowWrapper, setFlowWrapper,
        plugin,
        keepDirty, setKeepDirty,
        dialog, setDialog,
        project, setProject,
        nodes, setNodes,
        edges, setEdges,
        viewport, setViewport,
        rerenderNode, updateNode, getNode,
        // session, setSession,
    } as TFlowContext
}

export function FlowProvider({children}: {children: any}){
    const value = useSetupFlow()
    return createElement(FlowContext.Provider, {value}, children)
}
