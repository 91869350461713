import {Scene3dNode} from '../../nodes/Scene3dNode'
import {NodeRendererType} from '../rendering'
import {LinearFilter, LinearSRGBColorSpace, UnsignedByteType, Vector2, WebGLRenderTarget} from 'threepipe'
import {updateCameraAspect, updateNodeCamera} from '../updateNodeCamera'
import {FlowViewportRendererPlugin1} from '../plugins/flowViewportRendererPlugin1'

const targetSize = new Vector2(512, 512)

export const object3dRenderer: NodeRendererType = {
    render: (node: Scene3dNode, vs: FlowViewportRendererPlugin1) => {
        if (!(node.data.preview && vs.viewer)) return ''
        if (!node.data.tempTarget) {
            node.data.tempTarget = vs.viewer.renderManager.getTempTarget<WebGLRenderTarget>({
                size: targetSize,
                colorSpace: LinearSRGBColorSpace,
                // colorSpace: SRGBColorSpace, // todo
                type: UnsignedByteType,
                generateMipmaps: false,
                // wrapS: target.texture.wrapS,
                // wrapT: target.texture.wrapT,
                minFilter: LinearFilter,
                magFilter: LinearFilter,
            })
        }

        vs.renderNodePreview(node, node.data.tempTarget)
        // todo do not release target, store it for next frame maybe and rerender on camera or object change.
        //  but that would require releasing the target when it goes out of the screen or something.
        vs.viewer.renderManager.releaseTempTarget(node.data.tempTarget)
        node.data.tempTarget = undefined
        return 'object3dOut'
    },
    beforeRenderToScreen: (node: Scene3dNode, vs, target) => {
        if(!vs.viewer) return target
        // console.log('rendering preview')
        const pass = node.data.renderPass
        pass.scene = node.data.object
        pass.camera = node.data.camera
        pass.clear = true

        const minDist = 2;
        const maxDist = 20;

        const anyNodeMouse = false
        const mouseState = anyNodeMouse || vs.mouseState.nodeId === node.id ? vs.mouseState : undefined

        updateNodeCamera(mouseState, pass.camera, minDist, maxDist)

        const writeBuffer = pass.renderToScreen ? null : target;
        if(writeBuffer) {
            const aspect = writeBuffer ? writeBuffer.width / writeBuffer.height : 1;
            updateCameraAspect(aspect, pass.camera)
        }

        pass.render(vs.viewer.renderManager.webglRenderer, null, target as WebGLRenderTarget)
        return target
    },
    type: 'scene3d',
    // renderOrder: 3,
}
