import {DropzonePlugin, ThreeViewer} from 'threepipe'
import {ReactFlowInstance} from 'reactflow'
import {onDropAssets} from './addNodes'
import {FlowProjectPlugin1} from './plugins/flowProjectPlugin1'
import {NodeTypeDefs} from '../NodeTypeDefs'
import {NodeRendererType} from './rendering'

export const ENABLE_LOCAL_FILE_LOAD = false
export const ALLOWED_IMAGE_EXTENSIONS = ['hdr', 'png', 'jpg', 'jpeg', 'bmp', 'svg', 'webp', 'json', 'ktx', 'ktx2', 'ico', 'avif', 'exr']
export const ALLOWED_3D_MODEL_EXTENSIONS = ['glb', 'gltf', 'zip', 'fbx', 'obj'] // todo add more.

export function initViewerState(viewer: ThreeViewer, flowInstance: ReactFlowInstance): ThreeViewer{
    // // const previewer = v.addPluginSync(RenderTargetPreviewPlugin)
    // viewer.renderManager.autoBuildPipeline = false
    // const screenTarget = viewer.renderManager.createTarget<WebGLRenderTarget>({
    //     sizeMultiplier: 1,
    //     type: UnsignedByteType,
    //     colorSpace: LinearSRGBColorSpace,
    //     format: RGBAFormat,
    //     depthBuffer: false,
    //     generateMipmaps: false,
    // })
    // viewer.renderManager.screenPass.overrideReadBuffer = screenTarget
    // // viewer.renderManager.screenPass.outputColorSpace = LinearSRGBColorSpace
    // viewer.renderManager.screenPass.outputColorSpace = SRGBColorSpace
    //
    // viewer.renderManager.pipeline = ['screen']
    // viewer.renderManager.renderer.debug.checkShaderErrors = true


    const plugin = viewer.addPluginSync(new FlowProjectPlugin1(flowInstance))
    plugin.nodeRenderers.push(...Object.values(NodeTypeDefs).map(o=>o.renderer).filter(v=>!!v) as NodeRendererType[])

    if(ENABLE_LOCAL_FILE_LOAD){
        const dropzone = viewer.addPluginSync(new DropzonePlugin({
            allowedExtensions: ['hdr', 'png', 'jpg', 'jpeg', 'bmp', 'svg', 'webp', 'json', 'ktx', 'ktx2', 'ico', 'avif', 'exr'],
            // domElement: reactFlowInstance.,
        }))
        dropzone.addEventListener('drop', (e) => onDropAssets(plugin, e))
    }

    // viewer.addEventListener('preFrame', () => {
    //     const time = now()
    //     const delta = time - (state.timeState.__last||now())
    //     state.timeState.__last = time
    //     state.timeState.delta = delta
    //
    //     onPreFrame(state)
    // })

    // viewer.addEventListener('preRender', () => {
    //     onPreRender(state)
    //     state.timeState.time += state.timeState.delta
    //     state.timeState.frame++
    // })

    // viewer.addEventListener('postRender', () => {
    //     onPostRender(state)
    // })
    //
    // viewer.addEventListener('postFrame', () => {
    // })

    return viewer
}


// export function updateTimeState(v: ViewerStateData){
//     const time = now()
//     const delta = time - v.timeState.last
//     v.timeState.__last = time
//     v.timeState.time += delta
//     v.timeState.delta = delta
//     v.timeState.frame++
// }
// export function resetTimeState(v?: ViewerStateData){
//     if(!v) return
//     v.timeState.time = 0
//     v.timeState.delta = 0
//     v.timeState.frame = 0
//     v.setDirty()
// }
//
// export function updateViewerState(vs?: ViewerStateData, state?: Partial<ViewerStateData>){
//     if(!vs || !state) return
//     Object.assign(vs, state)
//     vs.setDirty()
// }

