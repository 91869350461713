import {generateUUID, Vector2,} from 'threepipe'
import {RichTextNodeData} from './data/RichTextNodeData'
import {FlowNodeType} from '../utils/rendering'

export interface RichTextNode extends FlowNodeType<RichTextNodeData> {
    position: Vector2
    type: 'richText'
}

// todo merge all the node types and functions with react flow 12 upgrade
export function createRichTextNode(value?: string, name?: string) {
    const node: RichTextNode = {
        id: generateUUID(),
        data: new RichTextNodeData(value),
        position: new Vector2(0, 0),
        type: 'richText'
    }
    if (name) node.data.name = name
    return node
}
