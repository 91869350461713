import {generateUUID, Vector2,} from 'threepipe'
import {ShaderSnippetNodeData} from './data/ShaderSnippetNodeData'
import {FlowNodeType} from '../utils/rendering'

export interface ShaderSnippetNode extends FlowNodeType<ShaderSnippetNodeData> {
    position: Vector2,
    type: 'shaderSnippet'
}

export function createShaderSnippetNode(value: string, name?: string) {
    const node: ShaderSnippetNode = {
        id: generateUUID(),
        data: new ShaderSnippetNodeData(value),
        position: new Vector2(0, 0),
        type: 'shaderSnippet'
    }
    if (name) node.data.name = name
    return node
}
