import {Button, Classes, H4} from '@blueprintjs/core'
import React from 'react'
import {useShaderCodeEditor} from './ShaderCodeEditor'

export function ShaderErrorContainer() {
    const {shaderError, setShaderError} = useShaderCodeEditor()
    return shaderError.length > 0 ? <div className="shader-error-container">
        <div className={Classes.DRAWER_HEADER}>
            <H4 className={Classes.HEADING}>Shader Error</H4>
            <Button icon={'cross'} className="shader-error-cross" minimal={true}
                    onClick={() => setShaderError('')}/>
        </div>
        <div className="shader-error">
            <pre>
            {shaderError}
            </pre>
        </div>
    </div> : null
}
