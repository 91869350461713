import {generateUUID, Vector2,} from 'threepipe'
import {ShaderMathDefineNodeData} from './data/ShaderMathDefineNodeData'
import {FlowNodeType} from '../utils/rendering'

export interface ShaderMathDefineNode extends FlowNodeType<ShaderMathDefineNodeData> {
    position: Vector2,
    type: 'shaderMathDefine'
}

export function createShaderMathDefineNode(value: string, name?: string) {
    const node: ShaderMathDefineNode = {
        id: generateUUID(),
        data: new ShaderMathDefineNodeData(value, true),
        position: new Vector2(0, 0),
        type: 'shaderMathDefine'
    }
    if (name) node.data.name = name
    return node
}

// {\displaylines \operatorname{sdSphere}:=(p\in\mathrm{R}^3,s\in\mathrm{R})\to\mathbb{R} \mapsto\lbrace\\ \quad \operatorname{length}\left(p\right)-s+0.1\\\rbrace}

// \operatorname{sdSphere}:=(p\in\mathrm{R}^3,s\in\mathrm{R})\to\mathbb{R}\mapsto
// {{{\displaylines\operatorname{length}\left(p\right)-s+0.1}}}
