import {NodeProps, useNodeId} from 'reactflow'
import {RichTextNodeData} from '../data/RichTextNodeData'
import React, {useMemo} from 'react'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {getSlot} from '../data/NodeData'
import {BaseNodeCard} from '../../components/BaseNodeCard'
import {BasicMarkdownTextEditor} from '../../lexical/BasicMarkdownTextEditor'
import {NodeHorizResizeControl} from '../../components/NodeHorizResizeControl'
import {useThreeViewer} from '../../components/ThreeViewerComponent'
import {useFlowContext} from '../../contexts/FlowContext'

function RichTextNodeFC({type, data, selected, isConnectable, dragging}: NodeProps<RichTextNodeData>) {
    const viewer = useThreeViewer()
    const flow = useFlowContext()
    const [minimize, setMinimize] = React.useState(data.minimize)
    const [name, setName] = React.useState(data.name)
    const [fontSize, setFontSize] = React.useState(data.editorSettings.fontSize)
    const [value, setValue] = React.useState(data.value)
    React.useEffect(() => {
        data.minimize = minimize
        data.name = name
        data.editorSettings.fontSize = fontSize
        data.value = value
    }, [name, minimize, data, fontSize, value])

    const nodeId = useNodeId()
    const node = useMemo(()=>nodeId ? flow.getNode(nodeId) : undefined, [nodeId, flow])

    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}
                      elevation={false} transparent={true} alwaysShow={true}
        >
            {selected && <NodeHorizResizeControl/>}
            {(selected || minimize) && <NodeHeaderComponent title={name} buttons={[
                !node ? null : nodeHeaderButtons.lockPosition(!(node.draggable ?? true), (v)=>flow.updateNode(nodeId!, {draggable: !v})),
                nodeHeaderButtons.download(() => data.value ? new File([data.value], `${data.name||'rich-text-node'}.${data.ext}`) : undefined),
                minimize ? null : nodeHeaderButtons.fontSize(fontSize, setFontSize, true),
                minimize ? null : nodeHeaderButtons.fontSize(fontSize, setFontSize, false),
                viewer && nodeHeaderButtons.duplicate(type, data, viewer),
                nodeHeaderButtons.minimize(minimize, setMinimize),
            ]} inline={true} setTile={setName}/>}
            {!minimize && (<>
                <BasicMarkdownTextEditor
                    value={value} setValue={setValue}
                    editable={selected}
                    fontSize={fontSize}>
                </BasicMarkdownTextEditor>
            </>)}
            <HandleOutSlot type={'string'} main={true}
                           style={{display: selected ? undefined:"none"}}
                           className={'card-node-minimized'} // hack for now
                           slot={getSlot(data.slots, 'string', 'string')}
                           connectable={isConnectable}/>

        </BaseNodeCard>
    );
}

export default RichTextNodeFC
