import {useProjectActions} from '../utils/idb'
import {useLoadingState} from '../utils/loadingState'
import {H4, HTMLTable, Spinner, Tag} from '@blueprintjs/core'
import {WelcomeDialogEmptyProjectState} from './WelcomeDialogEmptyProjectState'
import React, {useMemo} from 'react'

const templates = [
    "samples/Introduction Tutorial.json",
    "samples/Vignette Effect.json",
    "shadertoy/Global Wind Circulation.json",
    "shadertoy/Paint streams.json",
    "shadertoy/Dual Kawase Blur.json",
    "shadertoy/Expansive Reaction Diffusion.json",
    "shadertoy/Bricks Game.json",
    "lygia/Draw Color Checker.json",
    "lygia/Spectral Wavelength to RGB.json",
    "lygia/Color Iridescence Map.json",
    "lygia/Sample Dither.json",
    "lygia/Draw Shapes.json",
    "lygia/Color Mix.json",
    "lygia/Color Dither.json",
    "lygia/Animation Easing.json",
    "lygia/Draw Mandelbulb.json",
    "lygia/Math functions.json",
    "lygia/Draw Julia.json",
    "lygia/Draw Digits.json",
    "lygia/AA Functions.json",
    "lygia/Animation Sprite.json",
    "lygia/Draw Supershape.json",
    "lygia/Math Gaussian.json",
    "shadertoy/Mystify Screensaver.json",
    "shadertoy/Handwritten Digit Recognizer CNN.json",
    "shadertoy/dithering Color Banding Removal.json",
    "shadertoy/Mie scattering.json",
    "shadertoy/Let's self reflect.json",
    "shadertoy/2D Cloth Simulation.json",
    "shadertoy/Feedback Tree.json",
    "shadertoy/Cyber Fuji 2020.json",
]

export function WelcomeDialogSamplesTab(props: {title: string, filter?: (path: string)=>boolean}) {
    // const {projects} = useDBContext()
    // const projectsSorted = useMemo(() =>
    //     projects.sort((b, a) =>
    //         Number(a.localUpdatedAt || a.updated_at || '0') - (Number(b.localUpdatedAt || b.updated_at || '0'))),
    //     [projects])
    const projects = useMemo(()=>templates
        .filter(props.filter || (() => true))
        .map((path) => ({id: path, name: path.split('/')
                .pop()!.replace(/\.json$/, '')})),
        [props.filter])

    const {loadingState, updateLoading} = useLoadingState()
    const actions = useProjectActions()
    return projects.length > 0 ? (
            <div className="popup-main-container">
                <H4>
                    {props.title}
                </H4>
                {/*<ButtonGroup className="welcome-main-create-actions">*/}
                {/*    <WelcomeDialogCreateProjectActions alignText={Alignment.CENTER} minimal={false} outlined={true}/>*/}
                {/*</ButtonGroup>*/}
                <HTMLTable interactive={true}
                           className="project-list">
                    <thead>
                    <tr>
                        <th>Name</th>
                        {/*<th>Modified</th>*/}
                        {/*<th>Actions</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {projects.map((project) => (
                        <tr key={project.id.replace(' ', '_')}
                    className="project-list-item">
                    {/* todo: someone could load another template while the first one is loading. */}
                    <td
                        onClick={() => !loadingState['open-project-' + project.id.replace(' ', '_')] && updateLoading('open-project-' + project.id.replace(' ', '_'), actions.loadTemplate(project.id))}
                        className="project-list-item-title flex" style={{justifyContent: "space-between"}}>
                        <span style={{gap: "10px", display: "inline-flex"}}>{project.name} {loadingState['open-project-' + project.id.replace(' ', '_')] && <Spinner size={20} intent={'primary'} className={'bp5-inline'}/>}</span>
                        <Tag minimal round intent={'warning'}>{project.id.startsWith('shadertoy') ? 'ShaderToy' : project.id.startsWith('lygia') ? 'Lygia' : 'Template'}</Tag>
                        {/*{isProjectEmpty(project) ? (<Tag minimal round intent={'warning'}>Empty</Tag>) : null}*/}
                        {/*{project.localDeletedAt ? (<Tag minimal round intent={'danger'}>Deleted</Tag>) : null}*/}
                    </td>
                    {/*<td className="project-list-item-modified">*/}
                    {/*    {project.localUpdatedAt || project.updated_at ? new Date(project.localUpdatedAt || project.updated_at!).toLocaleDateString() : 'Never'}*/}
                    {/*</td>*/}
                    {/*<td className="project-list-item-actions">*/}
                    {/*    <ButtonGroup minimal>*/}
                    {/*        /!*Edit Project*!/*/}
                    {/*        {!project.localDeletedAt && <Button*/}
                    {/*            icon={'edit'} loading={loadingState['open-project-' + project.id]}*/}
                    {/*            onClick={() => !project.localDeletedAt && updateLoading('open-project-' + project.id, actions.loadProject(project))}/>}*/}
                    {/*        /!*Open in new Tab*!/*/}
                    {/*        /!*{project.slug && }*!/*/}
                    {/*        /!*<Button small minimal*!/*/}
                    {/*        /!*        icon={'share'}*!/*/}
                    {/*        /!*        onClick={() => updateLoading('open-project-' + project.id, actions.loadProject(project))} />*!/*/}
                    {/*        /!*Download JSON*!/*/}
                    {/*        {!isProjectEmpty(project) && <Button*/}
                    {/*            icon={'download'} loading={loadingState['download-project-' + project.id]}*/}
                    {/*            onClick={(e) => updateLoading('download-project-' + project.id, actions.downloadProjectJSON(project))}/>}*/}
                    {/*        /!*Duplicate Project*!/*/}
                    {/*        {<Button*/}
                    {/*            icon={'duplicate'} loading={loadingState['duplicate-project-' + project.id]}*/}
                    {/*            onClick={() => updateLoading('duplicate-project-' + project.id, actions.duplicateProject(project))}/>}*/}
                    {/*        /!*Delete Project*!/*/}
                    {/*        {!project.localDeletedAt && <Button*/}
                    {/*            icon={'trash'} loading={loadingState['delete-project-' + project.id]}*/}
                    {/*            onClick={(e) => updateLoading('delete-project-' + project.id,*/}
                    {/*                e.shiftKey ? (actions.deleteProjectForce(project, true)) : actions.deleteProject(project))}/>}*/}
                    {/*        /!*More actions*!/*/}
                    {/*    </ButtonGroup>*/}
                    {/*</td>*/}
                    </tr>
                    ))}
                    </tbody>
                </HTMLTable>
            </div>
        ) : (<WelcomeDialogEmptyProjectState/>)
}
