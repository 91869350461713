import React from 'react'
import {Dialog, DialogBody, DialogFooter} from '@blueprintjs/core'
import {useFlowContext} from '../contexts/FlowContext'

export function DialogConnection(){
    const {dialog, setDialog} = useFlowContext()
    return (
        <Dialog title={dialog.title} isOpen={dialog.isOpen}
                // usePortal={true} // need to test properly. see tp-blueprint also...
                canEscapeKeyClose={false} // this has problems when returning from dialog and clearing loading state etc.
                canOutsideClickClose={false}
                isCloseButtonShown={dialog.canClose} onClose={() => {
            dialog.canClose && setDialog({...dialog, isOpen: false})
        }}>
            <DialogBody>
                {dialog.content}
            </DialogBody>
            <DialogFooter actions={dialog.actions}/>
        </Dialog>
    )
}
