import {NodeProps} from 'reactflow'
import {ShaderSnippetNodeData} from '../data/ShaderSnippetNodeData'
import React from 'react'
import {ControlGroup, InputGroup, Label} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {ShaderSlotEditButton} from '../../components/ShaderSlotEditButton'
import {getSlot} from '../data/NodeData'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {BaseNodeCard} from '../../components/BaseNodeCard'
import {useThreeViewer} from '../../components/ThreeViewerComponent'
import {NodeHorizResizeControl} from '../../components/NodeHorizResizeControl'

function ShaderSnippetNodeFC({type, data, selected, isConnectable, dragging}: NodeProps<ShaderSnippetNodeData>) {
    const viewer = useThreeViewer()
    const [minimize, setMinimize] = React.useState(data.minimize)
    React.useEffect(() => {
        data.minimize = minimize
    }, [minimize, data])

    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent title={minimize ? data.name : `Vector`} buttons={[
                nodeHeaderButtons.download(() => data.value ? new File([data.value], `${data.name||'shader-snippet'}.${data.ext}`) : undefined),
                viewer && nodeHeaderButtons.duplicate(type, data, viewer),
                nodeHeaderButtons.minimize(minimize, setMinimize),
            ]}/>
            {selected && <NodeHorizResizeControl/>}
            {!minimize && <>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    Name
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        style={{flex: '1'}}
                        defaultValue={data.name}
                        onChange={(evt) => data.name = evt.target.value}
                    />
                </Label>
                <ControlGroup className={'bp5-small'}>
                <ShaderSlotEditButton slot={getSlot(data.slots, 'shader', 'shader')!}
                                      connectable={isConnectable}/>
                </ControlGroup>
            </>}
            <HandleOutSlot type={'shader'} main={true}
                           slot={getSlot(data.slots, 'shader', 'shader')}
                           connectable={isConnectable}/>
        </BaseNodeCard>
    );
}

export default ShaderSnippetNodeFC
