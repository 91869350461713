import {BaseEdge, BezierEdge, EdgeProps} from 'reactflow'
import React from 'react'

export default function SelfConnectingEdge3(props: EdgeProps) {
    // we are using the default bezier edge when source and target ids are different
    if (props.source !== props.target) {
        return <BezierEdge {...props} />;
    }

    const { sourceX, sourceY, targetX, targetY, id, markerEnd } = props;
    const radiusX = (sourceX - targetX) * 0.3;
    const radiusY = 45;
    const edgePath = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 1 ${
        targetX + 8
    } ${targetY}`;

    return <BaseEdge path={edgePath} markerEnd={markerEnd} />;
}
