import {useFlowContext} from '../contexts/FlowContext'
import {Menu, MenuItem} from '@blueprintjs/core'
import {
    localSceneAdd,
    localTextureAdd,
    newCameraAdd,
    newFunctionMathAdd,
    newNodeAdd,
    newRenderTargetAdd,
    newShaderSnippetAdd
} from '../utils/addNodes'
import {FloatType, HalfFloatType, UnsignedByteType, Vector2, Vector3, Vector4} from 'threepipe'
import {createShaderPassNode} from '../nodes/ShaderPassNode'
import {CopyShaderPass} from '../passes/CopyShaderPass'
import {newShaderToyAdd} from '../utils/importers/shadertoy'
import {createRenderPassNode} from '../nodes/RenderPassNode'
import {NodeDepthRenderPass, NodeNormalRenderPass, NodeRenderPass} from '../passes/NodeRenderPass'
import {ENABLE_LOCAL_FILE_LOAD} from '../utils/flow'
import {remoteTextureAdd} from '../utils/importers/remoteTextureAdd'
import {remoteSceneAdd} from '../utils/importers/remoteSceneAdd'
import {createShaderMathDefineNode} from '../nodes/ShaderMathDefineNode'
import {createRichTextNode} from '../nodes/RichTextNode'
import {VectorIcon} from './VectorIcon'
import {createVectorNode} from '../nodes/VectorNode'
import {createRenderToScreenNode} from '../nodes/RenderToScreenNode'
import {createContextVariablesNode} from '../nodes/ContextVariablesNode'
import React from 'react'

export function AddNodeMenu() {
    const flowContext = useFlowContext()
    const viewer = flowContext.viewer
    return <Menu>
        <MenuItem text="Render Target" icon="heat-grid">
            <MenuItem text="Unsigned Byte" icon="heat-grid"
                      onClick={() => newRenderTargetAdd(viewer, UnsignedByteType)}/>
            <MenuItem text="Half Float" icon="heat-grid"
                      onClick={() => newRenderTargetAdd(viewer, HalfFloatType)}/>
            <MenuItem text="Float" icon="heat-grid"
                      onClick={() => newRenderTargetAdd(viewer, FloatType)}/>
        </MenuItem>
        <MenuItem text="Shader Pass" icon="flows">
            <MenuItem text="Shader Pass (Empty)" icon="flows"
                      onClick={() => newNodeAdd(viewer, () => createShaderPassNode(new CopyShaderPass(), 'Empty Shader Pass'))}/>
            <MenuItem text="Shader Toy (Empty)" icon="page-layout"
                      onClick={() => newShaderToyAdd(viewer)}/>
        </MenuItem>
        <MenuItem text="Render Pass" icon="flow-linear">
            <MenuItem text="Basic Render Pass" icon="flow-linear"
                      onClick={() => newNodeAdd(viewer, () => createRenderPassNode(new NodeRenderPass(), 'Render Pass'))}/>
            <MenuItem text="Depth Render Pass" icon="flow-linear"
                      onClick={() => newNodeAdd(viewer, () => createRenderPassNode(new NodeDepthRenderPass(), 'Depth Render Pass'))}/>
            <MenuItem text="Normal Render Pass" icon="flow-linear"
                      onClick={() => newNodeAdd(viewer, () => createRenderPassNode(new NodeNormalRenderPass(), 'Normal Render Pass'))}/>
        </MenuItem>
        <MenuItem text="Texture" icon="media">
            {ENABLE_LOCAL_FILE_LOAD && (
                <MenuItem text="Local Image/HDR" icon="media"
                          onClick={() => localTextureAdd(viewer)}/>)}
            <MenuItem text="Image/HDR By URL" icon="link"
                      onClick={() => remoteTextureAdd(flowContext)}/>
        </MenuItem>
        <MenuItem text="Object3D" icon="cube">
            {ENABLE_LOCAL_FILE_LOAD && (
                <MenuItem text="Local 3D as scene" icon="cube-add"
                          onClick={() => localSceneAdd(viewer)}/>
            )}
            <MenuItem text="3D scene by URL" icon="cube-add"
                      onClick={() => remoteSceneAdd(flowContext)}/>
        </MenuItem>
        <MenuItem text="Camera" icon="camera">
            <MenuItem text="Perspective Camera" icon="camera"
                      onClick={() => newCameraAdd(viewer, true)}/>
            <MenuItem text="Orthographic Camera" icon="camera"
                      onClick={() => newCameraAdd(viewer, false)}/>
        </MenuItem>
        <MenuItem text="Shader" icon="function">
            <MenuItem text="Snippet" icon="code"
                      onClick={() => newShaderSnippetAdd(viewer)}/>
            <MenuItem text="Math Function &#9888;" icon="variable"
                      onClick={() => newFunctionMathAdd(viewer)}/>
            <MenuItem text="Math Define &#9888;" icon="pulse"
                      onClick={() => newNodeAdd(viewer, () => createShaderMathDefineNode('\\sin(x)'))}/>
        </MenuItem>
        <MenuItem text="Info" icon="edit">
            <MenuItem text="Rich Text" icon="new-text-box"
                      onClick={() => newNodeAdd(viewer, () => createRichTextNode('# Shader Tile\n> by `<your name>`\n\nAdd some description', 'Information'))}/>
        </MenuItem>
        <MenuItem text="Vector" icon="arrow-right">
            <MenuItem text="Number" icon={<VectorIcon dim='1'/>}
                      onClick={() => newNodeAdd(viewer, () => createVectorNode(1))}/>
            <MenuItem text="Vector2" icon={<VectorIcon dim='2'/>}
                      onClick={() => newNodeAdd(viewer, () => createVectorNode(new Vector2(1, 1)))}/>
            <MenuItem text="Vector3" icon={<VectorIcon dim='3'/>}
                      onClick={() => newNodeAdd(viewer, () => createVectorNode(new Vector3(1, 1, 1)))}/>
            <MenuItem text="Vector4" icon={<VectorIcon dim='4'/>}
                      onClick={() => newNodeAdd(viewer, () => createVectorNode(new Vector4(1, 1, 1, 1)))}/>
        </MenuItem>
        <MenuItem text="Extras" icon="more">
            <MenuItem text="Render To Screen" icon="variable"
                      onClick={() => newNodeAdd(viewer, createRenderToScreenNode)}/>
            <MenuItem text="Context Variables" icon="small-info-sign"
                      onClick={() => newNodeAdd(viewer, createContextVariablesNode)}/>
        </MenuItem>
    </Menu>
}
