import React from 'react'

export const LOCAL_STORAGE_KEY = "shader-flow-editor-"
export function getLocalStorage<T = any>(key: string){
    const value = localStorage.getItem(LOCAL_STORAGE_KEY+key)
    if (value === null) return undefined
    return JSON.parse(value) as T
}

export function setLocalStorage(key: string, value: any){
    localStorage.setItem(LOCAL_STORAGE_KEY+key, JSON.stringify(value))
}

// async function syncProjects(projects: ProjectRecordType[]) {
//     let last_updated_at = getLocalStorage<string>("projects_last_updated_at") || null
//     const now = new Date().toISOString()
//     const localUpdatedProjects = projects.filter(project => !!project.localUpdatedAt)
//     const {data, error} = await supabaseClient.rpc('fetch_updated_projects', {last_updated_at})
//     if (error) console.error(error)
//     if (!data) return {projects}
//     let serverUpdatedProjects = data.projects || []
//     last_updated_at = data.last_updated_at || now
//
//     const conflicts: string[] = []
//     for (const serverUpdatedProject of serverUpdatedProjects) {
//         if (localUpdatedProjects.find(p => p.id === serverUpdatedProject.id)) {
//             console.error('conflict', serverUpdatedProject)
//             conflicts.push(serverUpdatedProject.id)
//         }
//     }
//     const newProjects = []
//     const toLocalDelete = []
//     for (const localUpdatedProject of localUpdatedProjects) {
//         if(conflicts.includes(localUpdatedProject.id)) {
//             newProjects.push(localUpdatedProject)
//             continue
//         }
//         if(!localUpdatedProject.created_at){
//             // create on server
//             const {data, error} = await supabaseClient.rpc('create_project')
//             if (error) console.error(error)
//             if (!data) {
//                 newProjects.push(localUpdatedProject)
//                 continue
//             }
//             let serverProject = data
//             const {data: dataUpdate, error: errorUpdate} = await supabaseClient.rpc('update_project', {
//                 project_id: serverProject.id,
//                 project_name: localUpdatedProject.name,
//                 project_project_data: localUpdatedProject.project_data,
//             })
//             if(errorUpdate) console.error(errorUpdate)
//             if(!dataUpdate?.id) {
//                 newProjects.push(localUpdatedProject)
//                 continue
//             }
//             serverProject = {...dataUpdate}
//             newProjects.push(serverProject)
//             toLocalDelete.push(localUpdatedProject)
//             continue
//         }else {
//             // update on server
//             const {data: dataUpdate, error} = await supabaseClient.rpc('update_project', {
//                 project_id: localUpdatedProject.id,
//                 project_name: localUpdatedProject.name,
//                 project_project_data: localUpdatedProject.project_data,
//             })
//             if (error) console.error(error)
//             if (!dataUpdate?.id) {
//                 newProjects.push(localUpdatedProject)
//                 continue
//             }
//             let serverProject = {...dataUpdate}
//             newProjects.push(serverProject)
//             toLocalDelete.push(localUpdatedProject)
//             continue
//         }
//     }
//
//     if(newProjects.length > 0) {
//         const {data: data2, error: error2} = await supabaseClient.rpc('fetch_updated_projects', {last_updated_at})
//         if (error2) console.error(error2)
//         // if (!data) return {projects}
//         const serverUpdatedProjects2 = data2?.projects || []
//         last_updated_at = data2.last_updated_at || now
//
//         serverUpdatedProjects = [...serverUpdatedProjects, ...serverUpdatedProjects2]
//     }
//
//     setLocalStorage("projects_last_updated_at", last_updated_at)
//
//     for (const serverU of serverUpdatedProjects) {
//         const local = newProjects.find(p => p.id === serverU.id)
//         if(local) {
//             if(local.updated_at !== serverU.updated_at) {
//                 // some error or conflict
//                 console.error('conflict 2', local, serverU)
//             }
//             continue
//         }
//         newProjects.push(serverU)
//     }
//
//     if(!newProjects.length && !toLocalDelete.length) return {projects}
//
//     const toLocalUpdate = [...newProjects]
//     for (const project of projects) {
//         if(toLocalDelete.find(p => p.id === project.id)) continue
//         if(newProjects.find(p => p.id === project.id)) continue
//         newProjects.push(project)
//     }
//
//     console.log(data)
//
//     return {projects: newProjects, toLocalDelete, toLocalUpdate}
// }

export function ServerDBConnection(){
    // supabase
    // const {session, setSession} = useFlowContext()
    // const idb = useDBContext()
    // const actions = useProjectActions()
    //
    // React.useEffect(() => {
    //     supabaseClient.auth.getSession().then(async ({data: {session}}) => {
    //         setSession(session)
    //         let projects = await getAllTable<ProjectRecordType>("projects")
    //         const userAssets = await getAllTable("user_assets")
    //         const profiles = await getAllTable("profiles")
    //
    //         // sync local data
    //         const synced = await syncProjects(projects)
    //         projects = synced.projects
    //         synced.toLocalDelete?.forEach(p => {
    //             actions.deleteProjectForce(p, false)
    //         })
    //         synced.toLocalUpdate?.forEach(p => {
    //             actions.updateProjectForce(p, false)
    //         })
    //         console.log(synced, projects)
    //         idb.setProjects(projects)
    //         idb.setUserAssets?.(userAssets)
    //         idb.setProfiles?.(profiles)
    //
    //     })
    //
    //     const {
    //         data: {subscription},
    //     } = supabaseClient.auth.onAuthStateChange((_event, session) => {
    //         setSession(session)
    //     })
    //
    //     return () => subscription.unsubscribe()
    // }, [actions, idb, setSession])

    return <></>
}
