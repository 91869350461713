import {Alignment, Button, Card, Classes, Colors, Elevation, Icon, Overlay2} from '@blueprintjs/core'
import React from 'react'
import {IconName} from '@blueprintjs/icons'
import {MaybeElement} from '@blueprintjs/core/src/common/props'
import {WelcomeDialogProjectsTab} from './WelcomeDialogProjectsTab'
import {useDBContext} from '../contexts/DBContext'
import {WelcomeDialogSamplesTab} from './WelcomeDialogSamplesTab'


const tabs = {
    'projects': {
        icon: 'projects' as IconName,
        text: 'Projects',
        color: Colors.BLUE4,
        // render: () => <WelcomeDialogAccountTab/>
        render: ()=><WelcomeDialogProjectsTab />
    },
    'templates': {
        icon: 'star-empty' as IconName,
        text: 'Samples',
        color: Colors.GOLD4,
        render: () => <WelcomeDialogSamplesTab title={"Samples"}/>
    },
    'learn': {
        icon: 'help' as IconName,
        text: 'Learn',
        color: Colors.GREEN4,
        render: () => <WelcomeDialogSamplesTab title={"Tutorials"} filter={(v)=>v.toLowerCase().includes('tutorial')}/>
    },
    'community': {
        icon: 'rocket-slant' as IconName,
        rightIcon: <Icon icon={'share'} size={10} style={{width: 'auto'}}/>,
        text: 'Community',
        color: Colors.RED4,
        render: () => <WelcomeDialogProjectsTab/>
    },
    'settings': {
        icon: 'cog' as IconName,
        text: 'Settings',
        color: Colors.GRAY4,
        render: () => <WelcomeDialogProjectsTab/>
    },
    'account': {
        icon: 'user' as IconName,
        text: 'Account',
        color: Colors.ORANGE4,
        render: () => <WelcomeDialogAccountTab/>
    },
}

export function WelcomeDialogAuthLogin(){
    // supabase
    // return <Auth providers={[]} magicLink={true} theme="dark" supabaseClient={supabaseClient}
    //              appearance={{
    //                  theme: ThemeSupa,
    //                  className: {
    //                      input: Classes.INPUT + " " + Classes.MINIMAL + " " + Classes.LARGE,
    //                      // button: Classes.BUTTON + " " + Classes.INTENT_PRIMARY + " " + Classes.LARGE,
    //                      label: Classes.NAVBAR_HEADING
    //                  },
    //                  variables: {
    //                      default: {
    //                          colors: {
    //                              brand: Colors.BLUE2,
    //                              brandAccent: Colors.BLUE1,
    //                          },
    //                      },
    //                  },
    //              }}/>
}
export function WelcomeDialogAccountTab() {
    // const {projects} = useDBContext()
    // const {loadingState, updateLoading} = useLoadingState()
    // const actions = useProjectActions()

    // supabase
    // const {session} = useFlowContext()

    return (
        <div className="popup-main-container">
            {/*// supabase*/}
            {/*<H4>*/}
            {/*    {!session ? 'Login or Create an account' : 'Account'}*/}
            {/*</H4>*/}
            {/*{!session && <WelcomeDialogAuthLogin/>}*/}
            {/*{session && <div>*/}
            {/*    Logged in as: {session.user.email}*/}
            {/*    <br/>*/}
            {/*    <br/>*/}
            {/*    <Button onClick={() => supabaseClient.auth.signOut()}>Logout</Button>*/}
            {/*</div>}*/}
        </div>
    )
}

export function WelcomeSidebarListButton(props: {
    name: any,
    val: { color?: string, icon?: IconName | MaybeElement, text: string, rightIcon?: IconName | MaybeElement },
    currentTab: 'settings' | 'projects' | 'learn' | 'templates' | 'community' | 'account',
    disabled?: boolean,
    onClick: () => void
}) {
    return <Button
        id={'welcome-sidebar-list-button-' + props.name}
        icon={<Icon color={props.val.color} icon={props.val.icon}/>} text={props.val.text}
        alignText={Alignment.LEFT}
        disabled={props.disabled}
        minimal fill className="welcome-sidebar-list-button"
        active={props.currentTab === props.name}
        onClick={props.onClick}
        rightIcon={props.val.rightIcon}
    />
}

export function WelcomeScreenDialog(props: { onClose: () => void }) {
    const {currentProject} = useDBContext()
    const [currentTab, setCurrentTab] = React.useState<keyof typeof tabs>('projects')

    return currentProject ? <></> : <Overlay2 isOpen={true} className={Classes.OVERLAY_SCROLL_CONTAINER} onClose={props.onClose}>
        <Card id="welcome-dialog" className={"popup-dialog"} elevation={Elevation.ZERO}>
            <div id="welcome-sidebar">
                <div id="welcome-sidebar-logo">
                    <img src="logo.svg" width={35} height={35} alt="ShaderFlow"/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <h4 style={{margin: "0"}}>Shader Editor</h4>
                        <div>Alpha</div>
                    </div>
                </div>
                <WelcomeSidebarListButton name={"projects"} val={tabs["projects"]} currentTab={currentTab}
                                          onClick={() => setCurrentTab("projects")}/>
                <WelcomeSidebarListButton name={"templates"} val={tabs["templates"]} currentTab={currentTab}
                                          onClick={() => setCurrentTab("templates")}/>
                <WelcomeSidebarListButton name={"learn"} val={tabs["learn"]} currentTab={currentTab}
                                          onClick={() => setCurrentTab("learn")}/>
                <WelcomeSidebarListButton name={"community"} val={tabs["community"]} currentTab={currentTab}
                                          disabled
                                          onClick={() => setCurrentTab("community")}/>
                <WelcomeSidebarListButton name={"settings"} val={tabs["settings"]} currentTab={currentTab}
                                          disabled
                                          onClick={() => setCurrentTab("settings")}/>
                <WelcomeSidebarListButton name={"account"} disabled val={{
                    ...tabs["account"],
                    text: "Login",
                }} currentTab={currentTab} onClick={() => setCurrentTab("account")}/>
            </div>
            <div id="welcome-content">
                {tabs[currentTab].render()}
            </div>
        </Card>
    </Overlay2>
}
