import {serializable} from 'threepipe'
import {PassNodeData} from './PassNodeData'
import {NodeShaderPassBase} from '../../passes/NodeShaderPassBase'
import {CopyShaderPass} from '../../passes/CopyShaderPass'
import {createNodeConnectionSlot} from './NodeData'

@serializable('ShaderPassNodeData')
export class ShaderPassNodeData extends PassNodeData<NodeShaderPassBase> {
    constructor(value?: NodeShaderPassBase, preview?: boolean, preserveOutput?: boolean) {
        super(value || new CopyShaderPass(), preview, preserveOutput)
        this._slots.push(createNodeConnectionSlot('material', {
            name: 'shaderMaterial',
            label: 'Shader Material',
            getValue: () => this.value.material, // only output node
        }))
        this.refreshSlots()
    }
}
