import {Color, generateUUID, Vector2, WebGLRenderTarget} from 'threepipe'
import {RenderTargetNodeData} from './data/RenderTargetNodeData'
import {FlowNodeType} from '../utils/rendering'

export interface RenderTargetNode extends FlowNodeType<RenderTargetNodeData> {
    position: Vector2
    type: 'renderTarget'
}

export function createRenderTargetNode(target: WebGLRenderTarget, name?: string, data?: RenderTargetNodeData) {
    const node: RenderTargetNode = {
        id: generateUUID(),
        data: data || new RenderTargetNodeData(target, true, new Color(0, 0, 0), 1, true),
        position: new Vector2(0, 0),
        type: 'renderTarget'
    }
    if (name) node.data.name = name
    return node
}
