import {NodeResizeControl, ResizeControlVariant} from 'reactflow'
import {Icon} from '@blueprintjs/core'
import React from 'react'

export function NodeHorizResizeControl() {
    return <NodeResizeControl
        maxWidth={1024} // 64rem
        minWidth={256} // 16rem
        /*minHeight={30}*/
        className={'card-resize-handle-horiz'}
        variant={ResizeControlVariant.Handle}
    > <Icon icon={'arrows-horizontal'}/>
    </NodeResizeControl>
}
