import {AViewerPluginSync, serialize, ThreeViewer, Vector2, Vector3, Vector4} from 'threepipe'
import {FlowMouseState, FlowTimeState} from '../state'
import {now} from 'ts-browser-helpers'
import {onPointerDown, onPointerMove, onPointerUp, onPointerWheel} from '../mouse'
import {ShaderPassNode} from '../../nodes/ShaderPassNode'
import {FlowEdgeType, FlowNodeType, NodePreviewTargetType, NodeRendererType} from '../rendering'
import {Connection} from 'reactflow'
import {resolveNodeInputs} from '../renderers/pass'

export class FlowRendererPlugin1<E extends string = string> extends AViewerPluginSync<E> {
    public static readonly PluginType: string = 'FlowRendererPlugin1'
    enabled = true

    @serialize()
    userData: any = {}

    get viewer() {
        return this._viewer
    }

    constructor() {
        super()
    }

    @serialize()
    nodes: FlowNodeType[] = []
    @serialize()
    edges: FlowEdgeType[] = []
    @serialize()
    mouseState: FlowMouseState = {
        position: new Vector2(),
        scroll: {
            delta: new Vector3(),
            mode: 'pixel',
            time: 0,
            always: false
        },
        clickPosition: new Vector2(),
        isDown: false,
        isClick: false,
        clientX: 0,
        clientY: 0,
        nodeId: '',
    }
    @serialize()
    timeState: FlowTimeState = {
        time: 0,
        delta: 0,
        frame: 0,
        running: false,
    }
    _lastTime = 0

    @serialize()
    keepDirty = false

    protected _nodesRendered: FlowNodeType[] = []

    // node_id, viewport
    protected _renderedViewports: [string, Vector4][] = [] // todo add if viewport can scroll. actually not required since we are checking if the scroll value is consumed.
    get renderedViewports() {
        return this._renderedViewports
    }

    mainNode: FlowNodeType | null = null // all nodes are rendered if this is null

    renderNodePreview(node: FlowNodeType, target?: NodePreviewTargetType, clear?: boolean) {
        // implement in subclass
        return
    }

    nodeRenderers: NodeRendererType[] = []

    renderNode(node: FlowNodeType): string | undefined {
        if (this._nodesRendered.includes(node)) return
        this._nodesRendered.push(node)

        const renderer = this.nodeRenderers.find(r => (!r.type || r.type === node.type) && (!r.canRender || r.canRender(node)))

        if(renderer?.autoResolveInputs !== false) {
            let inputEdges = this.inputEdges(node)
            resolveNodeInputs(inputEdges, this, node.data.slots)
        }

        if (!renderer) {
            // console.error('invalid source node with bufferOut and not rendered', node)
            this.renderNodePreview(node)
        } else {
            return renderer.render(node, this)
        }
        // if (node.type === 'shaderPass') {
        //     renderShaderPassNode(vs, node as ShaderPassNode)
        //     return 'bufferOut'
        // } else if (node.type === 'renderTarget') {
        //     renderRenderTargetNode(vs, node as RenderTargetNode, false)
        //     return 'bufferOut'
        // } else if (node.type === 'texture') {
        //     renderTextureNode(vs, node as TextureNode)
        //     return 'textureOut'
        // } else console.error('invalid source node with bufferOut and not rendered', node)
    }

    resetTimeState = () => {
        this.timeState.time = 0
        this.timeState.delta = 0
        this.timeState.frame = 0
        this.setDirty()
    }

    onAdded(viewer: ThreeViewer) {
        super.onAdded(viewer);

        // const previewer = v.addPluginSync(RenderTargetPreviewPlugin)
        viewer.renderManager.autoBuildPipeline = false

        viewer.renderManager.pipeline = ['screen']
        viewer.renderManager.renderer.debug.checkShaderErrors = true


        viewer.addEventListener('preFrame', () => {
            const time = now()
            const delta = time - (this._lastTime || now())
            this._lastTime = time
            this.timeState.running = this.keepDirty || (this.mouseState.isDown && !!this.mouseState.nodeId)
            if(this.timeState.running)
                this.timeState.delta = delta
            this._onPreFrame()
        })
        viewer.addEventListener('preRender', () => {
            this._onPreRender()
            if(this.timeState.running) {
                this.timeState.time += this.timeState.delta
                this.timeState.frame++
            }
        })

        viewer.addEventListener('postRender', () => {
            this._onPostRender()
        })

        viewer.addEventListener('postFrame', () => {
        })


        // todo remove events
        viewer.container.parentElement?.addEventListener('pointerdown', (e) => onPointerDown(this, e))
        viewer.container.parentElement?.addEventListener('pointerup', (e) => onPointerUp(this, e))
        viewer.container.parentElement?.addEventListener('pointermove', (e) => onPointerMove(this, e))
        viewer.container.parentElement?.addEventListener('wheel', (e) => onPointerWheel(this, e), {passive: false, capture: true})
    }

    protected _onPreRender() {
        // if (!vs.nodes || !vs.edges || !vs.timeState) return
        this._nodesRendered = []

        if (!this.mainNode) {
            // const order = [, 'renderTarget','shaderPass', 'texture']
            const nodes = this.nodes.filter(n => n.type)
                // .sort((a, b) => order.indexOf(a.type!) - order.indexOf(b.type!))
            // console.log('preRender', nodes, edges)
            for (const node of nodes) {
                this.renderNode(node)
            }
        } else {
            this.renderNode(this.mainNode)
        }

        // const canvasRect = this.viewer!.canvas.getBoundingClientRect()
        // this._renderedViewports = [new Vector4(0, 0, canvasRect.width, canvasRect.height)]

    }

    protected _onPreFrame() {
        // console.log(this.mouseState.isDown)
        if (this.keepDirty || this.mouseState.isDown) {
            this.setDirty()
            return
        }
        if (!this.nodes) return
        const passes = this.nodes.filter(n => n.type === 'shaderPass') as ShaderPassNode[]
        const dirty = passes.filter(p => p.data.value.dirty)
        if (!dirty.length) return
        this.setDirty()
        dirty.forEach(p => p.data.value.dirty = false)
    }

    protected _onPostRender() {
        if (this.mouseState?.isClick) this.mouseState.isClick = false
        if (!this._viewer) return
        const passes = this.nodes.filter(node => node.type === 'shaderPass') as ShaderPassNode[]
        for (const node of passes) {
            // todo we need to clear last buffer when its being written on by some other pass next frame
            //  maybe its done now, check renderPass
            // node.data.lastBuffer = null

            // when preserverOutput is false and tempTarget is set, we need to release it
            if (node.data.preserveOutput) continue
            const target = node.data.tempTarget
            if (!target) continue
            this._viewer.renderManager.releaseTempTarget(target)
            node.data.tempTarget = undefined
            const edges2 = this.edges.filter(edge => edge.source === node.id && edge.sourceHandle === 'textureOut_writeBuffer')
            if (edges2.length > 0) this.deleteElements({edges: edges2})
        }
    }

    deleteElements(data: { nodes?: FlowNodeType[], edges?: FlowEdgeType[] }) {
        // this.flowInstance.deleteElements(data) // in subclass
    }

    // this is only called from importState, not when the user adds(unless added from ui). todo fix...
    addElements(data: { nodes?: FlowNodeType[], edges?: FlowEdgeType[] }) {
        data.nodes?.forEach(initNode)
        data.edges?.forEach(initConnection)
    }


    setDirty = () => {
        this.viewer?.setDirty(this)
    }

    @serialize()
    readonly asset = {
        generator: 'shader-flow-editor',
        version: '0.1',
        date: new Date().toISOString(),
    }

    async importState(_state: any, replace = true): Promise<void> {
        const state = {..._state}
        if (state.asset) {
            if (state.asset.generator !== this.asset.generator) {
                console.warn('This file was generated with an invalid generator, trying to import anyway', state.asset)
            }
            if (parseFloat(state.asset.version) > parseFloat(this.asset.version)) {
                console.warn('This file was generated with a newer version, trying to import anyway', state.asset)
            }
            delete state.asset
        }
        // if(state.nodes || state.edges) {
        //     this.addElements({nodes: state.nodes, edges: state.edges})
        //     delete state.nodes
        //     delete state.edges
        // }
        const {nodes, edges} = this
        if(replace){
            this.deleteElements({nodes, edges})
        }
        this.nodes = []
        this.edges = []
        await super.importState(state);
        this.addElements({nodes: this.nodes, edges: this.edges})
        if(!replace){
            const nodes2 = this.nodes
            const edges2 = this.edges
            this.nodes = nodes
            this.edges = edges
            this.nodes.push(...nodes2)
            this.edges.push(...edges2)
        }
        this.setDirty()
    }

    inputEdges(node: FlowNodeType){
        return this.edges.filter(e => e.target === node.id)
    }

}

export function initConnection(connection: Connection|FlowEdgeType) {
    const edge = connection as FlowEdgeType // note - we have to return the same object. see addElements
    if (edge.source === edge.target) {
        edge.type = 'selfConnecting'
    }else if(edge.type){
        delete edge.type
    }
    // console.log(connection.sourceHandle, connection.targetHandle)

    // legacy files
    edge.sourceHandle = edge.sourceHandle?.replace('writeBufferOut', 'bufferOut')
    edge.targetHandle = edge.targetHandle?.replace('writeBufferIn', 'bufferIn')

    // region todo remove later
    if(edge.sourceHandle === 'shaderOut_function') edge.sourceHandle = 'shaderOut_shader'

    if(edge.sourceHandle==='bufferOut' || edge.sourceHandle === 'bufferOut_renderBuffer'){
        edge.sourceHandle = 'bufferOut_writeBuffer' // todo remove in a few versions
    }
    // endregion

    const srcCls = (edge.sourceHandle || '').split('_')[0];
    const trgCls = (edge.targetHandle || '').split('_')[0];
    edge.className = (srcCls?'edge-'+srcCls:'') + ' ' + (trgCls?'edge-'+trgCls:'')

    edge.animated = !!edge.targetHandle && edge.targetHandle.startsWith('bufferIn')

    return edge
}

// todo - only called when deserialized
export function initNode(node: FlowNodeType) {
    // console.log({...node})
    return node
}
