import {generateUUID, ITexture, Vector2,} from 'threepipe'
import {TextureNodeData} from './data/TextureNodeData'
import {FlowNodeType} from '../utils/rendering'

export interface TextureNode extends FlowNodeType<TextureNodeData> {
    position: Vector2
    type: 'texture'
}

// todo merge all the node types and functions with react flow 12 upgrade
export function createTextureNode(texture: ITexture, name?: string) {
    const node: TextureNode = {
        id: generateUUID(),
        data: new TextureNodeData(texture, true),
        position: new Vector2(0, 0),
        type: 'texture'
    }
    if (name) node.data.name = name
    return node
}
