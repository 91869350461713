import {NodeProps, useReactFlow} from 'reactflow'
import {RenderTargetNodeData} from '../data/RenderTargetNodeData'
import {useThreeViewer} from '../../components/ThreeViewerComponent'
import React from 'react'
import {useFlowContext} from '../../contexts/FlowContext'
import {Checkbox, ControlGroup, InputGroup, Label} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {
    colorSpace,
    colorSpaceToName,
    magFilter,
    magFilterToName,
    minFilter,
    minFilterToName,
    textureDataTypeToName,
    wrapMode,
    wrapModeToName
} from '../../utils/three'
import {RenderTargetSizeControl} from '../../components/RenderTargetSizeControl'
import {LabelledDropdown} from '../../components/LabelledDropdown'
import {HandleInSlot} from '../../utils/HandleInSlot'
import {getSlot} from '../data/NodeData'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {BaseNodeCard} from '../../components/BaseNodeCard'

function RenderTargetNodeFC({data, selected, isConnectable, dragging}: NodeProps<RenderTargetNodeData>) {

    const viewer = useThreeViewer()
    const [isScreenTarget, setIsScreenTarget] = React.useState(data.value.sizeMultiplier !== undefined)

    const [preview, setPreview] = React.useState(data.preview)
    const [minimize, setMinimize] = React.useState(data.minimize)
    React.useEffect(() => {
        data.preview = preview
        data.minimize = minimize
    }, [preview, minimize, data])

    const flow = useFlowContext()
    const flowInstance = useReactFlow()

    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent
                title={minimize ? data.value.texture.name : `${textureDataTypeToName[data.value.texture.type]} Render Target`}
                buttons={[
                    !preview ? null : nodeHeaderButtons.focusPreview(preview, setPreview, data, flow, flowInstance),
                    nodeHeaderButtons.preview(preview, setPreview),
                    nodeHeaderButtons.download(() => {
                        const blob = flow.viewer?.renderManager.exportRenderTarget(data.value)
                        return blob ? new File([blob], `${data.value.texture.name}.${blob.ext}`) : undefined
                    }),
                    nodeHeaderButtons.minimize(minimize, setMinimize),
                ]}/>
            {!minimize && (<>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    Name
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        defaultValue={data.value.texture.name}
                        onChange={(evt) => data.value.texture.name = evt.target.value}
                    />
                </Label>
                <ControlGroup className={'bp5-small'}>
                    Read Buffer
                </ControlGroup>
                <RenderTargetSizeControl
                    screenTarget={isScreenTarget} value={data.value}
                    sizeMultiplierChange={(value) => {
                        data.value.sizeMultiplier = value
                        viewer?.renderManager.resizeTrackedTargets()
                        viewer?.setDirty()
                    }} widthChange={(value) => {
                    data.value.setSize(value, data.value.height)
                    viewer?.setDirty()
                }} heightChange={(value) => {
                    data.value.setSize(data.value.width, value)
                    viewer?.setDirty()
                }}/>

                {/* Show TextureDataType */}

                <Checkbox defaultChecked={isScreenTarget} label="Screen Size" onChange={() => {
                    if (data.value.sizeMultiplier !== undefined) {
                        data.value.sizeMultiplier = undefined
                    } else {
                        data.value.sizeMultiplier = 1
                    }
                    viewer?.renderManager.resizeTrackedTarget(data.value)
                    viewer?.setDirty()
                    setIsScreenTarget(!isScreenTarget)
                }} className={"bp5-small"}/>
                <Checkbox defaultChecked={data.clear} label="Auto Clear" onChange={() => data.clear = !data.clear}
                          className={"bp5-small"}/>
                <Checkbox defaultChecked={data.value.depthBuffer} label="Has Depth Buffer" onChange={() => {
                    data.value.depthBuffer = !data.value.depthBuffer
                    data.value.dispose()
                }} className={"bp5-small"}/>
                {data.value.texture.colorSpace !== undefined && (
                    <LabelledDropdown
                        className={"bp5-small"}
                        small={true}
                        label={"Color Space"}
                        options={Object.keys(colorSpace)}
                        defaultValue={colorSpaceToName[data.value.texture.colorSpace]}
                        onChange={(evt) => {
                            if (!data.value) return
                            data.value.texture.colorSpace = colorSpace[evt.target.value]
                            data.value.texture.needsUpdate = true
                            viewer?.setDirty()
                        }}/>
                )}
                {/*TODO this needs to be in edge properties */}
                {data.value.texture.minFilter !== undefined && (
                    <LabelledDropdown
                        className={"bp5-small"}
                        small={true}
                        label={"Min-Filter"}
                        options={Object.keys(minFilter)}
                        defaultValue={minFilterToName[data.value.texture.minFilter]}
                        onChange={(evt) => {
                            if (!data.value) return
                            data.value.texture.minFilter = minFilter[evt.target.value]
                            data.value.texture.needsUpdate = true
                            viewer?.setDirty()
                        }}/>
                )}
                {data.value.texture.magFilter !== undefined && (
                    <LabelledDropdown
                        className={"bp5-small"}
                        small={true}
                        label={"Mag-Filter"}
                        options={Object.keys(magFilter)}
                        defaultValue={magFilterToName[data.value.texture.magFilter]}
                        onChange={(evt) => {
                            if (!data.value) return
                            data.value.texture.magFilter = magFilter[evt.target.value]
                            data.value.texture.needsUpdate = true
                            viewer?.setDirty()
                        }}/>
                )}
                {data.value.texture.wrapS !== undefined && (
                    <LabelledDropdown
                        className={"bp5-small"}
                        small={true}
                        label={"Wrap S"}
                        options={Object.keys(wrapMode)}
                        defaultValue={wrapModeToName[data.value.texture.wrapS]}
                        onChange={(evt) => {
                            if (!data.value) return
                            data.value.texture.wrapS = wrapMode[evt.target.value]
                            data.value.texture.needsUpdate = true
                            viewer?.setDirty()
                        }}/>
                )}
                {data.value.texture.wrapT !== undefined && (
                    <LabelledDropdown
                        className={"bp5-small"}
                        small={true}
                        label={"Wrap T"}
                        options={Object.keys(wrapMode)}
                        defaultValue={wrapModeToName[data.value.texture.wrapT]}
                        onChange={(evt) => {
                            if (!data.value) return
                            data.value.texture.wrapT = wrapMode[evt.target.value]
                            data.value.texture.needsUpdate = true
                            viewer?.setDirty()
                        }}/>
                )}
                {/* Clear color and alpha */}
            </>)}
            <HandleInSlot main={false} type={'buffer'}
                          style={{top: minimize ? "25px" : "90px"}}
                          connectable={isConnectable}
                          slot={getSlot(data.slots, 'buffer', 'readBuffer')!}
            />
            <HandleOutSlot main={false} type={'buffer'}
                           style={{top: minimize ? "25px" : "90px"}}
                           connectable={isConnectable}
                           slot={getSlot(data.slots, 'buffer', 'readBuffer')!}
            />
            <HandleOutSlot type={'buffer'} main={true}
                           slot={getSlot(data.slots, 'buffer', 'writeBuffer')}
                           connectable={isConnectable}/>

        </BaseNodeCard>
    );
}

export default RenderTargetNodeFC
