import {FlowViewportRendererPlugin1} from './flowViewportRendererPlugin1'
import {ReactFlowInstance} from 'reactflow'
import {generateUUID, serialize, ThreeViewer} from 'threepipe'
import {FlowProjectType} from '../state'
import {uploadFile} from 'ts-browser-helpers'

export class FlowProjectPlugin1<E extends string = string> extends FlowViewportRendererPlugin1<E | 'projectChange'> {
    public static readonly PluginType: string = 'FlowProjectPlugin1'
    enabled = true

    constructor(flowInstance: ReactFlowInstance) {
        super(flowInstance)
    }

    @serialize()
    project: FlowProjectType = {
        uid: generateUUID(),
        name: 'Untitled',
        description: '',
        version: 1,
    }

    loadProject = async (data: any)=> {
        await this.importState(data)
        this.dispatchEvent({type: 'projectChange'})
    }

    saveProject = () => {
        const state= this.exportState()
        // console.log('saveProject', state)
        return state
    }


    promptProjectJSON = async () => {
        const files = await uploadFile(false, false, 'application/json')
        if (!files.length) return
        const file = files[0]
        const json = await file.text()
        await this.loadProject(JSON.parse(json))
    }

    // downloadProjectJSON = async () => {
    //     const data = await this.saveProject()
    //     const json = JSON.stringify(data)
    //     const blob = new Blob([json], {type: 'application/json'})
    //     downloadBlob(blob, this.project.name + '.json')
    // }

    private _emptyState = {}
    onAdded(viewer: ThreeViewer) {
        super.onAdded(viewer);
        this._emptyState = this.saveProject()
    }

    loadEmptyProject = async () => {
        await this.loadProject(this._emptyState)
    }

}
