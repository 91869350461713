import {NodeProps, useReactFlow} from 'reactflow'
import {TextureNodeData} from '../data/TextureNodeData'
import {useThreeViewer} from '../../components/ThreeViewerComponent'
import {useFlowContext} from '../../contexts/FlowContext'
import React from 'react'
import {InputGroup, Label} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {LabelledDropdown} from '../../components/LabelledDropdown'
import {colorSpace, colorSpaceToName, mapping, mappingToName, wrapMode, wrapModeToName} from '../../utils/three'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {getSlot} from '../data/NodeData'
import {BaseNodeCard} from '../../components/BaseNodeCard'
import {NodeHorizResizeControl} from '../../components/NodeHorizResizeControl'

function TextureNodeFC({data, selected, isConnectable, dragging}: NodeProps<TextureNodeData>) {
    const viewer = useThreeViewer()
    const flow = useFlowContext()
    const flowInstance = useReactFlow()
    const [preview, setPreview] = React.useState(data.preview)
    const [minimize, setMinimize] = React.useState(data.minimize)
    React.useEffect(() => {
        data.preview = preview
        data.minimize = minimize
    }, [preview, minimize, data])
    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent title={minimize ? data.name : `Texture`} buttons={[
                !preview ? null : nodeHeaderButtons.focusPreview(preview, setPreview, data, flow, flowInstance),
                nodeHeaderButtons.preview(preview, setPreview),
                // nodeHeaderButtons.download(() => { // todo:
                //     const blob = flow.viewer?.renderManager.exportRenderTarget(data.value)
                //     return blob ? new File([blob], `${data.value.texture.name}.${blob.ext}`) : undefined
                // })
                nodeHeaderButtons.minimize(minimize, setMinimize),
            ]}/>
            {selected && <NodeHorizResizeControl/>}
            {!minimize && (<>
                <Label>
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        defaultValue={data.value.name}
                        onChange={(evt) => data.value.name = evt.target.value}
                    />
                </Label>
                <LabelledDropdown
                    small={true}
                    label={"Mapping"}
                    options={Object.keys(mapping)}
                    defaultValue={mappingToName[data.value.mapping]}
                    onChange={(evt) => {
                        if (!data.value) return
                        data.value.mapping = mapping[evt.target.value]
                        data.value.setDirty?.()
                        viewer?.setDirty()
                    }}/>
                <LabelledDropdown
                    className={"bp5-small"}
                    label={"Color Space"}
                    options={Object.keys(colorSpace)}
                    defaultValue={colorSpaceToName[data.value.colorSpace]}
                    onChange={(evt) => {
                        if (!data.value) return
                        data.value.colorSpace = colorSpace[evt.target.value]
                        data.value.setDirty?.()
                        viewer?.setDirty()
                    }}/>
                {data.value.wrapS !== undefined && (
                    <LabelledDropdown
                        className={"bp5-small"}
                        small={true}
                        label={"Wrap S"}
                        options={Object.keys(wrapMode)}
                        defaultValue={wrapModeToName[data.value.wrapS]}
                        onChange={(evt) => {
                            if (!data.value) return
                            data.value.wrapS = wrapMode[evt.target.value]
                            data.value.needsUpdate = true
                            viewer?.setDirty()
                        }}/>
                )}
                {data.value.wrapT !== undefined && (
                    <LabelledDropdown
                        className={"bp5-small"}
                        small={true}
                        label={"Wrap T"}
                        options={Object.keys(wrapMode)}
                        defaultValue={wrapModeToName[data.value.wrapT]}
                        onChange={(evt) => {
                            if (!data.value) return
                            data.value.wrapT = wrapMode[evt.target.value]
                            data.value.needsUpdate = true
                            viewer?.setDirty()
                        }}/>
                )}
            </>)}
            <HandleOutSlot type={'texture'} main={true}
                           slot={getSlot(data.slots, 'texture', 'texture')}
                           connectable={isConnectable}/>

        </BaseNodeCard>
    );
}

export default TextureNodeFC
