import React from 'react'
import {Button, InputGroup, Label} from '@blueprintjs/core'
import {generateUUID, MaterialExtension, Shader, ThreeViewer, toTitleCase, UnsignedByteType} from 'threepipe'
import {autoPlaceNodes, newRenderTargetAdd} from '../addNodes'
import {TFlowContext} from '../../contexts/FlowContext'
import {ImportPopupFileButton} from '../../components/importPopupFileButton'
import {createShaderSnippetNode} from '../../nodes/ShaderSnippetNode'
import {extractIncludes, getFileSync} from '../shaderBasicParse'
import {createShaderPassNode} from '../../nodes/ShaderPassNode'
import {CopyShaderPass} from '../../passes/CopyShaderPass'
import {createRichTextNode} from '../../nodes/RichTextNode'

// todo use our colors maybe
export function LygiaLogo() {
    return <svg width="16" height="16" viewBox="0 0 399 399" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="195.5" width="276.479" height="276.479" transform="rotate(-45 4 195.5)" fill="#313236"/>
        <rect x="4.70711" y="195.5" width="137.179" height="275.479" transform="rotate(-45 4.70711 195.5)"
              fill="#313236" stroke="#212226"/>
        <rect x="198" y="195.5" width="139.3" height="139.3" transform="rotate(-45 198 195.5)" fill="#DCDCDA"/>
    </svg>
}
const lygiaExamplesBase = 'https://github.com/patriciogonzalezvivo/lygia_examples/blob/main/'

async function loadLygia(id: string, viewer: ThreeViewer) {
    if(id.startsWith(lygiaExamplesBase) || id.endsWith('.frag')){
        return loadLygiaExample(id, viewer)
    }
    const domain = 'https://lygia.xyz/'
    if (!id.toLowerCase().includes('lygia.xyz')) {
        id = domain+id
            .replace(/^\//g, '')
            .replace(/\/$/g, '')
            .replace(/\.json|\.glsl|\/$/g, '')
            .replace(/[^a-zA-Z0-9\/]/g, '')
    }
    if(!id.startsWith(domain)) throw new Error('Invalid URL, must be lygia.xyz')
    const res = await (await fetch(id + '.json?glsl')).json().catch((e: any)=>{console.error(e); return null;})
    if (!res) throw new Error('Unable to import, check console for more details')
    if (res.error || res.Error) throw new Error(res.error || res.Error)

    const shader = res.code.trim()
    if(!shader || res.language !== 'GLSL') throw new Error('Shader GLSL Code Not Found')
    const node = createShaderSnippetNode(shader, res.name + '.glsl')
    node.data.source = id.replace(domain, 'lygia:')
    const metadata = {...res}
    delete metadata.code
    delete metadata.types
    // noinspection JSConstantReassignment
    delete metadata.versions
    node.data.metadata = {'lygia': metadata}
    autoPlaceNodes(viewer, [node])
    return ''
}

async function loadLygiaExample(id: string, viewer: ThreeViewer) {
    if(id.startsWith('http') && !id.startsWith(lygiaExamplesBase)) throw new Error('Not supported - Invalid URL')
    if(!id.endsWith('.frag')) throw new Error('Not supported - Invalid URL')
    id = id.replace(lygiaExamplesBase, '')
    const url = 'https://cdn.jsdelivr.net/gh/patriciogonzalezvivo/lygia_examples@main/' + id
    let shader = await (await fetch(url)).text().catch((e: any)=>{console.error(e); return null;})
    if (!shader) throw new Error('Unable to import, check console for more details')

    shader = shader.trim()
    shader = shader
        .replace(/v_texcoord/g, 'vUv')
        .replace(/u_tex0Resolution/g, 'tDiffuseSize')
        .replace(/u_tex0/g, 'tDiffuse')
        .replace(/u_tex0Resolution/g, 'tDiffuseSize')
        .replace(/u_mouse/g, 'iMouse')
        .replace(/u_time/g, 'iTime')
        .replace(/u_resolution/g, 'iResolution')
        .replace(/#version \d+\s*/, '')
        .replace(/^[ \t]*uniform[ \t]+sampler2D[ \t]+tDiffuse[ \t]*;/gm, '//uniform sampler2D tDiffuse;')
    // console.log(shader)


    if (!shader) throw new Error('Unable to import, check console for more details')
    const node = createShaderPassNode(new CopyShaderPass({
        fragmentShader: shader,
    }), id)
    node.data.source = 'lygia_examples:' + id
    const renderTarget = newRenderTargetAdd(viewer, UnsignedByteType)!
    const edge = {
        id: generateUUID(),
        source: renderTarget.id,
        target: node.id,
        animated: false,
        sourceHandle: 'bufferOut_writeBuffer',
        targetHandle: 'bufferIn',
    }
    const name = toTitleCase(id.replaceAll('_', ' ').replace(/\.\w+$/, ''))
    const infoText = createRichTextNode(`
## ${name || 'Shader Toy'}
> from Lygia Examples
[${id}](${lygiaExamplesBase + id})
\n
`, 'Lygia Example Import')
    autoPlaceNodes(viewer, [infoText, renderTarget, node], [edge])
    return name
}

export const importLygiaShader = (context: TFlowContext) => {
    const {dialog, setDialog} = context
    if (!dialog || !setDialog) return
    const state = {value: 'https://lygia.xyz/math/atan2'}
    setDialog({
        ...dialog,
        canClose: true,
        isOpen: true,
        state,
        title: 'Import Lygia Shader Node',
        content: (
            <Label>
                Lygia ID/URL:
                <InputGroup
                    placeholder="lygia.xyz/math/atan2"
                    defaultValue={state.value}
                    onChange={(e: any) => state.value = e.target.value}
                />
            </Label>
        ),
        actions: (
            <>
                <Button text={'Close'} onClick={() => setDialog({...dialog, isOpen: false})}/>
                <ImportPopupFileButton state={state} importer={loadLygia}/>
            </>
        ),
    })
    // autoPlaceNodes([node])
}

// todo this makes it very slow when loading files, so include all lygia shaders in the bundle...
const lygiaShaderChunk = new Map()
const replacer = (name: string, line: string, shader: Shader)=>{
    shader.fragmentShader = shader.fragmentShader.replace(line, lygiaShaderChunk.get(name))
    shader.vertexShader = shader.vertexShader.replace(line, lygiaShaderChunk.get(name))
}

export function cacheLygiaInclude(name: string) {
    if(lygiaShaderChunk.has(name)) return lygiaShaderChunk.get(name)
    // todo make a proxy with proper caching...
    const url = 'https://lygia.xyz/' + name
        .replace(/^lygia\//, '')
        .replace(/\"|\;|\s/g, '')
        .replace(/\.glsl$/, '') + '.glsl'
    const content = getFileSync(url)
    if (!content || content.startsWith('Path')) {
        console.error('lygiaMaterialExtension: Invalid include', name, url, content)
        lygiaShaderChunk.set(name, '// Invalid include: ' + name)
    } else {
        lygiaShaderChunk.set(name, content)
    }
    return content;
}

// todo should we make one for random plugins as well?
export const lygiaMaterialExtension: MaterialExtension = {
    shaderExtender: (shader, material, renderer) => {
        const shaders = shader.fragmentShader + '\n' + shader.vertexShader
        const includes = extractIncludes(shaders)
        for (let [name, line] of includes) {
            if (!name.startsWith('lygia/')) continue
            cacheLygiaInclude(name)
            replacer(name, line, shader)
        }
    },
    priority: -1000, // last
    isCompatible: ()=>true,
}
