import {NodeProps} from 'reactflow'
import {CameraNodeData} from '../data/CameraNodeData'
import {useThreeViewer} from '../../components/ThreeViewerComponent'
import React from 'react'
import {ControlGroup, FormGroup, InputGroup, Label, NumericInput} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {getSlot} from '../data/NodeData'
import {Vec3InputGroup} from '../../components/Vec3InputGroup'
import {NearFarInputGroup} from '../../components/NearFarInputGroup'
import {BaseNodeCard} from '../../components/BaseNodeCard'

function CameraNodeFC({data, selected, isConnectable, dragging}: NodeProps<CameraNodeData>) {
    const viewer = useThreeViewer()
    // const flow = useFlowContext()
    // const flowInstance = useReactFlow()
    const [minimize, setMinimize] = React.useState(data.minimize)
    React.useEffect(() => {
        data.minimize = minimize
    }, [minimize, data])
    // todo position sync
    // const [position, setPosition] = React.useState(data.position.clone())
    // React.useEffect(() => {
    //     const update = () => {
    //         console.log('camera')
    //         setPosition(data.position.clone())
    //     }
    //     data.value.addEventListener('cameraUpdate', update)
    //     return () => {
    //         data.value.removeEventListener('cameraUpdate', update)
    //     }
    // }, [data, setPosition])
    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent title={minimize ? data.name : 'Camera'} buttons={[
                // nodeHeaderButtons.download(() => { // todo:
                //     const blob = flow.viewer?.renderManager.exportRenderTarget(data.value)
                //     return blob ? new File([blob], `${data.value.texture.name}.${blob.ext}`) : undefined
                // })
                nodeHeaderButtons.minimize(minimize, setMinimize),
            ]}/>
            {!minimize && (<>
                <Label>
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        defaultValue={data.name}
                        onChange={(evt) => data.name = evt.target.value}
                    />
                </Label>
                {data.perspective && <>
                    <FormGroup inline={true}
                               label={"Field Of View"}
                               labelFor="fov-input" className="num-input-form-group bp5-small">
                        <ControlGroup id="fov-input">
                            <NumericInput placeholder="FoV"
                                          small={true}
                                          className="numberInput"
                                          leftIcon={"camera"}
                                          stepSize={1}
                                          minorStepSize={1 / 10}
                                          majorStepSize={10.}
                                          defaultValue={data.fov}
                                          onValueChange={(value) => {
                                              data.fov = value
                                              viewer?.setDirty()
                                          }}

                            />
                        </ControlGroup>
                    </FormGroup>
                </>}
                {!data.perspective && <>
                    <FormGroup inline={true}
                               label={"Zoom"}
                               labelFor="zoom-input" className="num-input-form-group bp5-small">
                        <ControlGroup id="zoom-input">
                            <NumericInput placeholder="Zoom"
                                          small={true}
                                          className="numberInput"
                                          leftIcon={"zoom-in"}
                                          stepSize={1}
                                          minorStepSize={1 / 10}
                                          majorStepSize={10.}
                                          defaultValue={data.zoom}
                                          onValueChange={(value) => {
                                              data.zoom = value
                                              viewer?.setDirty()
                                          }}

                            />
                        </ControlGroup>
                    </FormGroup>
                </>}
                <NearFarInputGroup
                    near={data.near} far={data.far} onChangeNear={(near) => {
                    data.near = near
                    viewer?.setDirty()
                }} onChangeFar={(far) => {
                    data.far = far
                    viewer?.setDirty()
                }}/>
                <Vec3InputGroup
                    label='Position'
                    name={'position'}
                    value={data.position} onChange={(value) => {
                    // data.position.copy(value)
                    data.targetChanged()
                    viewer?.setDirty()
                }}/>
                <Vec3InputGroup
                    label='Target'
                    name={'target'}
                    value={data.target} onChange={(value) => {
                    // data.position.copy(value)
                    data.targetChanged()
                    viewer?.setDirty()
                }}/>

            </>)}
            <HandleOutSlot type={'camera'} main={true}
                           slot={getSlot(data.slots, 'camera', 'camera')}
                           connectable={isConnectable}/>
        </BaseNodeCard>
    );
}

export default CameraNodeFC
