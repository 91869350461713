import {Button, Classes, Drawer} from '@blueprintjs/core'
import React, {useCallback, useRef} from 'react'
import CodeMirror from '@uiw/react-codemirror'
import {cpp} from '@codemirror/lang-cpp'
import {EditorView, ViewUpdate} from '@codemirror/view'
import {getSlotVal, NodeConnectionSlot, setInternalVal} from '../nodes/data/NodeData'
import {indentUnit} from "@codemirror/language"

export type CodeItemType = NodeConnectionSlot<'shader'> | null
export type TShaderCodeEditorContext = {
    codeItem: CodeItemType,
    setCodeItem: (item: CodeItemType) => void,
    shaderError: string,
    setShaderError: (error: string) => void,
}
export const ShaderCodeEditorContext = React.createContext<TShaderCodeEditorContext>({
    codeItem: null,
    setCodeItem: () => {},
    shaderError: '',
    setShaderError: () => {},
})

export const useShaderCodeEditor = () => React.useContext(ShaderCodeEditorContext)

export function ShaderCodeEditor({children}: {children: React.ReactNode}) {
    const [showCodeInput, setShowCodeInput] = React.useState(false)
    const [codeString, setCodeString] = React.useState('')
    const [codeItem, setCodeItem] = React.useState<CodeItemType>(null)
    const editorRef = useRef<EditorView | null>(null)

    const onCodeMirrorChange = useCallback((value: string, viewUpdate: ViewUpdate) => {
        setCodeString(value)
    }, [setCodeString]);

    const [shaderError, setShaderError] = React.useState<string>('')

    const onCodeMirrorSubmit = useCallback((value: string) => {
        setCodeString(value)
        setShaderError('')
        codeItem && setInternalVal(codeItem, value) // todo use default value in slot in case of empty string
    }, [codeItem, setCodeString, setShaderError])

    const setCodeItem1 = useCallback((item: CodeItemType) => {
        setCodeItem(item)
        setCodeString(item? (getSlotVal(item) || '') : '')
        setShowCodeInput(true)
        editorRef.current?.dispatch({effects: EditorView.scrollIntoView(0)})
    }, [setCodeItem, setCodeString, setShowCodeInput, editorRef])

    return <ShaderCodeEditorContext.Provider value={{
        codeItem, setCodeItem: setCodeItem1,
        shaderError, setShaderError,
    }}>
        {children}
        <Drawer
            isOpen={showCodeInput}
            autoFocus={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            enforceFocus={false}
            lazy={true}
            onClose={() => setShowCodeInput(false)}
            hasBackdrop={false}
            shouldReturnFocusOnClose={false} // note dont set to true, messes up react flow (maybe, cannot test easily)
            className="code-drawer"
            isCloseButtonShown={true}
            title="Edit Shader"
            onClosed={() => onCodeMirrorSubmit(codeString)} // todo also do this when some other file is loaded.
            // position={Position.RIGHT}
            // size={undefined}
            // portalClassName="code-drawer-portal"
            usePortal={false}
            transitionName={'none'}
            transitionDuration={0}
            // portalContainer={flow.flowWrapper}
        >
            <CodeMirror
                value={codeString}
                className={Classes.DRAWER_BODY}
                extensions={[cpp(), indentUnit.of("    ")]} // todo make global variable config
                theme="dark"
                onChange={onCodeMirrorChange}
                onCreateEditor={(editor, state) => {
                    // console.log('editor:', editor, state);
                    editorRef.current = editor
                    editor.contentDOM.dataset.gramm = 'false'
                    editor.contentDOM.dataset.gramm_editor = 'false'
                    editor.contentDOM.dataset.enable_grammarly = 'false'
                }}
            />
            <div className={Classes.DRAWER_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button intent='none' onClick={() => setShowCodeInput(false)}>Close</Button>
                        <Button intent='primary' onClick={() => onCodeMirrorSubmit(codeString)}>Run</Button>
                </div>
            </div>
        </Drawer>
    </ShaderCodeEditorContext.Provider>
}
