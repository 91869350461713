import {ConnectionDataType, getSlotVal, NodeConnectionSlot} from '../nodes/data/NodeData'
import {Handle, Position} from 'reactflow'
import React from 'react'

export function HandleOutSlot<T extends ConnectionDataType>(props: {
    type: T,
    main: boolean,
    style?: React.CSSProperties,
    slot?: NodeConnectionSlot<T>,
    className?: string,
    connectable: boolean
}) {
    return <Handle
        type="source"
        position={Position.Right}
        style={{...props.style, opacity: (props.main || (getSlotVal(props.slot) !== undefined && getSlotVal(props.slot) !== '')) ? (props.style?.opacity ?? (props.main?'1':'0.75')) : '0.2'}} // todo instead of getValue, make and use hasSetValue, which is undefined or defaultValue
        id={`${props.type}Out` + (props.slot ? `_${props.slot.name}` : '')}
        className={(props.className||'') + ` ${props.type}-handle` + (props.main?' main-out-handle':'') + (props.slot ? ` handle-${props.slot.name}` : '')}
        isConnectable={props.connectable}
    />
}
