import {serialize, Vector4} from 'threepipe'
import {onChange} from 'ts-browser-helpers'
import {Rect} from 'reactflow'
import {MinimizeNodeData} from './MinimizeNodeData'

export abstract class PreviewNodeData<T extends string = string> extends MinimizeNodeData<'previewRender'|'previewChanged'|T>{
    @onChange('onPreviewChange')
    @serialize() preview: boolean

    constructor(preview?: boolean) {
        super()
        this.preview = preview ?? true
    }

    lastViewport: Vector4|null = null
    lastRect: Rect|null = null
    onPreviewRender(viewport: Vector4, rect: Rect) {
        if(!this.preview) console.error('PreviewNodeData.onPreviewRender called when preview is false')
        if(!this.lastViewport) this.lastViewport = new Vector4()
        this.lastViewport.copy(viewport)
        this.lastRect = {...rect}
        this.dispatchEvent({type: 'previewRender', viewport: this.lastViewport, rect: this.lastRect})
    }

    onPreviewChange() {
        if(!this.preview) {
            this.lastViewport = null
            this.lastRect = null
        }
        this.dispatchEvent({type: 'previewChanged', preview: this.preview})
    }

}
