import {InitialConfigType, LexicalComposer} from '@lexical/react/LexicalComposer';
import {$convertFromMarkdownString, TRANSFORMERS} from '@lexical/markdown';
import {MarkdownShortcutPlugin} from '@lexical/react/LexicalMarkdownShortcutPlugin';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {HeadingNode, QuoteNode} from "@lexical/rich-text";
import {TableCellNode, TableNode, TableRowNode} from "@lexical/table";
import {ListItemNode, ListNode} from "@lexical/list";
import {CodeHighlightNode, CodeNode} from "@lexical/code";
import {AutoLinkNode, LinkNode} from "@lexical/link";
import {ListPlugin} from '@lexical/react/LexicalListPlugin'
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin'
import {TabIndentationPlugin} from '@lexical/react/LexicalTabIndentationPlugin'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {convertToMarkdownString, PLAYGROUND_TRANSFORMERS} from './MarkdownTransformers/playground'
import {ClickableLinkPlugin} from '@lexical/react/LexicalClickableLinkPlugin';
import {RichTextBoxPlugin} from './plugins/RichTextBoxPlugin'
import {EditableAutoFocusPlugin} from './EditableAutoFocusPlugin'
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin'
import {validateUrl} from './utils/url'
import {HorizontalRuleNode} from '@lexical/react/LexicalHorizontalRuleNode'
// import AutoLinkPlugin from './plugins/AutoLinkPlugin';
// import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
// import LinkPlugin from './plugins/LinkPlugin';

// import './lexical.scss'

// todo add more plugins from - https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/Editor.tsx

const initialConfig: InitialConfigType = {
    namespace: 'TestEditor',
    theme: {
        code: 'editor-code',
        heading: {
            h1: 'editor-heading-h1',
            h2: 'editor-heading-h2',
            h3: 'editor-heading-h3',
            h4: 'editor-heading-h4',
            h5: 'editor-heading-h5',
        },
        image: 'editor-image',
        link: 'editor-link',
        list: {
            listitem: 'editor-listitem',
            nested: {
                listitem: 'editor-nested-listitem',
            },
            ol: 'editor-list-ol',
            ul: 'editor-list-ul',
        },
        ltr: 'ltr',
        paragraph: 'editor-paragraph',
        placeholder: 'editor-placeholder',
        quote: 'editor-quote',
        rtl: 'rtl',
        text: {
            bold: 'editor-text-bold',
            code: 'editor-text-code',
            hashtag: 'editor-text-hashtag',
            italic: 'editor-text-italic',
            overflowed: 'editor-text-overflowed',
            strikethrough: 'editor-text-strikethrough',
            underline: 'editor-text-underline',
            underlineStrikethrough: 'editor-text-underlineStrikethrough',
        },
    },
    // Catch any errors that occur during Lexical updates and log them
    // or throw them as needed. If you don't throw them, Lexical will
    // try to recover gracefully without losing user data.
    onError: (error, editor) => {
        console.error(error);
    },
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode, // requires CodeHighlightPlugin
        TableNode, // todo.
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
        HorizontalRuleNode,
    ]
};

const usePlaygroundMarkdownTransformers = true
const _TRANSFORMERS = usePlaygroundMarkdownTransformers ? PLAYGROUND_TRANSFORMERS : TRANSFORMERS

// plugins - https://lexical.dev/docs/react/plugins
// full playground - https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/Editor.tsx
// CodeActionMenuPlugin for copy and pretty buttons - https://github.com/facebook/lexical/tree/main/packages/lexical-playground/src/plugins/CodeActionMenuPlugin
export function BasicMarkdownTextEditor(props: {value: string, setValue: (v: string)=>void, fontSize?: number, editable: boolean}) {
    // const editorStateRef = useRef<EditorState|undefined>(undefined);
    return (
        <LexicalComposer
            initialConfig={{...initialConfig,
                editorState: () => $convertFromMarkdownString(props.value, _TRANSFORMERS),
                editable: props.editable
            }}
        >
            {/*<ToolbarPlugin />*/}
            <RichTextBoxPlugin fontSize={props.fontSize || 1.5} editable={props.editable} />
            <OnChangePlugin onChange={(editorState) => {
                // editorStateRef.current = editorState;
                editorState.read(()=>{
                    props.setValue(convertToMarkdownString(_TRANSFORMERS));
                })
            }} />
            <ListPlugin />
            <LinkPlugin validateUrl={validateUrl} />
            <TabIndentationPlugin />
            <MarkdownShortcutPlugin transformers={_TRANSFORMERS} />
            <CodeHighlightPlugin />
            <HistoryPlugin />
            <AutoLinkPlugin />
            <EditableAutoFocusPlugin editable={props.editable} />
            <ClickableLinkPlugin disabled={props.editable} />

            {/*{floatingAnchorElem && !isSmallWidthViewport && (*/}
            {/*    <>*/}
            {/*        <DraggableBlockPlugin anchorElem={floatingAnchorElem} />*/}
            {/*        <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />*/}
            {/*        <FloatingLinkEditorPlugin*/}
            {/*            anchorElem={floatingAnchorElem}*/}
            {/*            isLinkEditMode={isLinkEditMode}*/}
            {/*            setIsLinkEditMode={setIsLinkEditMode}*/}
            {/*        />*/}
            {/*        <TableCellActionMenuPlugin*/}
            {/*            anchorElem={floatingAnchorElem}*/}
            {/*            cellMerge={true}*/}
            {/*        />*/}
            {/*        <FloatingTextFormatToolbarPlugin*/}
            {/*            anchorElem={floatingAnchorElem}*/}
            {/*            setIsLinkEditMode={setIsLinkEditMode}*/}
            {/*        />*/}
            {/*    </>*/}
            {/*)}*/}

        </LexicalComposer>
    );
}
