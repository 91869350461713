import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {FocusStyleManager} from '@blueprintjs/core'
import "mathlive";
import "@cortex-js/compute-engine"
import {setupMathfield} from './utils/cortex/setup'
import {consoleWelcomePrompt} from './utils/consoleWelcomePrompt'

consoleWelcomePrompt()

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

// todo use VisualStyleContext from uiconfig-blueprint
if(localStorage.getItem('bpUiDarkMode') === 'true' || localStorage.getItem('bpUiDarkMode') === null)
    document.body.classList.add('bp5-dark');

FocusStyleManager.onlyShowFocusOnTabs();

setupMathfield()

// Popover.defaultProps.transitionDuration = 200;
// Popover.defaultProps.hoverOpenDelay = 0;
// Popover.defaultProps.hoverCloseDelay = 50;

root.render(
  // <React.StrictMode>
    <App />
 // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
