import React from 'react'
import {Card} from '@blueprintjs/core'
import {ThreeViewer} from 'threepipe'
import {FlowProjectPlugin1} from '../utils/plugins/flowProjectPlugin1'
import {useNavBarContext} from '../contexts/NavbarContext'

export function ColorPickerCard(props: {
    mousePosition: { x: number; y: number },
    cardRef: React.MutableRefObject<HTMLDivElement | null>
}) {
    const {colorPicker} = useNavBarContext()

    return !colorPicker ? null : <Card className="mouse-tooltip" style={{
        transform: `translate(${props.mousePosition.x}px, ${props.mousePosition.y}px)`,
    }}>
        <div ref={props.cardRef} style={{
            display: 'inline-flex', alignItems: 'center',
            fontFamily: 'ui-monospace, monospace', fontSize: '1.1rem'
        }}></div>
    </Card>
}

export function pickCanvasColor(v: ThreeViewer, plugin: FlowProjectPlugin1) {
    const buffer = new Uint8Array(4)
    v.renderManager.webglRenderer.readRenderTargetPixels(plugin.screenTarget, plugin.mouseState.clientX, plugin.lastCanvasRect.height + plugin.lastCanvasRect.y - plugin.mouseState.clientY, 1, 1, buffer)
    let colorStr = ''
    if (plugin.userData.colorPickerMode === 'RGBA')
        colorStr = '[' + (buffer[0] / 255).toFixed(2) + ',' + (buffer[1] / 255).toFixed(2) + ',' + (buffer[2] / 255).toFixed(2) + ',' + (buffer[3] / 255).toFixed(2) + ']';
    if (plugin.userData.colorPickerMode === 'rgba')
        colorStr = 'rgba(' + buffer[0] + ',' + buffer[1] + ',' + buffer[2] + ',' + (buffer[3] / 255).toFixed(2) + ')';
    else if (plugin.userData.colorPickerMode === 'hex')
        colorStr = '#' + buffer[0].toString(16).padStart(2, '0') + buffer[1].toString(16).padStart(2, '0') + buffer[2].toString(16).padStart(2, '0') + buffer[3].toString(16).padStart(2, '0');
    return colorStr
}


export function cycleColorPickerMode(plugin: FlowProjectPlugin1) {
    if (plugin.userData.colorPickerMode === 'RGBA') {
        plugin.userData.colorPickerMode = 'rgba'
    } else if (plugin.userData.colorPickerMode === 'rgba') {
        plugin.userData.colorPickerMode = 'hex'
    } else if (plugin.userData.colorPickerMode === 'hex') {
        plugin.userData.colorPickerMode = 'RGBA'
    }
}
