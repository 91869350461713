import {Card, Elevation} from '@blueprintjs/core'
import {useGlobalSettings} from '../contexts/GlobalSettings'
import React from 'react'

export function BaseNodeCard(props: {
    minimize: boolean,
    selected: boolean,
    children: React.ReactNode,
    elevation?: boolean, // default true
    transparent?: boolean,
    dragging?: boolean,
    alwaysShow?: boolean, // sets display flex in style so it's not hidden by css
}) {
    const {ui} = useGlobalSettings()
    const noElevation = props.elevation === false || props.elevation === null || ui.node_shadows === 0
    // noinspection PointlessBooleanExpressionJS
    const noOutlines = ui.node_outlines===false && !props.selected
    const style = {
        boxShadow: noElevation ? "none" : undefined,
        background: props.transparent ? "transparent" : undefined,
        outline: noOutlines ? "none" : undefined,
        borderRadius: ui.node_corner_radius!==undefined ? ui.node_corner_radius + "px" : undefined,
        display: props.alwaysShow ? "flex" : undefined,
    }
    return <Card
        interactive={false} // todo
        elevation={noElevation ? 0 : (props.dragging ? Elevation.TWO : (ui.node_shadows ?? Elevation.THREE))}
        style={style}
        children={props.children}
        compact={props.minimize}
        className={"card-node" + (props.minimize ? " card-node-minimized" : "") + (props.selected ? " card-node-selected" : "")}/>
}
