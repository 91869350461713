import {IRenderTarget, WebGLRenderTarget} from 'threepipe'
import {ControlGroup, FormGroup, NumericInput} from '@blueprintjs/core'
import React from 'react'

export function RenderTargetSizeControl(props: {
    screenTarget: boolean,
    value: IRenderTarget & WebGLRenderTarget,
    sizeMultiplierChange: (value: number) => void,
    widthChange: (value: number) => void,
    heightChange: (value: number) => void
}) {
    return <FormGroup inline={true}
                      label={props.screenTarget ? 'Size Multiplier' : 'Size'}
                      labelFor="target-size" className="num-input-form-group bp5-small">

        <ControlGroup id="target-size">
            {props.screenTarget ? (
                <>
                    <NumericInput placeholder="Size"
                                  small={true}
                                  className="numberInput"
                                  leftIcon={'asterisk'}
                                  key={"sizeMultiplier"}
                                  stepSize={1. / 4}
                                  minorStepSize={1. / 16}
                                  majorStepSize={1.}
                                  defaultValue={props.value.sizeMultiplier}
                                  onValueChange={props.sizeMultiplierChange}
                    />
                </>
            ) : (
                <>
                    <NumericInput placeholder="Width"
                                  small={true}
                                  className="numberInput"
                                  leftIcon={'horizontal-distribution'}
                                  key={"width"}
                                  stepSize={100}
                                  minorStepSize={1}
                                  majorStepSize={500}
                                  defaultValue={props.value.width}
                                  onValueChange={props.widthChange}
                    />
                    <NumericInput placeholder="Height"
                                  small={true}
                                  className="numberInput"
                                  leftIcon={'vertical-distribution'}
                                  key={"height"}
                                  stepSize={100}
                                  minorStepSize={1}
                                  majorStepSize={500}
                                  defaultValue={props.value.height}
                                  onValueChange={props.heightChange}
                    />
                </>
            )}
        </ControlGroup>
    </FormGroup>
}
