import {
    IWebGLRenderer,
    serializable,
    ShaderMaterialParameters,
    WebGLMultipleRenderTargets,
    WebGLRenderTarget
} from 'threepipe'
import {NodeShaderPassBase} from './NodeShaderPassBase'
import defaultFrag from './shaders/defaultFrag.glsl'
import defaultVert from './shaders/defaultVert.glsl'

@serializable('CopyShaderPass')
export class CopyShaderPass extends NodeShaderPassBase {
    passId: string = 'copy';
    dirty = false

    constructor(shader: Partial<ShaderMaterialParameters> = {}, channels = ['tDiffuse'], editable = true) {
        super({
            uniforms: {
                tDiffuse: {value: null},
            },
            // glslVersion: GLSL3,
            vertexShader: shader.vertexShader || defaultVert,
            fragmentShader: shader.fragmentShader || defaultFrag,
            transparent: true,
            // toneMapped: false,
            depthTest: false,
            depthWrite: false,
            premultipliedAlpha: false,
            ...shader,
        }, editable, true, ...channels)
        this.material.needsUpdate = true
    }

    render(renderer: IWebGLRenderer, writeBuffer?: WebGLRenderTarget | null, readBuffer?: WebGLMultipleRenderTargets | WebGLRenderTarget, deltaTime?: number, maskActive?: boolean) {
        super.render(renderer, writeBuffer, readBuffer, deltaTime, maskActive);
        this.dirty = false
    }

}
