import {Button, ButtonGroup, EditableText, Icon, Position, Tooltip} from '@blueprintjs/core'
import {IconName} from '@blueprintjs/icons'
import {MaybeElement} from '@blueprintjs/core/src/common/props'
import {CSSProperties, JSX} from 'react'
import {PreviewNodeData} from '../nodes/data/PreviewNodeData'
import {downloadFile} from 'ts-browser-helpers'
import {FLOW_NODE_PREVIEW_PADDING, FLOW_NODE_PREVIEW_Y} from '../utils/plugins/flowViewportRendererPlugin1'
import {TFlowContext} from '../contexts/FlowContext'
import {ReactFlowInstance} from 'reactflow'
import {BaseNodeData} from '../nodes/data/BaseNodeData'
import {generateUUID, getEmptyMeta, metaFromResources, ThreeSerialization, ThreeViewer, Vector2} from 'threepipe'
import {autoPlaceNodes} from '../utils/addNodes'

export interface NodeHeaderButtonConfig{
    icon: IconName | MaybeElement
    onClick: () => void
    minimal?: boolean
    small?: boolean
    className?: string
    tooltip?: string | JSX.Element
}
export interface NodeHeaderComponentProps {
    title?: string
    placeholder?: string
    setTile?: (title: string) => void
    buttons: (NodeHeaderButtonConfig|null)[]
    button?: NodeHeaderButtonConfig // minimize button
    inline?: boolean
}

export default function NodeHeaderComponent(props: NodeHeaderComponentProps) {
    const headerStyles: CSSProperties = !props.inline ? {
        position: "absolute",
        right: "calc(var(--pt-grid-size) / 2)",
        // @ts-ignore
        "--g3": "calc(var(--pt-grid-size) * -3)",
        top: "calc(var(--g3) - 0.5rem)",
    } : {}
    return (
        <div className="node-header" style={{
            justifyContent: props.inline ? 'space-between' : 'center',
        }}>
            {/*<Tooltip content={props.title} position={Position.TOP} compact={true}>*/}
            {!props.setTile ? <div className="node-header-title">
                {props.title || props.placeholder || ''}
            </div> : <EditableText
                className="node-header-title"
                placeholder={props.placeholder || 'Node'}
                value={props.title}
                onChange={props.setTile}
            />}
            {/*</Tooltip>*/}
            <ButtonGroup style={headerStyles}>
                {props.buttons.map((button, i) => !button ? null : (
                    <Tooltip key={i} content={button.tooltip} position={Position.BOTTOM}>
                        <Button icon={button.icon}
                                onClick={button.onClick} minimal={button.minimal??true}
                                small={button.small??true} className={button.className??''} />
                    </Tooltip>
                ))}
            </ButtonGroup>
            {props.button &&
            <Tooltip className={'single-side-button'} content={props.button.tooltip} position={Position.BOTTOM}>
                <Button icon={props.button.icon}
                        onClick={props.button.onClick} minimal={props.button.minimal??true}
                        small={props.button.small??true} className={props.button.className??''} />
            </Tooltip> }
        </div>
    )
}

export const nodeHeaderButtons = {
    preview: (preview: boolean, setPreview: (preview: boolean) => void) => ({
        icon: preview ? 'eye-open' : 'eye-off',
        onClick: () => setPreview(!preview),
        tooltip: "Preview"
    } as NodeHeaderButtonConfig),
    minimize: (minimize: boolean, setMinimize: (minimize: boolean) => void) => ({
        icon: minimize ? 'chevron-down' : 'chevron-up',
        onClick: () => setMinimize(!minimize),
        tooltip: minimize ? 'Maximize' : 'Minimize'
    } as NodeHeaderButtonConfig),
    fontSize: (fontSize: number, setFontSize: (fontSize: number) => void, increase: boolean) => ({
        icon: increase ? (<Icon icon={'font'} size={12}/>) : (<Icon icon={'font'} size={8}/>),
        onClick: () => setFontSize(fontSize + (increase ? 1 : -1) * 0.1),
        tooltip: (increase ? "Increase" : "Decrease") + " font size"
    } as NodeHeaderButtonConfig),
    previewZoom: (onClick: (zoomIn: boolean) => void, zoomIn: boolean) => ({
        icon: zoomIn ? 'zoom-in' : 'zoom-out',
        onClick: () => onClick(zoomIn),
        tooltip: zoomIn ? "Zoom in" : "Zoom out"
    } as NodeHeaderButtonConfig),
    // todo put type in BaseNodeData
    duplicate: (type: string, data: BaseNodeData, viewer: ThreeViewer) => ({
        icon: 'duplicate',
        onClick: ()=>{
            const serialized = ThreeSerialization.Serialize(data, getEmptyMeta())
            const node = ThreeSerialization.Deserialize(serialized, undefined, metaFromResources(serialized.resources, viewer))
            autoPlaceNodes(viewer, [{
                    id: generateUUID(),
                    data: node,
                    position: new Vector2(0,0),
                    type: type
                }])
        },
        tooltip: "Duplicate"
    } as NodeHeaderButtonConfig),
    focusPreview: (preview: boolean, setPreview: (preview: boolean) => void, data: PreviewNodeData, flow: TFlowContext, flowInstance: ReactFlowInstance)=>({
        icon: 'zoom-to-fit',
        onClick: () => {
            if(!preview) setPreview(!preview)
            // const bottomPadding = 30
            const bottomPadding = 0
            const zoom = ()=>{
                if(!data.lastRect) return
                const x = data.lastRect.x + FLOW_NODE_PREVIEW_PADDING/2
                const y = data.lastRect.y - data.lastRect.height + FLOW_NODE_PREVIEW_Y + FLOW_NODE_PREVIEW_PADDING/2
                const width = data.lastRect.width - FLOW_NODE_PREVIEW_PADDING
                const height = data.lastRect.height + bottomPadding - FLOW_NODE_PREVIEW_PADDING
                flowInstance.fitBounds({x, y, width, height}, {padding: 0, duration: 600})
            }
            if(data.lastRect) zoom()
            else data.doOnce('previewRender', zoom)
        },
        tooltip: "Focus preview"
    } as NodeHeaderButtonConfig),
    download: (exportFile: ()=>File|undefined)=>({
        icon: 'download',
        onClick: () => {
            const file = exportFile()
            file && downloadFile(file)
        },
        tooltip: "Download"
    } as NodeHeaderButtonConfig),
    lockPosition: (lockPosition: boolean, setLockPosition: (lockPosition: boolean) => void) => ({
        icon: lockPosition ? 'lock' : 'unlock',
        onClick: () => setLockPosition(!lockPosition),
        tooltip: lockPosition ? 'Unlock position' : 'Lock position'
    } as NodeHeaderButtonConfig),
}
