import {
    ExtendedShaderMaterial,
    GLSL3,
    IWebGLRenderer,
    matDefineBool,
    MaterialExtension,
    serializable,
    serialize,
    Vector2,
    Vector3,
    Vector4,
    WebGLMultipleRenderTargets,
    WebGLRenderTarget,
} from 'threepipe'
import {NodeShaderPassBase} from './NodeShaderPassBase'
import toyDefault from './shaders/toyDefault.glsl'
import toyFrag from './shaders/toyFrag.glsl'
import toyVert from './shaders/toyVert.glsl'
import {createNodeConnectionSlot} from '../nodes/data/NodeData'

@serializable('ShaderToyShaderPass')
export class ShaderToyShaderPass extends NodeShaderPassBase {
    static PassTypeName = 'Shader Toy' // for ui
    passId: string = 'shader-toy';
    readonly toyExtension: MaterialExtension;

    dirty = false

    @serialize()
    get toyFragmentShader() {
        return this.toyExtension.parsFragmentSnippet as string || ''
    }

    set toyFragmentShader(v) {
        if(this.toyExtension.parsFragmentSnippet === v) return
        this.toyExtension.parsFragmentSnippet = v
        this.toyExtension.setDirty?.()
        this.toyExtension.computeCacheKey = Math.random().toString()
        this.setNeedsUpdate()
    }

    @serialize()
    @matDefineBool('IS_SCREEN')
    isScreen: boolean = true

    constructor(defaultShader: string = toyDefault, isScreen = true, channels = ['iChannel0', 'iChannel1', 'iChannel2', 'iChannel3'], editable = true) {
        super(new ExtendedShaderMaterial({
            uniforms: {
                iTimeDelta: {value: 0},
                iDate: {value: new Vector4()},
                iFrameRate: {value: 0},
                iChannel0: {value: null},
                iChannel1: {value: null},
                iChannel2: {value: null},
                iChannel3: {value: null},
                iChannel0Size: {value: new Vector2()},
                iChannel1Size: {value: new Vector2()},
                iChannel2Size: {value: new Vector2()},
                iChannel3Size: {value: new Vector2()},
                iChannelTime: {value: [0, 0, 0, 0]},
                iChannelResolution: {value: [new Vector3(), new Vector3(), new Vector3(), new Vector3()]},
            },
            defines: {
                IS_SCREEN: isScreen ? '1' : '0',
            },
            glslVersion: GLSL3,
            vertexShader: toyVert,
            fragmentShader: toyFrag,
            transparent: true,
            depthTest: false,
            depthWrite: false,
            premultipliedAlpha: false,
        }, channels, false), editable, false, ...channels)
        // this.material.glslVersion = '#version 300 es\n'

        // ignored from parsing
        this.ignoredUniforms.push("iTimeDelta", "iDate", "iFrameRate", "iChannel0", "iChannel1", "iChannel2", "iChannel3", "iChannel0Size", "iChannel1Size", "iChannel2Size", "iChannel3Size", "iChannelTime", "iChannelResolution", "iSampleRate")

        this.toyExtension = {
            parsFragmentSnippet: defaultShader,
            isCompatible: () => true,
        }
        this.material.registerMaterialExtensions([this.toyExtension])
        this.toyExtension.computeCacheKey = Math.random().toString()
        this.toyExtension.setDirty?.()
        this.material.needsUpdate = true
        // for (const tex of channels) { // done in parent class
        //     this.inputs.textures![tex] = this.material.uniforms[tex]
        // }
        if(editable) {
            const toySlot = createNodeConnectionSlot('shader', {
                name: 'shaderToy',
                label: 'Shader Toy Shader',
                // hasValue: true,
                getValue: () => this.toyFragmentShader,
                setValue: (value) => {
                    // if(value === undefined || value === null) value = toySlot.defaultValue!
                    this.toyFragmentShader = value
                },
            })
            this._slots.push(toySlot)
            // this.refreshSlots()
            // console.log(this.slots)
        }

        this.initialized = true
        this.refreshDynamicSlots()
    }

    render(renderer: IWebGLRenderer, writeBuffer?: WebGLRenderTarget | null, readBuffer?: WebGLMultipleRenderTargets | WebGLRenderTarget, deltaTime?: number, maskActive?: boolean) {
        const renderManager = renderer.renderManager
        // this.material.uniforms.iTime.value = renderManager.clock.elapsedTime
        this.material.uniforms.iTimeDelta.value = deltaTime || renderManager.clock.getDelta()
        // this.material.uniforms.iFrame.value = renderManager.totalFrameCount
        this.material.uniforms.iFrameRate.value = 30
        this.material.uniforms.iChannelTime.value = [0, 0, 0, 0]
        const date = new Date()
        this.material.uniforms.iDate.value.set(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds() + date.getMilliseconds() / 1000)
        // this.material.uniforms.iChannelResolution.value = [new Vector3(), new Vector3(), new Vector3(), new Vector3()]
        // this.material.uniforms.iMouse.value.set(0, 0, 0, 0)

        // todo set this in all shaders automatically based on global settings gamma correct
        // if(writeBuffer?.texture.colorSpace !== SRGBColorSpace){
        //     this.material.defines.IS_LINEAR_OUTPUT = 1
        //     this.material.needsUpdate = true
        // }else {
        //     delete this.material.defines.IS_LINEAR_OUTPUT
        //     this.material.needsUpdate = true
        // }

        super.render(renderer, writeBuffer, readBuffer, deltaTime, maskActive);
        this.dirty = false
    }

}
