import {Color, IRenderTarget, serializable, serialize, WebGLRenderTarget} from 'threepipe'
import {PreviewNodeData} from './PreviewNodeData'
import {createNodeConnectionSlot, NodeData} from './NodeData'

@serializable('RenderTargetNodeData')
export class RenderTargetNodeData extends PreviewNodeData implements NodeData {
    @serialize() value: IRenderTarget & WebGLRenderTarget
    @serialize() clear: boolean
    @serialize() clearColor: Color
    @serialize() clearAlpha: number

    // no need to serialize since that is done in value
    get name(){
        return this.value.texture.name
    }
    set name(v: string){
        this.value.texture.name = v
    }

    readBuffer?: IRenderTarget & WebGLRenderTarget

    constructor(value?: WebGLRenderTarget, clear?: boolean, clearColor?: Color, clearAlpha?: number, preview?: boolean) {
        super(preview)
        this.value = value || new WebGLRenderTarget(1, 1)
        this.clear = clear || true
        this.clearColor = clearColor ?? new Color(0, 0, 0)
        this.clearAlpha = clearAlpha ?? 0
        this.slots.push(createNodeConnectionSlot('buffer', {
            name: 'readBuffer',
            label: 'Read Buffer',
            getValue: () => this.readBuffer || null,
            setValue: (value) => this.readBuffer = value || undefined
        }))
        this.slots.push(createNodeConnectionSlot('buffer', {
            name: 'writeBuffer',
            getValue: () => this.value,
        }))
    }

    dispose() {
        this.value.dispose()
        this.readBuffer = undefined
    }
}
