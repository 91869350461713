import {SimpleEventDispatcher} from 'ts-browser-helpers'
import {
    type ISerializedConfig,
    onChangeDispatchEvent,
    SerializationMetaType,
    serialize,
    ThreeSerialization
} from 'threepipe'
import {getSlotVal, NodeConnectionSlot, setInternalVal} from './NodeData'

function deserializeSlots(data: ISerializedConfig, slots: NodeConnectionSlot<any>[]) {
    if (data.slots) {
        for (const slot of slots) {
            if (data.slots[slot.name] !== undefined) {
                setInternalVal(slot, ThreeSerialization.Deserialize(data.slots[slot.name], undefined))
            }
        }
    }
    slots.forEach(s => {
        setInternalVal(s, getSlotVal(s))
    })
}

function serializeSlots(data: any, slots: NodeConnectionSlot<any>[], prefix = 'dynamic_') {
    if (!data.slots) data.slots = {}
    for (const slot of slots) {
        if (slot.name.startsWith(prefix)) {
            data.slots[slot.name] = ThreeSerialization.Serialize(slot.defaultValue)
        }
    }
}

export abstract class BaseNodeData<T extends string = string> extends SimpleEventDispatcher<T | 'delete' | 'slotsChanged' | 'serialize' | 'deserialize'> {
    // dont serialize
    @onChangeDispatchEvent('slotsChanged')
    slots: NodeConnectionSlot<any>[]

    @serialize()
    metadata: any = {}

    // @serialize()
    // lockPosition = false
    // @serialize()
    // lockSelection = false

    constructor() {
        super()
        this.slots = []
    }

    // todo rename?
    onNodeDelete() {
        this.dispose()
        this.dispatchEvent({type: 'delete'})
    }

    // todo add to SimpleEventDispatcher?
    async doOnce<TRet>(event: string, func: (...args: any[]) => TRet): Promise<TRet> {
        return new Promise((resolve) => {
            const listener = async (...args: any[]) => {
                this.removeEventListener(event as any, listener)
                resolve(await func(...args))
            }
            this.addEventListener(event as any, listener)
        })
    }

    // todo automatically clear all slots also in this
    abstract dispose(): void;

    toJSON(meta?: SerializationMetaType): ISerializedConfig {
        const data: any = ThreeSerialization.Serialize(this, meta, true)
        // data.type = this.constructor.PluginType
        // data.assetType = 'config'

        // dynamic slot internal values
        serializeSlots(data, this.slots)

        this.dispatchEvent({type: 'serialize', data})

        return data
    }

    fromJSON(data: ISerializedConfig, meta?: SerializationMetaType): this|null {
        // if (data.type !== this.constructor.PluginType && data.type !== this.constructor.OldPluginType)
        //     return null

        // internal values
        ThreeSerialization.Deserialize(data, this, meta, true)
        deserializeSlots(data, this.slots)

        this.dispatchEvent({type: 'deserialize', data, meta})
        return this
    }
}
