import {RenderTargetNode} from '../../nodes/RenderTargetNode'
import {NoBlending, WebGLRenderTarget} from 'threepipe'
import {FlowRendererPlugin1} from '../plugins/flowRendererPlugin1'

export function renderRenderTargetNode(node: RenderTargetNode, vs: FlowRendererPlugin1, renderForward = false) {
    if(!vs.viewer) throw new Error('Viewer not initialized')
    const target = node.data.value as WebGLRenderTarget
    if (node.data.clear) {
        vs.viewer.renderManager.clearColor({
            r: node.data.clearColor.r,
            g: node.data.clearColor.g,
            b: node.data.clearColor.b,
            a: node.data.clearAlpha,
            target: target
        })
    }

    if(node.data.readBuffer){
        vs.viewer.renderManager.blit(target, {
            source: node.data.readBuffer.texture,
            blending: NoBlending,
            transparent: true,
            respectColorSpace: true,
            clear: false,
        })
    }

    vs.renderNodePreview(node, target, true)

    if (renderForward) {

        let currId = node.id
        while (true) { // target connected to only one pass
            const edge = vs.edges.find(edge => edge.source === currId &&
                edge.sourceHandle?.split('_')[0] === 'bufferOut' && edge.targetHandle?.split('_')[0] === 'bufferIn')
            // console.log(edge, vs.edges)
            if (!edge) break
            const node1 = vs.nodes.find(node => node.id === edge.target)
            // console.log(node1)
            if (!node1) break
            const res = vs.renderNode(node1)
            if (res !== 'bufferOut') {
                console.error('Invalid edge', edge)
                break
            }
            currId = node1.id
        }
    }
    return 'bufferOut'
}

