import {Button, Classes, InputGroup, Menu, MenuItem, Popover, Position} from '@blueprintjs/core'
import React from 'react'
import {useProjectActions} from '../utils/idb'
import {useLoadingState} from '../utils/loadingState'
import {useDBContext} from '../contexts/DBContext'
import {useFlowContext} from '../contexts/FlowContext'
import {useNavBarContext} from '../contexts/NavbarContext'

export function FlowNavBarProjectName(props: {}) {
    const {project, setProject} = useFlowContext()
    const {setShowVersions} = useNavBarContext()
    const {currentProject} = useDBContext()
    const {loadingState, updateLoading} = useLoadingState()
    const actions = useProjectActions()

    return !project || !currentProject?.project_data ? <></> : <InputGroup
        value={project?.name || ''}
        // leftIcon={'document'}
        onChange={(e) => project && setProject({
            ...project,
            name: e.target.value
        })}
        onBlur={(e) => {
            // console.log(e.target.value || 'Untitled')
            project && setProject({
                ...project,
                name: e.target.value || 'Untitled',
                // __editing: false
            })
        }}
        // readOnly={!project}
        // readOnly={!project || !project.__editing}
        // onFocus={() => project && setProject({...project, __editing: true})}
        // onPointerMove={() => project && setProject({...project, __editing: true})}
        // onPointerEnter={() => project && setProject({...project, __editing: true})}
        // onPointerLeave={(e) => project && e.currentTarget!==document.activeElement && setProject({...project, __editing: false})}
        rightElement={(
            <Popover
                content={
                    <Menu>
                        {/*<MenuItem text="New" icon="document" />*/}
                        {/*<MenuItem text="Open" icon="folder-shared" />*/}
                        <MenuItem text="Save" icon="floppy-disk" onClick={async ()=>updateLoading('project-menu', actions.saveProject())} />
                        <MenuItem text="Export JSON" onClick={()=>updateLoading('project-menu', actions.downloadProjectJSON())} icon="download"/>
                        <MenuItem text="Show Versions" onClick={async ()=>setShowVersions(true)} icon="history"/>
                        <MenuItem text="Close Project" onClick={async ()=>updateLoading('project-menu', actions.closeProject())} icon="cross"/>
                        {/*<MenuItem text="Save as..." icon="floppy-disk" />*/}
                        {/*<MenuDivider />*/}
                        {/*<MenuItem text="Exit" icon="cross" />*/}
                    </Menu>
                }
                minimal={false}
                position={Position.BOTTOM_LEFT}
                renderTarget={({isOpen, ref, ...p}) => (
                    <Button
                        loading={loadingState['project-menu']}
                        {...p} active={isOpen} ref={ref}
                        className={Classes.MINIMAL} icon="caret-down"/>
                )}
            />
        )}
    ></InputGroup>
}
