import {
    bindToValue,
    type ISerializedConfig,
    onChange,
    OrthographicCamera,
    PerspectiveCamera,
    serializable,
    SerializationMetaType,
    serialize,
    Vector3
} from 'threepipe'
import {MinimizeNodeData} from './MinimizeNodeData'
import {ConnectionValueType, createNodeConnectionSlot, NodeData} from './NodeData'

@serializable('CameraNodeData')
export class CameraNodeData extends MinimizeNodeData implements NodeData {
    @onChange('valueChange')
    value: ConnectionValueType['camera'] // dont serialize this

    @serialize()
    @bindToValue({obj: 'value'})
    name!: string

    @serialize()
    @bindToValue({obj: 'value', onChange: 'targetChanged'})
    position!: Vector3

    @serialize()
    @bindToValue({obj: 'value', onChange: 'targetChanged'})
    target!: Vector3

    @serialize()
    @bindToValue({obj: 'value'})
    fov!: number // only for perspective

    @serialize()
    @bindToValue({obj: 'value', onChange: 'updateProjectionMatrix'})
    zoom!: number // only for orthographic

    @serialize()
    @bindToValue({obj: 'value', onChange: 'updateProjectionMatrix'})
    near!: number

    @serialize()
    @bindToValue({obj: 'value', onChange: 'updateProjectionMatrix'})
    far!: number

    constructor(
        value?: ConnectionValueType['camera'],
    ) {
        super()
        if (!value) {
            value = new PerspectiveCamera(45, 1, 0.1, 20)
            value.position.set(0, 0, 3)
        }
        this.value = value
        this.targetChanged()
        this.slots.push(createNodeConnectionSlot('camera', {
            name: 'camera',
            getValue: () => this.value,
        }))
    }

    targetChanged() {
        if (!this.target) this.target = new Vector3(0, 0, 0)
        this.value.lookAt(this.target)
    }

    fromJSON(data: ISerializedConfig, meta?: SerializationMetaType) {
        if (data.perspective !== undefined) {
            this.perspective = data.perspective // we need to set this first so that the camera is converted.
            data = {...data}
            delete data.perspective
        }
        if(!super.fromJSON(data, meta)) return null
        this.targetChanged()
        return this
    }

    @serialize()
    get perspective() {
        return (this.value as PerspectiveCamera).isPerspectiveCamera || false
    }

    set perspective(v: boolean) {
        let changed = false
        if (v && !(this.value as PerspectiveCamera)?.isPerspectiveCamera) {
            this.value = new PerspectiveCamera(45, 1, 0.1, 20)
            changed = true
        } else if (!v && !(this.value as OrthographicCamera)?.isOrthographicCamera) {
            this.value = new OrthographicCamera(-1, 1, 1, -1, 0.1, 20)
            changed = true
        }
        if (changed) {
            this.value.position.set(0, 0, 3)
            this.targetChanged()
        }
    }

    updateProjectionMatrix() {
        this.value.updateProjectionMatrix()
    }

    dispose() {
        // todo hack
        (this.value as any).dispose && (this.value as any).dispose()
    }
}
