import {useProjectActions} from '../utils/idb'
import {useLoadingState} from '../utils/loadingState'
import {Alignment, Button, ButtonGroup, H4, HTMLTable, Tag} from '@blueprintjs/core'
import {WelcomeDialogCreateProjectActions} from './WelcomeDialogCreateProjectActions'
import {WelcomeDialogEmptyProjectState} from './WelcomeDialogEmptyProjectState'
import React, {useMemo} from 'react'
import {isProjectEmpty, useDBContext} from '../contexts/DBContext'

export function WelcomeDialogProjectsTab() {
    const {projects} = useDBContext()
    const projectsSorted = useMemo(() =>
        projects.sort((b, a) =>
            Number(a.localUpdatedAt || a.updated_at || '0') - (Number(b.localUpdatedAt || b.updated_at || '0'))),
        [projects])
    const {loadingState, updateLoading} = useLoadingState()
    const actions = useProjectActions()
    return projects.length > 0 ? (
            <div className="popup-main-container">
                <H4>
                    Projects
                </H4>
                <ButtonGroup className="welcome-main-create-actions">
                    <WelcomeDialogCreateProjectActions alignText={Alignment.CENTER} minimal={false} outlined={true}/>
                </ButtonGroup>
                <HTMLTable interactive={true}
                           className="project-list">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Modified</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {projectsSorted.map((project) => (
                        <tr key={project.id}
                    className="project-list-item">
                    <td
                        onClick={() => !project.localDeletedAt && updateLoading('open-project-' + project.id, actions.loadProject(project))}
                        className="project-list-item-title">
                        {project.name} &nbsp;
                        {isProjectEmpty(project) ? (<Tag minimal round intent={'warning'}>Empty</Tag>) : null}
                        {project.localDeletedAt ? (<Tag minimal round intent={'danger'}>Deleted</Tag>) : null}
                    </td>
                    <td className="project-list-item-modified">
                        {project.localUpdatedAt || project.updated_at ? new Date(project.localUpdatedAt || project.updated_at!).toLocaleDateString() : 'Never'}
                    </td>
                    <td className="project-list-item-actions">
                        <ButtonGroup minimal>
                            {/*Edit Project*/}
                            {!project.localDeletedAt && <Button
                                icon={'edit'} loading={loadingState['open-project-' + project.id]}
                                onClick={() => !project.localDeletedAt && updateLoading('open-project-' + project.id, actions.loadProject(project))}/>}
                            {/*Open in new Tab*/}
                            {/*{project.slug && }*/}
                            {/*<Button small minimal*/}
                            {/*        icon={'share'}*/}
                            {/*        onClick={() => updateLoading('open-project-' + project.id, actions.loadProject(project))} />*/}
                            {/*Download JSON*/}
                            {!isProjectEmpty(project) && <Button
                                icon={'download'} loading={loadingState['download-project-' + project.id]}
                                onClick={(e) => updateLoading('download-project-' + project.id, actions.downloadProjectJSON(project))}/>}
                            {/*Duplicate Project*/}
                            {<Button
                                icon={'duplicate'} loading={loadingState['duplicate-project-' + project.id]}
                                onClick={() => updateLoading('duplicate-project-' + project.id, actions.duplicateProject(project))}/>}
                            {/*Delete Project*/}
                            {!project.localDeletedAt && <Button
                                icon={'trash'} loading={loadingState['delete-project-' + project.id]}
                                onClick={(e) => updateLoading('delete-project-' + project.id,
                                    e.shiftKey ? (actions.deleteProjectForce(project, true)) : actions.deleteProject(project))}/>}
                            {/*More actions*/}
                        </ButtonGroup>
                    </td>
                    </tr>
                    ))}
                    </tbody>
                </HTMLTable>
            </div>
        ) : (<WelcomeDialogEmptyProjectState/>)
}
