import {openDB} from "idb";
import React from 'react'
import {downloadBlob, generateUUID, getUrlQueryParam, ISerializedConfig} from 'threepipe'
import {Button, InputGroup, Label} from '@blueprintjs/core'
import {isProjectEmpty, useDBContext} from '../contexts/DBContext'
import {TFlowContext, useFlowContext} from '../contexts/FlowContext'

export type ProjectRecordType = {
    id: string, name: string,
    project_data: any,
    localUpdatedAt?: number, localDeletedAt?: number,
    updated_at?: string,  // for server
    vid?: string, // for versions
    [key: string]: any}
const tables = [
    "projects",
    "projects_deleted",
    "projects_versions",
    // "user_assets",
    // "profiles",
]
export const idb = {
    db1: openDB("shader-flow-db1", 5, {
        upgrade(database, oldVersion, newVersion, transaction, event) {
            if (oldVersion < 1) {
                tables.forEach(table=> database.createObjectStore(table, {keyPath: "id"}));
            }else if(oldVersion < 3) {
                database.createObjectStore("projects_deleted", {keyPath: "id"});
            }else if(oldVersion < 5) {
                try {
                    const versionsStore = database.createObjectStore("projects_versions", {keyPath: ["id", "vid"]});
                    versionsStore.createIndex('byIdAndDate', ['id', 'localUpdatedAt']);
                }catch (e){ // todo catching because of a temp mistake, remove later
                    console.error(e)
                }
            }
        },
        blocking(currentVersion: number, blockedVersion: number | null, event: IDBVersionChangeEvent) {
            console.warn("blocking", currentVersion, blockedVersion, event)
            if(window.confirm("A new version of the app is available and being used in another tab, please refresh the page to update.")){
                window.location.reload()
            }
        },
        // blocked(currentVersion: number, blockedVersion: number | null, event: IDBVersionChangeEvent) {
        //     console.warn("blocked", currentVersion, blockedVersion, event)
        //     alert("An old version of the app is being used in another tab. Please close all tabs to update.")
        // }
    }),
};

export async function addToIDBStore(store: string, value: any, key?: string) {
    return await (await idb.db1).put(store, value, key);
}

export async function removeFromIDBStore(store: string, key: string) {
    return await (await idb.db1).delete(store, key);
}

export async function getFromIDBStore(store: string, key: string) {
    return await (await idb.db1).get(store, key);
}

export async function getAllTable<T=any>(table: string){
    return await (await idb.db1).getAll(table) as T[]
}

export async function getProjectVersions(projectId: string) {
    return await (await idb.db1).getAllFromIndex('projects_versions', 'byIdAndDate', IDBKeyRange.bound([projectId, 0], [projectId, Date.now()]))
}

export type TDBContext = {
    currentProject?: ProjectRecordType|undefined, setCurrentProject?: (project: ProjectRecordType|undefined) => void,
    projects: ProjectRecordType[], setProjects: (project: ProjectRecordType[]) => void,
    // userAssets?: any[], setUserAssets?: (userAssets: any[]) => void,
    // profiles?: any[], setProfiles?: (profiles: any[]) => void,
}

// export const DBContext = React.createContext<{ v:TDBContext }>({v:{} as TDBContext})
// export function useDBContext() {
//     return React.useContext(DBContext).v
// }
export function DBConnection(){
    const {setProjects} = useDBContext()
    React.useEffect(()=>{
        (async () => {
            const projects = [
                ...await getAllTable("projects"),
                ...(getUrlQueryParam('deleted')!==null ? await getAllTable("projects_deleted") : [])
            ]
            // const userAssets = await getAllTable("user_assets")
            // const profiles = await getAllTable("profiles")
            setProjects(projects)
            // context.setUserAssets?.(userAssets)
            // context.setProfiles?.(profiles)
        })()
    }, [setProjects])
    return <></>
}

async function updateCurrentProject(context: TDBContext, data: Partial<ProjectRecordType>, flow: TFlowContext) {
    if(!context.currentProject) throw new Error("No current project")
    const project = context.currentProject
    let newProject: ProjectRecordType = {...project, ...data, localUpdatedAt: Date.now(), vid: generateUUID()}
    await addToIDBStore("projects", newProject)
    await addToIDBStore("projects_versions", newProject)

    // supabase
    // if(flow.session?.user.id) {
    //     const {data: dataUpdate, error: errorUpdate} = await supabaseClient.rpc('update_project', {
    //         project_id: newProject.id,
    //         project_name: newProject.name,
    //         project_project_data: newProject.project_data,
    //     })
    //     if (errorUpdate) console.error(errorUpdate)
    //
    //     if (!dataUpdate?.id) {
    //         return {
    //             currentProject: newProject,
    //             projects: context.projects.map(p => p.id === project.id ? newProject : p)
    //         }
    //     }
    //
    //     newProject = {...dataUpdate}
    //     await addToIDBStore("projects", newProject)
    // }

    return {
        currentProject: newProject,
        projects: context.projects.map(p => p.id === project.id ? newProject : p)
    }
}

async function createProject(context: TDBContext, project_data: any, load = true) {
    const newProject = {project_data, name: project_data.project?.name||'Untitled', id: generateUUID(), localUpdatedAt: Date.now()}
    context.setProjects?.([...context.projects, newProject])
    await addToIDBStore("projects", newProject)
    if(load) context.setCurrentProject?.(newProject);
}

async function deleteProject(context: TDBContext, row: Pick<ProjectRecordType, 'id'|'vid'>) {
    // if(context.currentProject) throw new Error("Cannot delete any project while in edit mode") // todo
    if(context.currentProject?.id === row.id) throw new Error("Cannot delete loaded project")
    // if(row.vid) throw new Error('TODO: implement delete version')
    const project = await getFromIDBStore("projects", row.id) as ProjectRecordType
    if(!isProjectEmpty(project)) {
        project.localDeletedAt = Date.now()
        await addToIDBStore("projects_deleted", project).catch((e)=>{
            console.error('Unable to backup project', e)
            downloadBlob(new Blob([JSON.stringify(project)], {type: 'application/json'}), project.name + '.json')
        })
    }
    await removeFromIDBStore("projects", row.id)
    return {projects: context.projects.filter(p => p.id !== row.id)}
    // context.setProjects?.(context.projects.filter(p => p.id !== row.id))
}

async function updateProject(context: TDBContext, row: ProjectRecordType, updateContext = true) {
    if(!row.id) throw new Error("Cannot update project without id")
    await addToIDBStore("projects", row)
    if(!updateContext) return
    if(context.projects.find(p => p.id === row.id)) {
        context.setProjects?.(context.projects.map(p => p.id === row.id ? row : p))
    }else {
        context.setProjects?.([...context.projects, row])
    }
}

async function saveProject(context: TDBContext, project_data: any, flow: TFlowContext, autosave = false) {
    const project = context.currentProject
    if (!project) throw new Error("No project loaded, cannot save project data")

    // in case state is cleared, we dont want project to get cleared by mistake
    const isCleared =
        //project_data.project.name === 'Untitled' &&
        !project_data.nodes.length && !project_data.edges.length &&
        (project.project_data.name !== 'Untitled' || project.project_data.nodes.length || project.project_data.edges.length)

    if(isCleared){
        console.warn("Project might be cleared by mistake, not saving")
        return
    }
    if(autosave && !isProjectDataChanged(project_data, project)) return

    if(autosave) console.log('Autosaving...')

    // supabase
    // if(!project.created_at && flow.session?.user.id){
    //     // create project on server
    //     const createProjectResponse = await supabaseClient.rpc('create_project')
    //     if (createProjectResponse.error) console.error(createProjectResponse.error)
    //     if(createProjectResponse.data){
    //         const ret = await updateCurrentProject(context, {...createProjectResponse.data, project_data, name: project_data.project?.name||'Untitled'}, flow)
    //         context.currentProject = ret.currentProject // for delete project
    //         await deleteProject(context, project)
    //         return ret
    //     }
    // }

    return updateCurrentProject(context, {project_data, name: project_data.project?.name||'Untitled'}, flow)
}

export function isProjectDataChanged(project_data?: ISerializedConfig | Record<string, never>, currentProject?: ProjectRecordType) {
    const cl = {
        asset: {}, // metadata with timestamp
        viewport: {},
        timeState: {},
        mouseState: {},
    }
    // console.log(JSON.stringify({...project_data,...cl,}))
    // console.log(JSON.stringify({...currentProject?.project_data, ...cl}))
    return (!project_data?.project || !currentProject) ? false : JSON.stringify({...project_data,...cl,}) !== JSON.stringify({...currentProject.project_data, ...cl})
}

// export async function loadProject(context: TDBContext, project: ProjectRecordType) {
//     context.setCurrentProject?.(project);
// }
function deleteProjectConfirmation(db: TDBContext, flow: TFlowContext, row: Pick<ProjectRecordType, "id" | "name">, resolve: () => void) {
    const state = {value: ""}
    flow.setDialog({
        ...flow.dialog,
        // canClose: false,
        isOpen: true,
        state,
        title: "Delete Project",
        content: (
            <Label>
                Enter the name of the project to confirm deletion. <br/>
                Name: <b>{row.name}</b> <br/>
                <InputGroup style={{marginTop: "10px"}}
                    placeholder="Project Name"
                    defaultValue={state.value}
                    onChange={(e: any) => state.value = e.target.value}
                />
                <br/>
                <span className={"bp5-text-muted"}>
                Tip: Hold down shift before clicking delete to bypass this confirmation.
                </span>
            </Label>
        ),
        actions: (
            <>
                <Button text={'Cancel'} onClick={() => {
                    flow.setDialog({...flow.dialog, isOpen: false})
                    resolve()
                }}/>
                <Button text={'Delete'} intent="danger" onClick={async () => {
                    flow.setDialog({...flow.dialog, isOpen: false})
                    if (state.value === row.name) {
                        const res = await deleteProject(db, row)
                        db.setProjects(res.projects)
                    } else {
                        alert("Incorrect project name, deletion aborted")
                    }
                    resolve()
                }}/>
            </>
        ),
    })
}

export function useProjectActions(){
    const db = useDBContext()
    const flow = useFlowContext()
    return {
        createProject: async (data = {}) => createProject(db, data),
        saveProject: async (autosave = false) => {
            const saved = await saveProject(db, flow.plugin?.saveProject(), flow, autosave)
            if(!saved) return
            db.setCurrentProject?.(saved.currentProject)
            db.setProjects(saved.projects)
        },
        loadProject: async (project: ProjectRecordType) => {
            db.setCurrentProject?.(project);
            const data ={...project.project_data}
            // console.log(data, project.project_data)//, project.project_data.resources.extras)
            flow.plugin?.loadProject(data)
        },
        loadTemplate: async (path: string)=>{
            const url = path.startsWith('http') ? path : (window.location.origin + `/templates/`+path)
            const data = await fetch(encodeURI(url)).then(r=>r.json())
            if(!data?.type) {
                alert("Unable to load, please try again later. Let me know on discord, if this persists.")
                return;
            }
            await createProject(db, data, true)
            flow.plugin?.loadProject(data)
            // createProject(db, {})
        },
        downloadProjectJSON: async (project?: ProjectRecordType) => { // todo async not required.
            if(!project && !flow.plugin){
                console.error("No project loaded")
                return
            }
            const data = project?.project_data ?? flow.plugin?.saveProject()
            const json = JSON.stringify(data)
            const blob = new Blob([json], {type: 'application/json'})
            downloadBlob(blob, (project ?? flow.plugin?.project)?.name + '.json')
        },
        duplicateProject: async (project: ProjectRecordType) => {
            function incrementProjectName(name: string){
                if(!name) return 'Untitled copy'
                return name.match(/ copy \d+$/) ? name.replace(/ copy (\d+)$/, (m, p1) => ' copy ' + (parseInt(p1) + 1)) : name + ' copy'
            }
            const project_data = project.project_data
            const data = {...project_data, project: {...project_data.project, name: incrementProjectName(project_data.project.name)}}
            return createProject(db, data, false)
        },
        closeProject: async () => {
            const saved = await saveProject(db, flow.plugin?.saveProject(), flow)
            db.setCurrentProject?.(undefined);
            if(saved) db.setProjects(saved.projects);
            flow.plugin?.loadEmptyProject()
        },
        deleteProject: async (row: Pick<ProjectRecordType, 'id'|'name'>) => new Promise<void>(resolve => deleteProjectConfirmation(db, flow, row, resolve)),
        deleteProjectForce: async (row: Pick<ProjectRecordType, 'id'|'name'>, updateContext: boolean) => {
            const res = await deleteProject(db, row)
            if(updateContext) db.setProjects?.(res.projects)
        },
        updateProjectForce: async (project: ProjectRecordType, updateContext: boolean) => updateProject(db, project, updateContext),
    }
}

// todo by ai
// async function cleanupOldVersions(tx: IDBPTransaction<unknown, ['projects', 'projects_versions'], 'readwrite'>, projectId: string) {
//     const versionStore = tx.objectStore('projects_versions');
//     const index = versionStore.index('byIdAndDate');
//     let cursor = await index.openCursor(IDBKeyRange.bound([projectId, 0], [projectId, Date.now()]), 'prev');
//
//     let count = 0;
//     const toDelete = [];
//
//     while (cursor) {
//         if (count >= 500) {
//             toDelete.push(cursor.primaryKey);
//         }
//         count++;
//         cursor = await cursor.continue();
//     }
//
//     for (const key of toDelete) {
//         await versionStore.delete(key);
//     }
// }
