import {createRenderTargetNode, RenderTargetNode} from './nodes/RenderTargetNode'
import {createShaderPassNode, ShaderPassNode} from './nodes/ShaderPassNode'
import {createRenderPassNode} from './nodes/RenderPassNode'
import {createTextureNode, TextureNode} from './nodes/TextureNode'
import {createScene3dNode} from './nodes/Scene3dNode'
import {object3dRenderer} from './utils/renderers/object3dRenderer'
import {createCameraNode} from './nodes/CameraNode'
import {createRenderToScreenNode, RenderToScreenNode} from './nodes/RenderToScreenNode'
import {createShaderMathFunctionNode} from './nodes/ShaderMathFunctionNode'
import {createShaderSnippetNode} from './nodes/ShaderSnippetNode'
import TextureNodeFC from './nodes/fc/TextureNodeFC'
import ShaderSnippetNodeFC from './nodes/fc/ShaderSnippetNodeFC'
import ShaderPassNodeFC from './nodes/fc/ShaderPassNodeFC'
import ShaderMathFunctionNodeFC from './nodes/fc/ShaderMathFunctionNodeFC'
import Scene3dNodeFC from './nodes/fc/Scene3dNodeFC'
import RenderToScreenNodeFC from './nodes/fc/RenderToScreenNodeFC'
import RenderTargetNodeFC from './nodes/fc/RenderTargetNodeFC'
import RenderPassNodeFC from './nodes/fc/RenderPassNodeFC'
import CameraNodeFC from './nodes/fc/CameraNodeFC'
import {NodeTypes} from './NodeTypes'
import {createRichTextNode} from './nodes/RichTextNode'
import RichTextNodeFC from './nodes/fc/RichTextNodeFC'
import VectorNodeFC from './nodes/fc/VectorNodeFC'
import {createVectorNode} from './nodes/VectorNode'
import {createContextVariablesNode} from './nodes/ContextVariablesNode'
import ContextVariablesNodeFC from './nodes/fc/ContextVariablesNodeFC'
import {createShaderMathDefineNode} from './nodes/ShaderMathDefineNode'
import ShaderMathDefineNodeFC from './nodes/fc/ShaderMathDefineNodeFC'
import {FlowRendererPlugin1} from './utils/plugins/flowRendererPlugin1'
import {renderPassNode} from './utils/renderers/pass'
import {renderRenderTargetNode} from './utils/renderers/renderTarget'
import {shaderMathDefineRenderer} from './utils/renderers/renderMathDefineNode'

// todo remove all create functions
export const NodeTypeDefs: NodeTypes = {
    renderTarget: {
        label: 'Render Target',
        create: createRenderTargetNode,
        Component: RenderTargetNodeFC,
        renderer: {
            render: renderRenderTargetNode,
            getPreviewTarget: (node: RenderTargetNode) => node.data.value,
            type: 'renderTarget',
            // renderOrder: 1,
        },
    },
    shaderPass: {
        label: 'Shader Pass',
        create: createShaderPassNode,
        Component: ShaderPassNodeFC,
        renderer: {
            render: renderPassNode,
            autoResolveInputs: false,
            getPreviewTarget: (node: ShaderPassNode) => node.data.tempTarget || node.data.lastBuffer || undefined,
            type: 'shaderPass',
            // renderOrder: 2,
        },
    },
    renderPass: {
        label: 'Render Pass',
        create: createRenderPassNode,
        Component: RenderPassNodeFC,
        renderer: {
            render: renderPassNode,
            autoResolveInputs: false,
            getPreviewTarget: (node: ShaderPassNode) => node.data.tempTarget || node.data.lastBuffer || undefined,
            type: 'renderPass',
            // renderOrder: 2,
        },
    },
    texture: {
        label: 'Texture',
        create: createTextureNode,
        Component: TextureNodeFC,
        renderer: {
            render: (node: TextureNode, vs: FlowRendererPlugin1)=>{vs.renderNodePreview(node); return 'textureOut';},
            getPreviewTarget: (node: TextureNode) => ({texture: node.data.value, width: node.data.value.image.width, height: node.data.value.image.height}),
            type: 'texture',
            // renderOrder: 3,
        },
    },
    scene3d: {
        label: 'Scene 3D',
        create: createScene3dNode,
        Component: Scene3dNodeFC,
        renderer: object3dRenderer,
    },
    camera: {
        label: 'Camera',
        create: createCameraNode,
        Component: CameraNodeFC,
    },
    vector: {
        label: 'Vector',
        create: createVectorNode,
        Component: VectorNodeFC,
    },
    renderToScreen: {
        label: 'Render to Screen',
        create: createRenderToScreenNode,
        Component: RenderToScreenNodeFC,
        renderer: {
            render: (node, vs)=>{vs.renderNodePreview(node); return 'textureOut';},
            type: 'renderToScreen',
            getPreviewTarget: (node: RenderToScreenNode) => (!node.data.texture ? undefined : {texture: node.data.texture, width: node.data.texture.image.width, height: node.data.texture.image.height}),
        },
    },
    shaderMathFunction: {
        label: 'Shader Math Function',
        create: createShaderMathFunctionNode,
        Component: ShaderMathFunctionNodeFC,
    },
    shaderMathDefine: {
        label: 'Shader Math Define',
        create: createShaderMathDefineNode,
        Component: ShaderMathDefineNodeFC,
        renderer: shaderMathDefineRenderer,
    },
    shaderSnippet: {
        label: 'Shader Snippet',
        create: createShaderSnippetNode,
        Component: ShaderSnippetNodeFC,
    },
    richText: {
        label: 'Rich Text',
        create: createRichTextNode,
        Component: RichTextNodeFC,
    },
    contextVariables: {
        label: 'Context Variables',
        create: createContextVariablesNode,
        Component: ContextVariablesNodeFC,
    },
}


