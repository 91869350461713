import {Vector2, Vector3, Vector4} from 'threepipe'
import {ControlGroup, Label, NumericInput} from '@blueprintjs/core'
import React from 'react'
import {IconName} from '@blueprintjs/icons'

export type IVector = number | Vector2 | Vector3 | Vector4
function NumInput<T extends IVector>(props: { field: 'x'|'y'|'z'|'w', value: T, onValueChange?: (v: T) => void, icon?: IconName, disabled?: boolean }) {
    return <NumericInput placeholder="0"
                         small={true}
                         className="numberInput"
                         leftIcon={props.icon}
                         stepSize={0.1}
                         disabled={!props.onValueChange || props.disabled}
                         hidden={props.disabled}
                         minorStepSize={0.01}
                         majorStepSize={1}
                         value={props.onValueChange ? undefined : typeof props.value === 'number' ? props.value : (props.value as any)[props.field]}
                         defaultValue={!props.onValueChange ? undefined : typeof props.value === 'number' ? props.value : (props.value as any)[props.field]}
                         onValueChange={!props.onValueChange ? undefined : (v)=>{
                             if(typeof props.value !== 'number') (props.value as any)[props.field] = v
                             props.onValueChange && props.onValueChange(typeof props.value !== 'number' ? props.value : v as any)
                         }}
    />
}

export function vectorDimension(value: IVector) {
    return typeof value === 'number' ? 1 :
        (value as Vector4).w !== undefined ? 4 :
            (value as Vector3).z !== undefined ? 3 : 2
}

// todo rename to vector input group
export function Vec3InputGroup(props: {
    name: string,
    label: string,
    value: IVector,
    onChange?: (v: IVector) => void,
    disabled?: boolean,
    children1?: React.ReactNode,
    children2?: React.ReactNode,
}) {
    const type= vectorDimension(props.value)
    return (
    // <FormGroup inline={true}
    //                   label={props.label}
    //                   labelFor={props.name + "-input"} className="num-input-form-group bp5-small">
        <ControlGroup id={props.name + "-input"} className={"bp5-small num-input-form-group"}>
            {props.children1}
            <Label className={props.children1 ? "slot-in-control-label" : ""} style={{marginRight: "10px"}}>{props.label}</Label>
            <NumInput field='x' value={props.value} onValueChange={props.onChange} disabled={props.disabled}/>
            {type < 2 ? null : <NumInput field='y' value={props.value} onValueChange={props.onChange} disabled={props.disabled}/>}
            {type < 3 ? null : <NumInput field='z' value={props.value} onValueChange={props.onChange} disabled={props.disabled}/>}
            {type < 4 ? null : <NumInput field='w' value={props.value} onValueChange={props.onChange} disabled={props.disabled}/>}
            {props.children2}
        </ControlGroup>
    // </FormGroup>
    )
}
