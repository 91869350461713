import {NodeProps, useReactFlow} from 'reactflow'
import {Scene3dNodeData} from '../data/Scene3dNodeData'
import {useFlowContext} from '../../contexts/FlowContext'
import React from 'react'
import {Button, InputGroup, Label} from '@blueprintjs/core'
import NodeHeaderComponent, {nodeHeaderButtons} from '../../components/NodeHeaderComponent'
import {filterSlots, getSlot} from '../data/NodeData'
import {NodeInOutHandleSlot} from '../../components/NodeInOutHandleSlot'
import {HandleOutSlot} from '../../utils/HandleOutSlot'
import {BaseNodeCard} from '../../components/BaseNodeCard'
import {useThreeViewer} from '../../components/ThreeViewerComponent'

function Scene3dNodeFC({data, selected, isConnectable, dragging}: NodeProps<Scene3dNodeData>) {
    const viewer = useThreeViewer()
    const flow = useFlowContext()
    const flowInstance = useReactFlow()
    const [preview, setPreview] = React.useState(data.preview)
    const [minimize, setMinimize] = React.useState(data.minimize)
    React.useEffect(() => {
        data.preview = preview
        data.minimize = minimize
    }, [preview, minimize, data])

    // todo add the same in texture node
    const [url, setUrl] = React.useState(data.value)
    React.useEffect(() => {
        data.addEventListener('valueChanged', () => setUrl(data.value))
    }, [setUrl, data])

    return (
        <BaseNodeCard minimize={minimize} selected={selected} dragging={dragging}>
            <NodeHeaderComponent title={`Scene (3D)`} buttons={[
                !preview ? null : nodeHeaderButtons.focusPreview(preview, setPreview, data, flow, flowInstance),
                nodeHeaderButtons.preview(preview, setPreview),
                // nodeHeaderButtons.download(() => { // todo:
                //     const blob = flow.viewer?.renderManager.exportRenderTarget(data.value)
                //     return blob ? new File([blob], `${data.value.texture.name}.${blob.ext}`) : undefined
                // })
                nodeHeaderButtons.minimize(minimize, setMinimize),
            ]}/>
            {!minimize && (<>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    Name
                    <InputGroup
                        small={true}
                        placeholder="Name"
                        defaultValue={data.name}
                        onChange={(evt) => data.name = evt.target.value}
                    />
                </Label>
                <Label className="bp5-inline bp5-flex-label bp5-small">
                    URL
                    <InputGroup
                        small={true}
                        placeholder="https://example.com/scene.glb"
                        defaultValue={url}
                        onChange={evt => setUrl(evt.target.value)}
                        rightElement={<Button icon={"download"} disabled={url===data.value} small minimal onClick={() => data.loadData(url, viewer!)}/>}
                    />
                </Label>
                {filterSlots(data.slots, 'texture').map((slot) =>
                    <NodeInOutHandleSlot key={slot.name} type={'texture'} slot={slot} connectable={isConnectable}/>)
                }
                {filterSlots(data.slots, 'camera').map((slot) =>
                    <NodeInOutHandleSlot key={slot.name} type={'camera'} slot={slot} connectable={isConnectable}/>)
                }
            </>)}
            <HandleOutSlot type={'object3d'} main={true}
                           slot={getSlot(data.slots, 'object3d', 'scene')}
                           connectable={isConnectable}/>

        </BaseNodeCard>
    );
}

export default Scene3dNodeFC
