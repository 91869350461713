import {TFlowContext} from '../../contexts/FlowContext'
import {Button, InputGroup, Label} from '@blueprintjs/core'
import {ImportPopupFileButton} from '../../components/importPopupFileButton'
import {autoPlaceNodes} from '../addNodes'
import {createShaderSnippetNode} from '../../nodes/ShaderSnippetNode'
import React from 'react'

// todo make a generic popup thing...
export const remoteGLSLAdd = (context: TFlowContext) => {
    const {dialog, setDialog} = context
    if (!dialog || !setDialog) return
    const state = {value: ''}
    setDialog({
        ...dialog,
        canClose: true,
        isOpen: true,
        state,
        title: 'Import GLSL File By URL',
        content: (
            <Label>
                GLSL File URL:
                <InputGroup
                    placeholder="https://lygia.xyz/math/atan2.glsl"
                    defaultValue={state.value}
                    onChange={(e: any) => state.value = e.target.value}
                />
            </Label>
        ),
        actions: (
            <>
                <Button text={'Close'} onClick={() => setDialog({...dialog, isOpen: false})}/>
                <ImportPopupFileButton state={state} importer={(s, v)=>{
                    return fetch(s).then(r => r.text()).then(t => {
                        const name = new URL(s).pathname.split('/').pop()
                        autoPlaceNodes(v, [createShaderSnippetNode(t, name)])
                        return name?.split('.').shift() || 'Untitled'
                    })
                }}/>
            </>
        ),
    })
}
