import {BezierEdge, EdgeProps, Position, useNodes} from 'reactflow'
import React from 'react'

export default function DefaultEdge(props: EdgeProps) {
    const props2: Partial<EdgeProps> = {}
    // this is for when nodes are collapsed and target handles are not found. then react flow gives incorrect position(as right instead of left)
    // todo find a better way after react flow upgrade. this is more of a hack
    const targetNode = useNodes().find(n => n.id === props.target)
    if(props.targetHandleId?.includes('In_') && props.targetPosition === Position.Right) {
        // console.log(props.targetHandleId)
        props2.targetPosition = Position.Left
        const n = targetNode?.width || 0
        props2.targetX = (props.targetX) - n - 10
    }
    return <BezierEdge {...props} {...props2} />;
}
