import React from 'react'
import {AnchorButton, Button, ButtonGroup, Tooltip} from '@blueprintjs/core'
import {useFlowContext} from '../contexts/FlowContext'

export function FlowNavBarPlaybackControls(props: {
    currentTimeLabelRef: React.MutableRefObject<HTMLSpanElement | null>
}) {
    const {plugin, keepDirty, setKeepDirty} = useFlowContext()
    return (
        <ButtonGroup style={{gap: '0rem'}}>
            <Tooltip intent="primary" content="Restart" placement="bottom">
                <Button minimal={true} intent={'primary'} icon={'step-backward'}
                        onClick={plugin?.resetTimeState}/>
            </Tooltip>
            <Tooltip intent="primary" content={keepDirty ? 'Pause' : 'Run'} placement="bottom">
                <Button minimal={true} intent={'primary'} icon={keepDirty ? 'pause' : 'play'}
                        onClick={() => setKeepDirty(!keepDirty)}/>
            </Tooltip>
            <Tooltip intent="primary" content={'Step Frame'} placement="bottom">
                <AnchorButton minimal={true} intent={'primary'} icon={'step-forward'}
                              disabled={keepDirty} onClick={plugin?.setDirty}/>
            </Tooltip>
            {/*<Tooltip intent="primary" content={'Increment time'} placement="bottom">*/}
            {/*    <Button minimal={true} intent={'none'} icon={'time'}*/}
            {/*            onClick={() => setKeepDirty(!keepDirty)}/>*/}
            {/*</Tooltip>*/}
            <Tooltip intent="primary" content={'Time [Frame]'} placement="bottom">
                <span style={{
                    minWidth: '7rem',
                    padding: '5px 10px',
                    display: 'inline-flex', alignItems: 'center',
                    fontFamily: 'math, ui-monospace, monospace', fontSize: '1.1rem'
                }} ref={props.currentTimeLabelRef}> 0 </span>
            </Tooltip>
            {/*<Popover*/}
            {/*    content={*/}
            {/*        <Menu>*/}
            {/*            /!*<MenuItem text="New" icon="document" />*!/*/}
            {/*            /!*<MenuItem text="Open" icon="folder-shared" />*!/*/}
            {/*            <MenuItem text="Increment Time (when paused)" icon="time" onClick={async ()=>{}} />*/}
            {/*            /!*<MenuItem text="Export JSON" onClick={()=>updateLoading('project-menu', actions.downloadProjectJSON())} icon="download"/>*!/*/}
            {/*            /!*<MenuItem text="Close Project" onClick={async ()=>updateLoading('project-menu', actions.closeProject())} icon="cross"/>*!/*/}
            {/*            /!*<MenuItem text="Save as..." icon="floppy-disk" />*!/*/}
            {/*            /!*<MenuDivider />*!/*/}
            {/*            /!*<MenuItem text="Exit" icon="cross" />*!/*/}
            {/*        </Menu>*/}
            {/*    }*/}
            {/*    minimal={false}*/}
            {/*    position={Position.BOTTOM_LEFT}*/}
            {/*    renderTarget={({isOpen, ref, ...p}) => (*/}
            {/*        <Button*/}
            {/*            {...p} active={isOpen} ref={ref}*/}
            {/*            className={Classes.MINIMAL} icon="cog"/>*/}
            {/*    )}*/}
            {/*/>*/}
        </ButtonGroup>
    )
}
