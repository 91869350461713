import {ShaderMathDefineNode} from '../../nodes/ShaderMathDefineNode'
import {NodeRendererType} from '../rendering'
import {FlowRendererPlugin1} from '../plugins/flowRendererPlugin1'
import {LinearFilter, LinearSRGBColorSpace, NoBlending, UnsignedByteType, Vector2, WebGLRenderTarget} from 'threepipe'

const targetSize = new Vector2(512, 512)

export const shaderMathDefineRenderer: NodeRendererType = {
    render: (node: ShaderMathDefineNode, vs: FlowRendererPlugin1) => {
        if (!(node.data.preview && vs.viewer)) return ''
        const target = vs.viewer.renderManager.getTempTarget<WebGLRenderTarget>({
            size: targetSize,
            colorSpace: LinearSRGBColorSpace,
            // colorSpace: SRGBColorSpace, // todo
            type: UnsignedByteType,
            generateMipmaps: false,
            // wrapS: target.texture.wrapS,
            // wrapT: target.texture.wrapT,
            minFilter: LinearFilter,
            magFilter: LinearFilter,
        })

        vs.renderNodePreview(node, target)
        // todo do not release target, store it for next frame maybe and rerender on camera or object change.
        //  but that would require releasing the target when it goes out of the screen or something.
        vs.viewer.renderManager.releaseTempTarget(target)
        return 'shaderOut'
    },
    beforeRenderToScreen: (node: ShaderMathDefineNode, vs, target) => {
        if(!vs.viewer) return target


        const anyNodeMouse = false
        const mouseState = anyNodeMouse || vs.mouseState.nodeId === node.id ? vs.mouseState : undefined
        node.data.updatePreviewMouse(mouseState)

        node.data.beforePreviewRender()

        const previewMaterial = node.data.previewMaterial
        previewMaterial.uniforms.vSize.value.set(target.texture.image.width, target.texture.image.height)

        // todo pan and scroll
        // todo render directly to the screen target
        vs.viewer.renderManager.blit(target as WebGLRenderTarget, {
            clear: true,
            respectColorSpace: true,
            transparent: false, // todo
            blending: NoBlending,
            material: previewMaterial,
        })

        // const pass = node.data.renderPass // todo move it to this renderer, instead of the data class
        // pass.scene = node.data.object
        // pass.camera = node.data.camera
        // pass.clear = true
        //
        // const minDist = 2;
        // const maxDist = 20;
        //
        // const anyNodeMouse = false
        // const mouseState = anyNodeMouse || vs.mouseState.nodeId === node.id ? vs.mouseState : null
        //
        // updateNodeCamera(mouseState, pass.camera, minDist, maxDist)
        //
        // const writeBuffer = pass.renderToScreen ? null : target;
        // if(writeBuffer) {
        //     const aspect = writeBuffer ? writeBuffer.width / writeBuffer.height : 1;
        //     updateCameraAspect(aspect, pass.camera)
        // }

        // pass.render(vs.viewer.renderManager.webglRenderer, null, target as WebGLRenderTarget)
        return target
    },
    type: 'shaderMathDefine',
    // renderOrder: 3,
}

// todo remove?
// const pltMaterial = new FunctionPlotMaterial()

