export function consoleWelcomePrompt() {
    console.log(`
%c
   _____ _               _                                    
  / ____| |             | |                 /\\                
 | (___ | |__   __ _  __| | ___ _ __ ___   /  \\   _ __  _ __  
  \\___ \\| '_ \\ / _\` |/ _\` |/ _ \\ '__/ __| / /\\ \\ | '_ \\| '_ \\ 
  ____) | | | | (_| | (_| |  __/ |  \\__ \\/ ____ \\| |_) | |_) |
 |_____/|_| |_|\\__,_|\\__,_|\\___|_|  |___/_/    \\_\\ .__/| .__/ 
                                                  | |   | |    
                                                  |_|   |_|    
`, "font-family: monospace;");

    console.log(`
Hey there, hello! 👋

🚀 Welcome! Here you can create and experiment with render pipelines, three.js, shaders, ML and more.

🤝 Found a bug? Have a feature to request or just want to chat? 
   Join our Discord server: https://discord.com/invite/puwnhfjqqX. 
   
   If you're feeling extra awesome, spread the word, share your shader experiments, and create some tutorials!

💡 Pro tip: Keep an eye on this console for debugging info, and report anything unusual.

Now go make some awesome effects and apps! 🎉
`);

    // console.log("%c@repalash on Twitter for tips and updates!", "font-style: italic;");
}
