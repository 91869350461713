import {onChange, serializable, serialize, updateMaterialDefines, Vector2} from 'threepipe'
import {PreviewNodeData} from './PreviewNodeData'
import {createNodeConnectionSlot, NodeData} from './NodeData'
import {BoxedExpression} from '@cortex-js/compute-engine'
import {MathfieldElement} from 'mathlive'
import {compileGlsl, defaultCompileTarget} from '../../utils/cortex/compileGlsl'
import {FlowMouseState} from '../../utils/state'
import {FunctionPlotMaterial} from '../../utils/renderers/functionPlotMaterial'

@serializable('ShaderMathDefineNodeData')
export class ShaderMathDefineNodeData extends PreviewNodeData implements NodeData {
    @serialize() name: string = 'Func'

    @serialize()
    @onChange('valueChanged')
    value: string

    mathJson: BoxedExpression | undefined
    compiled: string = ''
    compiledRaw: string = ''
    params: string[] = []

    protected onCompiled(compiled: string, mathJson: BoxedExpression|null){
        const allowedParams = ['x', 'y']//, 'z', 'w', 'r', 'g', 'b', 'a']
        // const allowedVars = ['t', 'u', 'v', 'i', 'j', 'k', 'l']
        const allowedVars = [] as string[]
        if(mathJson?.unknowns.find(v => !allowedParams.includes(v) && !allowedVars.includes(v))){
            // console.warn('Some Unknowns not allowed', mathJson?.unknowns)
            return
        }
        const vars = mathJson?.unknowns.filter(v => allowedVars.includes(v)) ?? []
        const params = mathJson?.unknowns.filter(v => allowedParams.includes(v)) ?? []
        this.params = params.sort((a, b) => allowedParams.indexOf(a) - allowedParams.indexOf(b))
        this.compiledRaw = `(${this.params.join(',')}) (${compiled})`
        this.compiled = `#define ${this.name.replace(/\W/g, '_')}${this.compiledRaw}`
        this.mathJson = mathJson || undefined
        // todo vars to slots
    }

    valueChanged() {
        console.log('value changed', this.value)
        let mathJson = null
        try {
            // let fnDef = this.defValue
            // todo also check for \\rightarrow?
            // let fnType = fnDef.split('\\to ')[1] || ('\\' + fnDef.split('\\to\\')[1])
            // if(!fnType || fnType === '\\') fnType = '\\mathbb{R}'
            // else fnDef = fnDef.split('\\to ')[0]

            console.log('value', this.value)
            // let s = displayLinesToBlock(this.value)
            // console.log('To Block', s)
            // s = fnDef + '\\mapsto' + s
            // console.log('Fn', s)
            const expr = MathfieldElement.computeEngine!.parse(this.value)
            if (expr?.isValid) {
                mathJson = expr
            } else {
                console.warn('Invalid expression', expr?.json, expr)
                // mathJson = undefined
                mathJson = this.mathJson
            }
        } catch (e) {
            console.error(e)
        }
        // console.log(mathJson)
        if (!mathJson) {
            this.onCompiled('1.0', this.mathJson||null)
            return
        }
        try {
            console.log(mathJson.json)
            const compiled = compileGlsl(mathJson, defaultCompileTarget(mathJson), 0)?.toString() ?? '1.0'
            this.onCompiled(compiled, mathJson)
            // this.compiled = glsl`(${mathJson.unknowns.join(', ')}) (${this.compiled})`
        } catch (e) {
            console.error(e)
            // this.compiled = '1.0'
        }
        // this.compiled = this.compiled.replace('Math.', '')
        // this.compiled = this.compiled.replaceAll('_.x', 'x')
        console.log(this.compiled)
    }

    constructor(
        value?: string,
        preview?: boolean,
    ) {
        super(preview)
        this.previewZoom = this.previewZoom.bind(this)
        this.value = value || ''
        this.valueChanged()
        this.slots.push(createNodeConnectionSlot('shader', {
            name: 'shader',
            // hasValue: true, // not required since there is no setter
            label: 'Math Shader Define',
            // getValue: () => glsl`#define ${this.name}(x) (${this.value})`, // todo make math macro node
            // getValue: () => glsl`#define ${this.name}(${this.params}) (${this.compiled})`,
            getValue: () => `${this.compiled}\n`,
        }))
    }

    dispose() {
        this.mathJson = undefined
        this.compiled = ''
        this.previewMaterial.dispose()
    }

    // for preview

    @serialize()
    previewState: {
        zoom: number,
        center: Vector2,
        plotMode: number,
    } = {
        zoom: 1,
        center: new Vector2(0,0),
        plotMode: 0,
    }
    previewZoom(zoomIn = true, mul = 1.1) {
        this.previewState.zoom *= zoomIn ? 1/mul : mul
    }
    nextPlotMode() {
        this.previewState.plotMode = (this.previewState.plotMode + 1) % 3
    }

    previewMaterial: FunctionPlotMaterial = new FunctionPlotMaterial()

    beforePreviewRender(){
        const previewMaterial = this.previewMaterial
        if(this.params.includes('y')) {
            previewMaterial.funcDefineX = ''
            previewMaterial.funcDefineXY = this.compiledRaw
            previewMaterial.setDirty()
        }else {
            previewMaterial.funcDefineX = this.compiledRaw
            previewMaterial.funcDefineXY = ''
            previewMaterial.setDirty()
        }
        previewMaterial.uniforms.zoom.value = this.previewState.zoom
        previewMaterial.uniforms.center.value.copy(this.previewState.center)
        updateMaterialDefines({PLOT_MODE: this.previewState.plotMode}, previewMaterial)
        // todo mouse state
    }


    private _lastCenter?: Vector2
    updatePreviewMouse(mouseState?: FlowMouseState){
        if(!mouseState) return
        const isScrolling = mouseState.scroll.delta.length() > 0.001
        if(!this._lastCenter || mouseState.isClick || isScrolling){
            this._lastCenter = (this._lastCenter || new Vector2()).copy(this.previewState.center)
        }
        if(isScrolling) { // only zoom when scrolling
            // todo
        }else {
            const mouseStart = mouseState.clickPosition // from 0 to 1
            const mouse = mouseState.position // from 0 to 1

            const panSpeed = 4 * this.previewState.zoom

            this.previewState.center.x = this._lastCenter.x + (mouseStart.x - mouse.x) * panSpeed
            this.previewState.center.y = this._lastCenter.y + (mouseStart.y - mouse.y) * panSpeed
        }
        console.log(this.previewState.center)
        // todo mouse state
    }
}

