import {createShaderPassNode} from '../../nodes/ShaderPassNode'
import {ShaderToyShaderPass} from '../../passes/ShaderToyShaderPass'
import React from 'react'
import {Button, InputGroup, Label} from '@blueprintjs/core'
import {
    ClampToEdgeWrapping,
    FloatType,
    generateUUID,
    ITexture,
    LinearFilter,
    LinearMipmapLinearFilter,
    LinearSRGBColorSpace,
    NearestFilter,
    NoBlending,
    NoColorSpace,
    RepeatWrapping,
    RGBAFormat,
    SRGBColorSpace,
    ThreeViewer,
    UnsignedByteType,
    WebGLRenderTarget
} from 'threepipe'
import {createRenderTargetNode} from '../../nodes/RenderTargetNode'
import {createTextureNode} from '../../nodes/TextureNode'
import {autoPlaceNodes} from '../addNodes'
import {TFlowContext} from '../../contexts/FlowContext'
import {ImportPopupFileButton} from '../../components/importPopupFileButton'
import {FlowEdgeType, FlowNodeType} from '../rendering'
import {createShaderSnippetNode} from '../../nodes/ShaderSnippetNode'
import {createRichTextNode} from '../../nodes/RichTextNode'

export const newShaderToyAdd = (viewer?: ThreeViewer) => {
    if (!viewer) return
    const node = createShaderPassNode(new ShaderToyShaderPass(), 'Shader Toy')
    autoPlaceNodes(viewer, [node])
}

async function loadShaderToy(id: string, viewer: ThreeViewer){
    if (id.toLowerCase().includes('shadertoy.com')) {
        id = id.split('/').pop() || ''
    }
    const apiUrl = 'https://shadertoy.shaders.app'
    const url = `${apiUrl}/api/v1/shaders/${id}`
    const res = await (await fetch(url)).json()
    console.log(res)

    if(res.Error === 'Shader not found') throw new Error('No shader found, make sure it\'s set to Public + API in ShaderToy')
    if(res.error || res.Error) throw new Error(res.error || res.Error)
    if(!res.Shader) throw new Error('Unknown error, try again later.')

    const shader = res.Shader
    const nodes: FlowNodeType[] = []
    const edges: FlowEdgeType[] = []

    const outputs = []
    const inputs = []

    const commonEdges: Omit<FlowEdgeType, 'target'|'id'>[] = []

//'# Shader Tile\n> by `<your name>`\n\nAdd some description'
    let user = shader.info?.username
    user = !user ? '' : '[' + user + '](https://www.shadertoy.com/user/' + user + ')'
    const infoText = createRichTextNode(`
## ${shader.info?.name || 'Shader Toy'}
> by ${user || '`Unknown`'}
[on ShaderToy](https://www.shadertoy.com/view/${id})
\n${shader.info?.description.replace(/\.\s+/g, '.\n') || ''}
`, 'Shader Toy Import')
    nodes.push(infoText)

    const commonShaders = shader.renderpass.filter((p: any) => p.type === 'common') as any[]
    for (const commonShader of commonShaders) {
        const commonCode = commonShader?.code || ''
        if(!commonCode) continue
        const snippetNode = createShaderSnippetNode(commonCode, 'Common')
        snippetNode.data.source = 'shadertoy:'+id
        nodes.push(snippetNode)
        commonEdges.push({
            source: snippetNode.id,
            animated: false,
            sourceHandle: 'shaderOut_shader',
            targetHandle: 'shaderIn_fragSnippets',
        })
    }

    let imageNode: FlowNodeType[] = []

    let yPos = 0
    for (const pass of shader.renderpass) {
        const type = pass.type
        if(type === 'common') continue
        const passNode = createShaderPassNode(new ShaderToyShaderPass(pass.code, type==='image'), pass.name)
        if(type === 'image') passNode.data.metadata = {shadertoy: shader.info}
        passNode.data.source = 'shadertoy:'+id
        passNode.data.value.blending = NoBlending
        if (type === 'buffer') {
            if(pass.outputs.length > 0)
                passNode.data.preserveOutput = true // todo only self
        }
        const pNodes = []
        if(type === 'image' || type === 'buffer') {
            const renderTargetNode = createRenderTargetNode(viewer.renderManager.createTarget<WebGLRenderTarget>({
                size: {width: 768, height: 432},
                // size: type === 'buffer' ? {width: 512, height: 512} : undefined,
                // sizeMultiplier: type === 'image' ? 1 : undefined,
                type: type === 'buffer' ? FloatType : UnsignedByteType,
                wrapS: ClampToEdgeWrapping,
                wrapT: ClampToEdgeWrapping,
                colorSpace: type === 'buffer' ? NoColorSpace : SRGBColorSpace,
                format: RGBAFormat, depthBuffer: false}), 'Image Buffer')
            renderTargetNode.data.clear = false
            renderTargetNode.data.preview = false
            renderTargetNode.data.minimize = true
            pNodes.push(renderTargetNode)
            edges.push({
                id: generateUUID(),// `${passNode.id}-${renderTargetNode.id}`,
                source: renderTargetNode.id,
                target: passNode.id,
                animated: true,
                sourceHandle: 'bufferOut_writeBuffer',
                targetHandle: 'bufferIn',
            })
            for (const commonEdge of commonEdges) {
                edges.push({...commonEdge, target: passNode.id, id: generateUUID()})
            }
        }
        for (const input of pass.inputs) {
            if (input.ctype === 'texture') {
                let src = input.src
                if(src.startsWith('/')) src = apiUrl + src
                const texture = await viewer.load<ITexture>(src)
                // console.log(texture, src, input)
                if (!texture) continue
                texture.flipY = input.sampler.vflip === "true"
                texture.colorSpace = input.sampler.srgb === "true" ? SRGBColorSpace : LinearSRGBColorSpace
                texture.wrapS = input.sampler.wrap === "repeat" ? RepeatWrapping : ClampToEdgeWrapping
                texture.wrapT = input.sampler.wrap === "repeat" ? RepeatWrapping : ClampToEdgeWrapping
                texture.minFilter = input.sampler.filter === "mipmap" ? LinearMipmapLinearFilter : input.sampler.filter === "linear" ? LinearFilter : NearestFilter
                texture.magFilter = input.sampler.filter === "mipmap" || input.sampler.filter === "linear" ? LinearFilter : NearestFilter
                // console.log(input)
                const node = createTextureNode(texture, input.src)
                node.position.y = 100
                pNodes.push(node)
                const edge = {
                    id: generateUUID(),
                    source: node.id,
                    target: passNode.id,
                    animated: false,
                    sourceHandle: 'textureOut_texture',
                    targetHandle: 'textureIn_iChannel' + input.channel,
                }
                edges.push(edge)
            }else if(input.ctype === 'buffer') {
                // buff
                inputs.push({
                    id: input.id,
                    channel: input.channel,
                    sampler: input.sampler,
                    target: passNode.id,
                    targetHandle: 'textureIn_iChannel' + input.channel
                })
                // todo sampler
            }else {
                console.warn('unsupported input type', input)
            }
        }
        for (const output of pass.outputs) {
            outputs.push({id: output.id,
                channel: output.channel,
                source: passNode.id,
                sourceHandle: type === 'buffer'? 'textureOut_writeBuffer':'bufferOut_writeBuffer' // todo buffer only when self
            })
        }
        if(pass.outputs.length > 1)
            console.error('multiple outputs not supported')
        pNodes.push(passNode)
        if(type === 'image') {
            if(imageNode.length) {
                console.error('multiple image passes not supported')
            }
            imageNode = pNodes
        }
        else {
            for (const pNode of pNodes) {
                pNode.position.y += yPos
            }
            yPos += 300
            nodes.push(...pNodes)
        }
    }
    if(!imageNode.length) {
        console.error('no image pass found')
    }else {
        nodes.push(...imageNode)
    }
    for (const input of inputs) {
        const output = outputs.find(o => o.id === input.id)
        if(!output) {
            console.warn('output not found for ', input, outputs)
            continue
        }
        edges.push({
            id: generateUUID(), //`${input.target}-${output.source}`,
            source: output.source,
            target: input.target,
            animated: false,
            sourceHandle: output.sourceHandle,
            targetHandle: input.targetHandle,
            // @ts-ignore
            sampler: input.sampler, // todo add sampler to edge type
            type: input.target === output.source ? 'selfConnecting' : undefined
        })
    }
    autoPlaceNodes(viewer, nodes, edges)
    return shader.info?.name
}

export const importShaderToy = (context: TFlowContext) => {
    const {dialog, setDialog} = context
    if (!dialog || !setDialog) return
    // const state = {value: 'ttjyDR'}
    const state = {value: 'MddGzf'}
    // const state = {value: 'ct3Szl'}
    // const state = {value: '4dG3R1'}
    // const state = {value: 'Mss3zH'}
    setDialog({
        ...dialog,
        canClose: true,
        isOpen: true,
        state,
        title: 'Import Shader Toy',
        content: (
            <Label>
                Shader Toy ID/URL:
                <InputGroup
                    placeholder="https://www.shadertoy.com/view/ttjyDR"
                    defaultValue={state.value}
                    onChange={(e: any) => state.value = e.target.value}
                />
            </Label>
        ),
        actions: (
            <>
                <Button text={'Close'} onClick={() => setDialog({...dialog, isOpen: false})}/>
                <ImportPopupFileButton state={state} importer={loadShaderToy}/>
            </>
        ),
    })
    // autoPlaceNodes([node])
}
