import React, {createContext, createElement} from 'react'

const defaultUi = {
    node_shadows: 3 as 0|1|2|3|4,
    node_corner_radius: 25,
    nodes_visible: true,
    edges_visible: true,
    handles_visible: true,
    node_outlines: true,
    node_colors: true,
    edge_colors: true,
    handle_colors: true,
    // edge_labels: true,
    // edge_arrows: true,
    // edge_curved: true,
}

const isMac = navigator.userAgent.indexOf("Mac") > -1;
const defaultInteraction = {
    panOnScroll: isMac,
    zoomOnScroll: !isMac,
    zoomOnPinch: true,
    leftMouseDrag: true, // if true - left is drag, right is selection, otherwise vice versa
    selectOnDrag: false, // if true - shows selection on drag, or use shift key
}
const defaultRendering = {
    gammaCorrection: true,
}
type UiSettingsType = typeof defaultUi
export type TGlobalSettingContext = {
    ui: UiSettingsType, setUi: (ui: Partial<UiSettingsType>) => void
    interaction: typeof defaultInteraction, setInteraction: (interaction: Partial<typeof defaultInteraction>) => void
    rendering: typeof defaultRendering, setRendering: (rendering: Partial<typeof defaultRendering>) => void
    drawerOpen: boolean, setDrawerOpen: (drawerOpen: boolean) => void
}
const GlobalSettingsContext = createContext({
    ui: {}, setUi: (_ui: any) => {},
    interaction: defaultInteraction, setInteraction: (_interaction: any) => {},
    rendering: defaultRendering, setRendering: (_rendering: any) => {},
    drawerOpen: false, setDrawerOpen: (_drawerOpen: boolean) => {},
} as TGlobalSettingContext)

export const useGlobalSettings = ()=>React.useContext(GlobalSettingsContext)

const localStorePrefix = 'shaders-global-config-'

const getDefault = (k: string, def: any)=>({...def, ...JSON.parse(localStorage.getItem(localStorePrefix+k) || '{}')})
export function GlobalSettingsProvider({children}: {children: any}){
    const [ui, setUi] = React.useReducer((state: UiSettingsType, action: Partial<UiSettingsType>) => {
        const res = ({...state, ...action})

        // hack so that these settings dont get saved.
        const res2 = {...res}
        res2.nodes_visible = defaultUi.nodes_visible
        res2.edges_visible = defaultUi.edges_visible
        res2.handles_visible = defaultUi.handles_visible
        localStorage.setItem(localStorePrefix+'ui', JSON.stringify(res2))

        return res
    }, getDefault( 'ui', defaultUi))
    const [interaction, setInteraction] = React.useReducer((state: typeof defaultInteraction, action: Partial<typeof defaultInteraction>) => {
        const res = ({...state, ...action})
        localStorage.setItem(localStorePrefix+'interaction', JSON.stringify(res))
        return res
    }, getDefault( 'interaction', defaultInteraction))
    const [rendering, setRendering] = React.useReducer((state: typeof defaultRendering, action: Partial<typeof defaultRendering>) => {
        const res = ({...state, ...action})
        localStorage.setItem(localStorePrefix+'rendering', JSON.stringify(res))
        return res
    }, getDefault( 'rendering', defaultRendering))
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)
    return createElement(GlobalSettingsContext.Provider, {value: {
            ui, setUi,
            interaction, setInteraction,
            rendering, setRendering,
            drawerOpen, setDrawerOpen,
    }}, children)
}
